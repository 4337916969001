import { useNavigate } from 'react-router-dom';
import Profile from "../../auth/Profile";
import AutoCompleteComponent from "../../stories/AutoComplete/AutoComplete"

interface RightMenuProps {
  adminMenuData: {
    handleMainMenuSelection: (menu: string) => void;
  };
  drawOpen?: boolean;
}

const RightMenu: React.FC<RightMenuProps> = ({ adminMenuData }) => {

  const navigate = useNavigate();

  return (
    <div className="right-menu-container">
      <AutoCompleteComponent
        emit={"autocomplete"}
        handleMainMenuSelection={adminMenuData.handleMainMenuSelection}
        navigate={navigate}
      />
      <Profile
        adminData={adminMenuData}
        handleMainMenuSelection={adminMenuData.handleMainMenuSelection}
      />
    </div>
  );
};

export default RightMenu;
