import uuid from "uuid-browser";
import { TableProps } from "antd/lib/table";
import { TableState } from "../../stories/Table/TableBase";
import { useMercuryContext } from "../../user-context";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { Space } from "antd";
import ManageProject from "./ManageProject";
import TableWSAbstract from "../../stories/Table/TableWS.abstract";
import "./manualProject.scss";
import TimezoneConverter from "../../timezone-converter";
import { BlockOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  BooleanFilter,
  CustomTooltip,
  DateFilter,
  ListFilter,
  TextFilter,
} from "../../Components";
import { productFilter } from "../filtersConfig";
import TestingProjectTypeFilter from "./TestingProjectType.filter";
import PriorityFilter from "./Priority.filter";
import SurfaceFilter from "./Surface.filter";
import ComplexityFilter from "./Complexity.filter";
import ProductionStatusFilter from "./ProductionStatus.filter";
import HeadsUpAccuracyFilter from "./HeadsUpAccuracy.filter";
import QuoteStatusFilter from "./QuoteStatus.filter";
import SoFilter from "./So.filter";
import EmpowerCCFilter from "../EmpowerCC.filter";
import { checkPermissions } from "../../Utilities/checkPermissions";
import NumberFilter from "../MongoNumber.filter";
import ManualProjectBatchUpdate from "./ManualProjectBatchUpdate";
import TableTitle from "../../reusable/TableTitle";

class ManualProjectSearch extends TableWSAbstract<any> {
  defaultSortField = "projectDropDate";
  FilterComponents = [
    {
      id: "buganizerId",
      name: "buganizerId",
      title: "Buganizer",
      component: ListFilter,
      active: true,
    },
    {
      id: "projectName",
      name: "projectName",
      title: "Project Name",
      component: TextFilter,
      active: true,
    },
    productFilter,
    {
      id: "shortId",
      name: "shortId",
      title: "Polyglot ID",
      component: ListFilter,
      active: true,
    },
    {
      id: "testingProjectType",
      name: "testingProjectType",
      title: "Project Type",
      component: TestingProjectTypeFilter,
      active: true,
    },
    {
      id: "projectDropDate",
      name: "projectDropDate",
      title: "Project Drop Date",
      component: DateFilter,
      active: true,
    },
    {
      id: "priority",
      name: "priority",
      title: "Priority",
      component: PriorityFilter,
      active: true,
    },
    {
      id: "testCases",
      name: "testCases",
      title: "Test cases/locale",
      component: NumberFilter,
      active: true,
    },
    {
      id: "complexity",
      name: "complexity",
      title: "Complexity",
      component: ComplexityFilter,
      active: true,
    },
    {
      id: "surface",
      name: "surface",
      title: "Surface",
      component: SurfaceFilter,
      active: true,
    },
    {
      id: "productionStatus",
      name: "productionStatus",
      title: "Production Status",
      component: ProductionStatusFilter,
      active: true,
    },
    {
      id: "headsUpReceived",
      name: "headsUpReceived",
      title: "Heads Up Received",
      component: BooleanFilter,
    },
    {
      id: "headsUpDate",
      name: "headsUpDate",
      title: "Heads Up Date",
      component: DateFilter,
      active: true,
    },
    {
      id: "headsUpAccuracy",
      name: "headsUpAccuracy",
      title: "Heads Up Accuracy",
      component: HeadsUpAccuracyFilter,
      active: true,
    },
    {
      id: "testingStart",
      name: "testingStart",
      title: "Testing Start",
      component: DateFilter,
      active: true,
    },
    {
      id: "quoteSubmission",
      name: "quoteSubmission",
      title: "Quote Submission",
      component: DateFilter,
      active: true,
    },
    {
      id: "quoteStatus",
      name: "quoteStatus",
      title: "Quote Status",
      component: QuoteStatusFilter,
      active: true,
    },
    {
      id: "internalCost",
      name: "internalCost",
      title: "Internal Cost",
      component: NumberFilter,
      active: true,
    },
    {
      id: "so",
      name: "so",
      title: "SO",
      component: SoFilter,
      active: true,
    },
    {
      id: "clientPO",
      name: "clientPO",
      title: "Client PO",
      component: TextFilter,
      active: true,
    },
    {
      id: "testerHours",
      name: "testerHours",
      title: "Tester Hours",
      component: NumberFilter,
      active: true,
    },
    {
      id: "testLeadHours",
      name: "testLeadHours",
      title: "Test Lead Hours",
      component: NumberFilter,
      active: true,
    },
    {
      id: "costCode",
      name: "costCode",
      title: "Cost Code",
      component: EmpowerCCFilter,
      active: false,
    },
  ];
  columns = [
    {
      id: "buganizerId",
      title: "Buganizer",
      dataIndex: "buganizerId",
      sorter: true,
      width: 100,
      render: (id: string, record: any) => {
        return (
          <>
            <a
              href={`/manual-project/${record?.id}`}
              target="_blank"
              rel="noopener noreferrer"
              title="View project details in a new tab"
            >
              <BlockOutlined style={{ marginRight: "3px" }} />
            </a>
            <CustomTooltip text={id}>
              <Link
                to={`/manual-project/${record?.id}`}
                id={record?.id}
                title="View project details"
              >
                {id}
              </Link>
            </CustomTooltip>
          </>
        );
      },
    },
    {
      id: "clientPO",
      title: "Client PO #",
      dataIndex: "clientPO",
      sorter: true,
      width: 100,
    },
    {
      id: "clientQuoteLink",
      title: "Client Quote Link",
      dataIndex: "clientQuoteLink",
      sorter: true,
      width: 140,
      render: (text: string) => <CustomTooltip text={text} />,
    },
    {
      id: "complexity",
      title: "Complexity",
      dataIndex: "complexity",
      sorter: true,
      width: 100,
    },
    {
      id: "costCode",
      title: "Cost Code",
      dataIndex: "costCode",
      sorter: true,
      width: 140,
    },
    {
      id: "headsUpAccuracy",
      title: "Heads Up Accuracy",
      dataIndex: "headsUpAccuracy",
      sorter: true,
      width: 160,
    },
    {
      id: "headsUpDate",
      title: "Heads Up Date",
      dataIndex: "headsUpDate",
      sorter: true,
      width: 150,
      render: (text: string) => (
        <CustomTooltip text={text}>
          <TimezoneConverter date={text} />
        </CustomTooltip>
      ),
    },
    {
      id: "headsUpReceived",
      title: "Heads Up Received",
      dataIndex: "headsUpReceived",
      sorter: true,
      width: 150,
      render: (text: string) => (text ? "Yes" : "No"),
    },
    {
      id: "internalCost",
      title: (<TableTitle title="Internal Cost" />) as any,
      dataIndex: "internalCost",
      sorter: true,
      width: 100,
    },
    {
      id: "internalQuoteLink",
      title: (<TableTitle title="Internal Quote Link" />) as any,
      dataIndex: "internalQuoteLink",
      sorter: true,
      width: 120,
      render: (text: string) => <CustomTooltip text={text} />,
    },
    {
      id: "lptPMNotes",
      title: "Lpt PM Notes",
      dataIndex: "lptPMNotes",
      sorter: true,
      width: 140,
      render: (text: string) => <CustomTooltip text={text} />,
    },
    {
      id: "priority",
      title: "Priority",
      dataIndex: "priority",
      sorter: true,
      width: 100,
    },
    {
      id: "product",
      title: "Products",
      dataIndex: "product",
      sorter: true,
      width: 100,
    },
    {
      id: "shortId",
      title: "Polyglot ID",
      dataIndex: "shortId",
      sorter: true,
      width: 100,
    },
    {
      id: "surface",
      title: "Surface",
      dataIndex: "surface",
      sorter: true,
      width: 100,
    },
    {
      id: "testCases",
      title: "Test cases/locale",
      dataIndex: "testCases",
      sorter: true,
      width: 140,
    },
    {
      id: "testingStart",
      title: "Testing Start",
      dataIndex: "testingStart",
      sorter: true,
      width: 150,
      render: (text: string) => (
        <CustomTooltip text={text}>
          <TimezoneConverter date={text} />
        </CustomTooltip>
      ),
    },
    {
      id: "testerHours",
      title: "Tester Hours",
      dataIndex: "testerHours",
      sorter: true,
      width: 130,
    },
    {
      id: "testLeadHours",
      title: "Test Lead Hours",
      dataIndex: "testLeadHours",
      sorter: true,
      width: 140,
    },
    {
      id: "testingProjectType",
      title: "Project Type",
      dataIndex: "testingProjectType",
      sorter: true,
      width: 140,
    },
    {
      id: "productionStatus",
      title: "Production Status",
      dataIndex: "productionStatus",
      sorter: true,
      width: 140,
    },
    {
      id: "projectName",
      title: "Project Name",
      dataIndex: "projectName",
      sorter: true,
      width: 140,
      render: (text: string) => <CustomTooltip text={text} />,
    },
    {
      id: "so",
      title: "SO",
      dataIndex: "so",
      sorter: true,
      width: 140,
    },
    {
      id: "rwsDeliveryLink",
      title: "RWS Delivery Link",
      dataIndex: "rwsDeliveryLink",
      sorter: true,
      width: 140,
      render: (text: string) => <CustomTooltip text={text} />,
    },
    {
      id: "quoteStatus",
      title: "Quote Status",
      dataIndex: "quoteStatus",
      sorter: true,
      width: 140,
    },
    {
      id: "quoteSubmission",
      title: "Quote Submission",
      dataIndex: "quoteSubmission",
      sorter: true,
      width: 140,
      render: (text: string) => (
        <CustomTooltip text={text}>
          <TimezoneConverter date={text} />
        </CustomTooltip>
      ),
    },
    {
      id: "projectDropDate",
      title: "Project Drop Date",
      dataIndex: "projectDropDate",
      sorter: true,
      width: 160,
      render: (text: string) => (
        <CustomTooltip text={text}>
          <TimezoneConverter date={text} />
        </CustomTooltip>
      ),
    },
  ];

  renderActions() {
    // @ts-ignore
    const userPermissions = this.userContext.userDetails?.permissions || [];
    const canCreateProject = checkPermissions(userPermissions, [
      {
        customerApplication: "Google",
        permissionName: "Manual Projects",
        action: "create",
      },
    ]);
    const canEditProject = checkPermissions(userPermissions, [
      {
        customerApplication: "Google",
        permissionName: "Manual Projects",
        action: "edit",
      },
    ]);
    return (
      <Space size={"large"}>
        {canCreateProject && (
          <ManageProject label="Add" reload={() => this.getData()} />
        )}
        {canEditProject && (
          <ManualProjectBatchUpdate
            selectedRows={this.state.selectedRows}
            reload={() => this.getData()}
          />
        )}
      </Space>
    );
  }

  pageTitle = "Manual Projects";
  title = "Manual Projects";
  pageClass = "manualProjects";

  public rowMap: Map<string, any> = new Map();

  public floodPrevention = () => {
    clearTimeout(this.floodTimer);
    this.floodTimer = undefined;
    this.setState({
      rowData: Array.from(this.rowMap.values()),
      changedIds: this.state.changedIds,
    });
  };

  public floodTimer?: number;

  async componentDidMount(): Promise<void> {
    await super.componentDidMount();

    const socket = this.props.context.gpSocket.manualproject;

    socket.on("tail", (doc: any) => {
      this.rowMap.set(doc._id, doc);
      this.state.changedIds?.add(doc._id);
      const { rowData } = this.state;
      rowData.unshift(doc);

      this.rowMap.clear();

      rowData.forEach((row: any) => this.rowMap.set(row._id, row));

      this.setState({
        rowData: Array.from(this.rowMap.values()),
        changedIds: this.state.changedIds,
      });
    });
  }

  componentDidUpdate(prevProps: TableProps<any>, prevState: TableState<any>) {
    super.componentDidUpdate(prevProps as any, prevState);
    if (prevState.reqId !== this.state.reqId) {
      const socket = this.props.context.gpSocket.manualproject;
      socket.off(`search:row:${prevState.reqId}`);
      socket.off(`search:meta:${prevState.reqId}`);
      socket.on(`search:row:${this.state.reqId}`, (row: any) => {
        const rows = Array.isArray(row) ? row : [row];

        rows.forEach((item: any) => {
          this.rowMap.set(item._id, item);
          socket.on(item._id, (doc: any) => {
            this.state.changedIds?.add(doc._id);
            if (this.rowMap.has(doc._id)) {
              this.rowMap.set(doc._id, doc);
              if (this.floodTimer) {
                clearTimeout(this.floodTimer);
                this.floodTimer = undefined;
              }
              this.floodTimer = setTimeout(
                () => this.floodPrevention(),
                500
              ) as any;
            }
          });
        });

        this.setState({
          loading: false,
          changedIds: new Set(),
          rowData: Array.from(this.rowMap.values()),
        });
      });

      socket.on(`search:meta:${this.state.reqId}`, (meta: any) => {
        this.setState({ selectedRows: [], currentPage: meta.page, meta: meta });
      });
    }
  }

  componentWillUnmount() {
    const socket = this.props.context.gpSocket.manualproject;
    super.componentWillUnmount();
    socket.off(`search:row:${this.state.reqId}`);
    socket.off(`search:meta:${this.state.reqId}`);
    socket.off(`tail`);
    Array.from(this.rowMap.keys()).forEach((key) => {
      socket.off(key);
    });
  }

  async getData(): Promise<void> {
    const socket = this.userContext.gpSocket.manualproject,
      { sortOrder, sortField, currentPage, itemsPerPage, rowData } = this.state,
      ids = rowData.map((row) => row._id);

    this.rowMap.clear();

    socket.emit("unbind:ids", ids);
    socket.emit("search:abort");

    ids.forEach((id) => {
      socket.off(id);
    });

    this.setState({ reqId: uuid(), loading: true }, () => {
      const filters = this.filtersToQuery(),
        sort = {
          [sortField ?? "importedTimestamp"]: sortOrder === "ascend" ? 1 : -1,
        };

      if (
        (sort.importedTimestamp === -1 && Object.keys(filters).length === 0) ||
        (Object.keys(filters).length === 1 &&
          filters.importedTimestamp?.length > 1 &&
          new Date(filters.importedTimestamp[1]) > new Date())
      ) {
        socket.emit("tail", () =>
          console.info("tailing for new projects activated...")
        );
      } else {
        socket.emit("untail", () => console.info("tailing de-activated"));
      }

      socket.emit(
        `search`,
        {
          filter: this.filtersToQuery(),
          sort: {
            [sortField ?? "created_at"]: sortOrder === "ascend" ? 1 : -1,
          },
          pagination: {
            pageNumber: currentPage || 1,
            resultsPerPage: itemsPerPage,
          },
          reqId: this.state.reqId,
        },
        () => {
          this.setState({ loading: false, changedIds: new Set() });
        }
      );
    });
  }

  send(): Promise<void> {
    return Promise.resolve(undefined);
  }
}

const ManualProject = () => {
  const context = useMercuryContext(),
    breaks = useBreakpoint();

  return (
    <ManualProjectSearch
      id="ManualProjects"
      context={context}
      useLegacyEndpoints={false}
      breaks={breaks as any}
    />
  );
};

export default ManualProject;
