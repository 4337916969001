import React, { Component } from "react";

import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Switch,
  Table,
  Tooltip,
} from "antd";
import { InfoCircleOutlined, RightOutlined } from "@ant-design/icons";

import { capitalizeString, groupAndSortByKey } from "../../../utils/helpers";

class EditUser extends Component {
  state = {
    selectedCA: new Set(
      this.props.values.editing.editingRow.customerApplications.map(
        (ca) => ca._id
      )
    ),
    selectedRoles: new Set(
      this.props.values.editing.editingRow.roles.map((role) => role._id)
    ),
    selectedPermissions: new Map(),
  };

  columns = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "",
      dataIndex: "description",
      key: "description",
      width: 10,
      render: (text, record) => (
        <Tooltip
          title={record.description}
          color={"var(--tertiary-color)"}
          placement={"left"}
        >
          <InfoCircleOutlined style={{ color: "var(--tertiary-color)" }} />
        </Tooltip>
      ),
    },
    {
      title: "Read",
      dataIndex: "read",
      key: "read",
      width: 60,
      render: (text, record) => {
        const [val, roleDisabled, matchedRoles] = this.permissionValue(
            record.id,
            "read"
          ),
          displayedRoles = matchedRoles
            ?.map((role) => capitalizeString(role.name))
            .join(", ");

        return record.supportsRead ? (
          <Tooltip
            placement={"left"}
            title={
              roleDisabled
                ? matchedRoles.length > 1
                  ? `This permission is part of the following roles: ${displayedRoles}. Remove roles to allow changing permission.`
                  : `This permission is part of the following role: ${displayedRoles}. Remove role to allow changing permission.`
                : ""
            }
          >
            <div>
              <Form.Item
                // fix for antd not populating role permissions
                name={`${roleDisabled ? "role-" : ""}permissions~read-${
                  record.id
                }`}
                key={`permissions~read-${record.id}`}
                noStyle
                valuePropName="checked"
                initialValue={roleDisabled ? val : undefined}
              >
                <Checkbox name="read" disabled={roleDisabled} />
              </Form.Item>
            </div>
          </Tooltip>
        ) : (
          <Form.Item
            name={`permissions~read-${record.id}`}
            key={`permissions~read-${record.id}`}
            noStyle
            valuePropName="checked"
          >
            <Tooltip
              title={`This permission does not support this change.`}
              placement={"left"}
            >
              <Checkbox name="read" disabled />
            </Tooltip>
          </Form.Item>
        );
      },
    },
    {
      title: "Create",
      dataIndex: "create",
      key: "create",
      width: 60,
      render: (text, record) => {
        const [val, roleDisabled, matchedRoles] = this.permissionValue(
            record.id,
            "create"
          ),
          displayedRoles = matchedRoles
            ?.map((role) => capitalizeString(role.name))
            .join(", ");
        return record.supportsCreate ? (
          <Tooltip
            placement={"left"}
            title={
              roleDisabled
                ? matchedRoles.length > 1
                  ? `This permission is part of the following roles: ${displayedRoles}. Remove roles to allow changing permission.`
                  : `This permission is part of the following role: ${displayedRoles}. Remove role to allow changing permission.`
                : ""
            }
          >
            <div>
              <Form.Item
                // fix for antd not populating role permissions
                name={`${roleDisabled ? "role-" : ""}permissions~create-${
                  record.id
                }`}
                key={`permissions~create-${record.id}`}
                noStyle
                valuePropName="checked"
                initialValue={roleDisabled ? val : undefined}
              >
                <Checkbox name="create" disabled={roleDisabled} />
              </Form.Item>
            </div>
          </Tooltip>
        ) : (
          <Form.Item
            name={`permissions~create-${record.id}`}
            key={`permissions~create-${record.id}`}
            noStyle
            valuePropName="checked"
          >
            <Tooltip
              title={`This permission does not support this change.`}
              placement={"left"}
            >
              <Checkbox name="create" disabled />
            </Tooltip>
          </Form.Item>
        );
      },
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      width: 60,
      render: (text, record) => {
        const [val, roleDisabled, matchedRoles] = this.permissionValue(
            record.id,
            "edit"
          ),
          displayedRoles = matchedRoles
            ?.map((role) => capitalizeString(role.name))
            .join(", ");
        return record.supportsEdit ? (
          <Tooltip
            placement={"left"}
            title={
              roleDisabled
                ? matchedRoles.length > 1
                  ? `This permission is part of the following roles: ${displayedRoles}. Remove roles to allow changing permission.`
                  : `This permission is part of the following role: ${displayedRoles}. Remove role to allow changing permission.`
                : ""
            }
          >
            <div>
              <Form.Item
                // fix for antd not populating role permissions
                name={`${roleDisabled ? "role-" : ""}permissions~edit-${
                  record.id
                }`}
                key={`permissions~edit-${record.id}`}
                noStyle
                valuePropName="checked"
                initialValue={roleDisabled ? val : undefined}
              >
                <Checkbox name="edit" disabled={roleDisabled} />
              </Form.Item>
            </div>
          </Tooltip>
        ) : (
          <Form.Item
            name={`permissions~edit-${record.id}`}
            key={`permissions~edit-${record.id}`}
            noStyle
            valuePropName="checked"
          >
            <Tooltip
              title={`This permission does not support this change.`}
              placement={"left"}
            >
              <Checkbox name="edit" disabled />
            </Tooltip>
          </Form.Item>
        );
      },
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      width: 60,
      render: (text, record) => {
        const [val, roleDisabled, matchedRoles] = this.permissionValue(
            record.id,
            "delete"
          ),
          displayedRoles = matchedRoles
            ?.map((role) => capitalizeString(role.name))
            .join(", ");

        return record.supportsDelete ? (
          <Tooltip
            placement={"left"}
            title={
              roleDisabled
                ? matchedRoles.length > 1
                  ? `This permission is part of the following roles: ${displayedRoles}. Remove roles to allow changing permission.`
                  : `This permission is part of the following role: ${displayedRoles}. Remove role to allow changing permission.`
                : ""
            }
          >
            <div>
              <Form.Item
                // fix for antd not populating role permissions
                name={`${roleDisabled ? "role-" : ""}permissions~delete-${
                  record.id
                }`}
                key={`permissions~delete-${record.id}`}
                noStyle
                valuePropName="checked"
                initialValue={roleDisabled ? val : undefined}
              >
                <Checkbox name="delete" disabled={roleDisabled} />
              </Form.Item>
            </div>
          </Tooltip>
        ) : (
          <Form.Item
            name={`permissions~delete-${record.id}`}
            key={`permissions~delete-${record.id}`}
            noStyle
            valuePropName="checked"
          >
            <Tooltip
              title={`This permission does not support this change.`}
              placement={"left"}
            >
              <Checkbox name="delete" disabled />
            </Tooltip>
          </Form.Item>
        );
      },
    },
  ];

  permissionValue(id, key) {
    const { selectedPermissions, selectedRoles } = this.state;

    let perm,
      roleDisabled = false,
      matchedRoles = [];

    Array.from(selectedRoles).forEach((rid) => {
      if (perm) {
        return;
      }
      const [role] = this.props.values.rolesList.filter((r) => r.id === rid);

      role?.permissions.forEach((p) => {
        if (p.permissionId === id) {
          perm = p[key];
          roleDisabled = p[key];
          roleDisabled && matchedRoles.push(role);
        }
      });
    });

    if (!perm) {
      if (
        selectedPermissions &&
        selectedPermissions instanceof Map && 
        selectedPermissions.get(id) &&
        selectedPermissions.get(id)[key] !== undefined
      ) {
        perm = selectedPermissions.get(id)[key];
      }
    }

    return [perm, roleDisabled, matchedRoles];
  }

  closeDrawer = (closeEditDrawer, e) => {
    closeEditDrawer(e);
    this.setState({
      selectedCA: new Set(),
      selectedRoles: new Set(),
      selectedPermissions: {},
    });
  };

  handleChangeApplications = (e) => {
    this.setState({ selectedCA: new Set(e) });

    // fix to remove roles which are not part of a customer application
    // get all details
    const rolesWithDetails = [];

    Array.from(this.state.selectedRoles).forEach((rid) => {
      rolesWithDetails.push(
        this.props.values.rolesList.find((role) => role.id === rid)
      );
    });

    const newRoles = rolesWithDetails
      .filter((role) => e.includes(role.customerApplication))
      .map((role) => role.id);

    this.setState({ selectedRoles: new Set(newRoles) });

    if (this.props.values.editFormRef.current) {
      this.props.values.editingForm.setFieldsValue({
        rolesValue: newRoles,
      });
    }
  };

  handleChangeRoles = (e) => {
    this.setState({ selectedRoles: new Set(e) });
  };

  onChangePermission = (e, id) => {
    const { selectedPermissions } = this.state;

    if (selectedPermissions.has(id)) {
      const p = selectedPermissions?.get(id);
      selectedPermissions.set(id, { ...p, [e.target.name]: e.target.checked });
    } else {
      selectedPermissions.set(id, { [e.target.name]: e.target.checked });
    }

    this.setState({ selectedPermissions });
  };

  render() {
    const {
        visibleEditDrawer,
        editingForm,
        applicationsList,
        rolesList,
        permissionsList,
        editing,
        editFormRef,
      } = this.props.values,
      { closeEditDrawer, onFinishEditing } = this.props.handlers,
      { selectedCA } = this.state,
      permissionMap = new Map(),
      editingRow = editing && editing?.editingRow,
      userInitialPermissions = editingRow.userLevelPermissions;

    permissionsList.forEach((permission) => {
      if (!permission.customerApplication) {
        return;
      }

      if (!permissionMap.has(permission.customerApplication)) {
        permissionMap.set(permission.customerApplication, []);
      }

      const arr = permissionMap.get(permission.customerApplication);

      arr.push(permission);
      permissionMap.set(permission.customerApplication, arr);
    });

    let initialFormValues = {
      firstNameValue: editingRow?.firstName,
      lastNameValue: editingRow?.lastName,
      emailValue: editingRow?.email,
      customerApplicationsValue: editingRow.customerApplications.map(
        (ca) => ca._id
      ),
      rolesValue: editingRow?.roles.map((role) => role._id),
    };

    // fix for antd form to set any manually checked permissions and not part of a role
    userInitialPermissions.forEach((dp) => {
      for (const [key, value] of Object.entries(dp)) {
        if (value === true) {
          initialFormValues[`permissions~${key}-${dp.permissionId}`] = value;
        }
      }
    });

    return <Drawer
          title="Edit User"
          placement="right"
          onClose={(e) => this.closeDrawer(closeEditDrawer, e)}
          open={visibleEditDrawer}
          width={550}
          closable={true}
          className="manage-pages-drawer edit-user-drawer"
          forceRender
        >
          <Form
            form={editingForm}
            ref={editFormRef}
            layout="vertical"
            onFinish={(values) => {
              onFinishEditing(values);
              this.closeDrawer(closeEditDrawer);
            }}
            name="editUserForm"
            className="edit-user-form"
            initialValues={{ ...initialFormValues }}
          >
            <Form.Item
              name="firstNameValue"
              label="First Name"
              rules={[
                { required: true, message: "Please type the first name" },
              ]}
            >
              <Input placeholder="Ex: John" />
            </Form.Item>
            <Form.Item
              name="lastNameValue"
              label="Last Name"
              rules={[{ required: true, message: "Please type the last name" }]}
            >
              <Input placeholder="Ex: Smith" />
            </Form.Item>
            <Form.Item
              name="emailValue"
              label="Email"
              rules={[
                { required: true, message: "Please type the email" },
                { type: "email", message: "The email is not valid." },
              ]}
            >
              <Input placeholder="Ex: john@rws.com" />
            </Form.Item>
            <Row>
              <Col span={8}>
                <Form.Item
                  label="Admin"
                  name="adminValue"
                  valuePropName="checked"
                  initialValue={editingRow.admin}
                >
                  <Switch defaultChecked={editingRow.admin} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Vendor Manager"
                  name="vendorManagerValue"
                  valuePropName="checked"
                  initialValue={editingRow.vendorManager}
                >
                  <Switch defaultChecked={editingRow.vendorManager} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Active"
                  name="isActiveValue"
                  valuePropName="checked"
                  initialValue={editingRow.isActive}
                >
                  <Switch defaultChecked={editingRow.isActive} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Customer Applications"
              name="customerApplicationsValue"
              rules={[
                {
                  required: true,
                  message: "Please select customer application",
                },
              ]}
            >
              <Select
                placeholder="Select application"
                onChange={this.handleChangeApplications}
                mode="multiple"
              >
                {applicationsList
                  ?.filter((i) => i.active)
                  .map((app) => {
                    return (
                      <Select.Option value={app.id} key={app.id}>
                        {capitalizeString(app.name)}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>

            {selectedCA.size === 0 ? (
              <p>Please select customer application first</p>
            ) : (
              <>
                <Form.Item
                  label="Roles"
                  name="rolesValue"
                  rules={[
                    {
                      required: true,
                      message: "Please select at least 1 role",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select roles"
                    style={{ width: "100%" }}
                    onChange={this.handleChangeRoles}
                  >
                    {rolesList
                      ?.filter((r) => selectedCA.has(r.customerApplication))
                      .map((role) => {
                        return (
                          <Select.Option
                            value={role._id}
                            key={role._id}
                            title={role.description}
                          >
                            {capitalizeString(role.name)}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item label="Permissions">
                  {Array.from(selectedCA).map((ca) => {
                    if (!permissionMap.get(ca)) {
                      return null;
                    }
                    const [application] = applicationsList?.filter(
                        (a) => a.id === ca
                      ),
                      list = permissionMap.get(ca);

                    const groupped = groupAndSortByKey(list, "category");

                    const renderedPermissions = [];
                    // render a table for each category
                    for (const [category, list] of Object.entries(groupped)) {
                      const permissionGroup = application && (
                        <Table
                          columns={this.columns}
                          dataSource={[
                            ...list.sort((a, b) =>
                              a.name.localeCompare(b.name)
                            ),
                          ]}
                          title={() => (
                            <Tooltip
                              title={`${capitalizeString(
                                category
                              )} category is part of ${capitalizeString(
                                application.name
                              )} customer application.`}
                              color={"var(--tertiary-color)"}
                              placement={"left"}
                            >
                              {
                                <span style={{ cursor: "help" }}>
                                  {application.name}
                                  <RightOutlined
                                    style={{
                                      color: "var(--primary-color)",
                                      margin: "0 3px",
                                      fontSize: "12px",
                                    }}
                                  />
                                  {category}
                                </span>
                              }
                            </Tooltip>
                          )}
                          key={`${application.id}-${category}`}
                          pagination={false}
                          className="role-table"
                        />
                      );

                      renderedPermissions.push(permissionGroup);
                    }

                    return renderedPermissions;
                  })}
                </Form.Item>
              </>
            )}
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save User
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
  }
}

export default ({ handlers, values }) => (
  <EditUser handlers={handlers} values={values} />
);
