import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Modal,
  Row,
  Tooltip,
} from "antd";
import { useState } from "react";
import { useMercuryContext } from "../../user-context";
import {
  failedNotification,
  successfulNotification,
} from "../../utils/notifications";
import { usePermissions } from "../../hooks/usePermissions";

const PlpBatchUpdate = (props: any) => {
  const { gpSocket } = useMercuryContext();
  const [form] = Form.useForm();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [etaFromLxD, setEtaFromLxD] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const rows = props.selectedRows || [];
  const { hasPermissions } = usePermissions();
  const canEditProjectInvoice = hasPermissions([{ customerApplication: "Google", permissionName: "Invoice Project", action: "edit" }]);
  const allowedSubmitCriteria = (element: any) => element.varStatus !== "INVOICE";
  const allowSubmit = rows.length > 0 && rows.filter( ( e: any ) => e.deleted !== true ).filter(allowedSubmitCriteria).length;

  const resetCheckboxes = () => {
    setEtaFromLxD(false);
  };

  const batchEdit = async (values: any) => {
    try {
      setLoading(true);
      const customVendorPLPMetadata = {
        etaFromLxD: etaFromLxD ? values?.etaFromLxD : undefined,
      } as Record<any, any>;

      return await new Promise((accept, reject) => {

        const filteredRows = rows?.filter((p: any) => (canEditProjectInvoice && p.varStatus === "INVOICE") || allowedSubmitCriteria(p));

        const plpIds = filteredRows?.map((p: any) => p._id);
        const languageCodes = filteredRows?.map((p: any) => p.targetLanguageCode);

        gpSocket.plp.emit(
          "batchUpdate",
          {
            plpIds,
            customVendorPLPMetadata,
          },
          (e: any) => {
            setLoading(false);
            setConfirmLoading(false);
            if (e.successIds.length > 0) {
              successfulNotification(
                `PLP(s): ${languageCodes.slice(0, 10).join(", ")} have been updated ${languageCodes.length > 10 ? "... " + (languageCodes?.length - 10) + " more" : ''}`
              );
              setIsOpen(false);
              form.resetFields();
              resetCheckboxes();
              return accept(null)
            } else {
              failedNotification(
                `Failed to bulk update:`,
                `PLP(s): ${languageCodes.slice(0, 10).join(", ")} have failed to update ${languageCodes.length > 10 ? '... ' + (languageCodes?.length - 10) + ' more' : ''}`
              )
              return reject(e as Error)
            }
          }
        );
      });
    } catch (err: any) {
      console.error(err)
    }
  };

  const handleModalOpen = (open: boolean) => {
    if (open) {
      form.resetFields();
      resetCheckboxes();
    }
    setIsOpen(open);
  };

  return (
    <>
      <Button
        onClick={() => handleModalOpen(true)}
        className="action-btn edit-btn"
        icon={<EditOutlined />}
        type="primary"
        loading={loading}
        disabled={!allowSubmit && !canEditProjectInvoice || rows.length === 0}
      >
        Edit
      </Button>
      <Modal
        open={isOpen}
        className="batch-modal"
        title="Batch Update"
        okText="Ok"
        cancelText="Cancel"
        onCancel={() => handleModalOpen(false)}
        maskClosable={false}
        onOk={async () => {
          try {
            if (await form.validateFields()) {
              let values = form.getFieldsValue();
              setConfirmLoading(true);
              await batchEdit(values);
            }
          } catch (error) {
            console.error("Validate Failed:", error);
          } finally {
            await props.reload();
          }
        }}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
      >
        <Form
          form={form}
          labelCol={{
            span: 10,
          }}
          wrapperCol={{
            span: 18,
          }}
          labelAlign="left"
          name="plpBatchEditingForm"
        >
          <p>
            Specify the fields to be updated on the selected plps using the
            checkboxes.
          </p>
          <Row gutter={18}>
            <Col span={22}>
              <Form.Item label="ETA from LxD" name="etaFromLxD">
                <DatePicker picker="date" disabled={!etaFromLxD} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item valuePropName="checked">
                <Checkbox
                  defaultChecked={false}
                  onChange={(e) => setEtaFromLxD(e.target.checked)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default PlpBatchUpdate;
