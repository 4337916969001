import { useState, FunctionComponent } from "react";
import {
  failedNotification,
  successfulNotification,
} from "../../../utils/notifications";
import { Button } from "antd";
import { ShareAltOutlined } from "@ant-design/icons";
import { useMercuryContext } from "../../../user-context";
interface IProps {
  projectId: string;
  varStatus?: string;
  salesOrder?: string;
}

const ShareProjectButton: FunctionComponent<IProps> = ({ projectId, varStatus, salesOrder }) => {
  const { gpClient } = useMercuryContext();
  
  const [isSharing, setIsSharing] = useState<boolean>(false);

  const handleClickShare = async () => {
    setIsSharing(true);

    let response = "";
    try {
      const body = {
        shortIds: [projectId],
        type: "MANUAL",
        // "linguistId":"",
        // "email": "",
        // "sesameId": ""
      };

      response = await gpClient.post(
        `/google-gp-v1/share/project/manual`,
        body
      );

      successfulNotification(
        `Your request to share project (${projectId}) has been sent, 
            any documents at IN TRANSLATION will be shared shortly.`,
        ""
      );
    } catch (error) {
      console.log("Sharing request response: ", response);
      console.error("Error sharing: ", error);
      failedNotification(
        `The request to share project ${projectId} failed`,
        ""
      );
    }
    setIsSharing(false);
  };

  return <Button
      loading={isSharing}
      onClick={handleClickShare}
      className="action-btn edit-btn"
      icon={<ShareAltOutlined />}
    >
      Share Project
    </Button>
};

export default ShareProjectButton;
