import React from "react";
import {
    FiniteListWithAsyncLoaderAbstract, ListProps
} from "../../Components";
import {useMercuryContext} from "../../user-context";

class ExtensionStatusFilter extends FiniteListWithAsyncLoaderAbstract {

    statusCodes = [
        { value: 'queued', name: 'Queued' },
        { value: 'pending', name: 'Pending' },
        { value: 'approved', name: 'Approved' },
        { value: 'approved_modified', name: 'Approved - modified' },
        { value: 'rejected', name: 'Rejected' },
        { value: 'Failed Validation', name: 'Failed Validation' },
      ];

    // @ts-ignore 
    async fetch() {
        return this.statusCodes.map( ( item:any ) => {
            return {
                title: item.name,
                value: item.value
            }
        })
    }
}

export default (props:ListProps) => {
    const context = useMercuryContext();
    return <ExtensionStatusFilter context={context} {...props} />
}
