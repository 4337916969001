import { FiniteListWithAsyncLoaderAbstract, ListProps } from "../../Components";
import { useMercuryContext } from "../../user-context";

export const fetchHeadsUpAccuracy = async (context: any) => {

  const { data } = await context.gpClient.get(
    "/google-gp-v1/manualProjects/listFields/headsUpAccuracy" 
  );
  return data.map((item: any) => ({
    title: item.label,
    value: item.value,
  }));
};

class HeadsUpAccuracyFilter extends FiniteListWithAsyncLoaderAbstract {
  fetch() {
    return fetchHeadsUpAccuracy(this.props.context);
  }
}

export default (props: ListProps) => {
  const context = useMercuryContext();
  return <HeadsUpAccuracyFilter context={context} {...props} />;
};
