import React, {Component, createRef} from "react";
import {
    DateFilter,
    ListFilter,
    FiltersBar
} from "../../../Components";
import { useMercuryContext } from "../../../user-context";
import { BlockOutlined, 
    CheckCircleOutlined, 
    CloseCircleOutlined, 
    InfoCircleOutlined, 
    ReloadOutlined } 
    from "@ant-design/icons";
import TimezoneConverter from "../../../timezone-converter";

import { CustomTooltip } from "../../../Components";

// import TableWSAbstract from "../../../stories/Table/TableWS.abstract";

import "../Projects.scss"
import {TableProps} from "antd/lib/table";
import {Key, SorterResult, TableCurrentDataSource, TablePaginationConfig} from "antd/es/table/interface";
import {TableState} from "../../../stories/Table/TableBase";
import uuid from "uuid-browser";

import BaseTable from "../../../stories/Table/BaseTable";
import EmptyOverlay from "../../../stories/Table/EmptyOverlay";
import SharingAttemptsModal from "./SharingAttemptsModal";

import "./SharingTab.scss"
import { Button, Tooltip } from "antd";
import { DateFilterObj } from "../../../stories/DateFilter/DateFilter";
import LanguageCodeFilter from "../../LanguageCode.filter";
import SharingDocumentsStatus from "./SharingDocumentsStatus.filter";
import SharedByFilter from "./SharedBy.filter";
import ShareProjectButton from "./ShareProjectButton";
import NewSharingStatusFilter from "./NewSharingStatus.filter";
import { filterEmptyValues } from "../../../utils/helpers";
import ShareDocumentsButton from "./ShareDocumentsButton";
import { checkPermissions } from "../../../Utilities/checkPermissions";

function TableCell( props: any ) {

    const
        rowData = props.rowData,
        text = rowData[props.column.dataIndex],
        record = rowData;

    if ( props.render ) {
        return props.render(text, record);
    }

    return text;
}

class SharingSearch extends Component<any, any> {

    private readonly defaultState = {
        loading: false,
        tableLoading: false,
        filters: {},
        activeFilters: [],
        filterData: {},
        selectedFilters: [],
        rowData: [],
        selectedRows: []
      }
    
    state = Object.assign(this.defaultState);
    defaultSortField = "lastShared";
    filters = [
        ...this.props?.documentId ? [] : [{
            id: "document",
            name: "document",
            title: "Document ID",
            component: ListFilter,
            active: true
        }],
        {
            id: "type",
            name: "type",
            title: "Shared By",
            component: SharedByFilter,
            active: true
        },
        {
            id: "sesameEmail",
            name: "sesameEmail",
            title: "Sesame ID",
            component: ListFilter,
            active: true
        },
        // {
        //     id: "linguist",
        //     name: "linguist",
        //     title: "Linguist Name",
        //     component: ListFilter,
        //     active: true
        // },
        {
            id: "shared",
            name: "shared",
            title: "Shared",
            component: NewSharingStatusFilter,
            active: true
        },
        ...(this.props?.targetLanguageCode || this.props?.documentId) ? [] : [{
            id: "targetLanguageCode",
            name: "targetLanguageCode",
            title: "Target Language",
            component: LanguageCodeFilter,
            active: true
        }],
        {
            id: "firstShared",
            name: "firstShared",
            title: "First Shared",
            component: DateFilter,
            active: true
        },
        {
            id: "lastShared",
            name: "lastShared",
            title: "Last Shared",
            component: DateFilter,
            active: true
        },
        {
            id: "status",
            name: "status",
            title: "Status",
            component: SharingDocumentsStatus,
            active: true
        }
    ];
    CellRenderer= TableCell;
    columns = [
        ...this.props?.documentId ? [] : [ {
            id: "documentId",
            title: "Document ID",
            dataIndex: "documentId",
            sorter: true,
            width: 140,
            fixed: undefined,
            render: (text: string) =>
                <a href={`https://localization.google.com/polyglot/${text}`} target="_blank" rel="noopener noreferrer" style={{display: 'flex', alignItems: 'center'}}>
                    <BlockOutlined style={{marginRight: '5px' }}/>{text}
                </a>
        }],
        {
            id: "title",
            title: "Title",
            dataIndex: "title",
            sorter: true,
            width: 400,
            fixed: undefined,
            render: (text: string) => <CustomTooltip text={text} length={57}/>,
        },
        {
            id: "type",
            title: "Shared by",
            dataIndex: "type",
            sorter: true,
            width: 80,
            fixed: undefined,
            render: (text: string) => {
                return text
            },
        },
        {
            id: "sesameEmail",
            title: "Seasame ID",
            dataIndex: "sesameEmail",
            sorter: true,
            width: 200,
            fixed: undefined,
        },
        {
            id: "linguistName",
            title: "Linguist Name",
            dataIndex: "name",
            sorter: true,
            width: 200,
            fixed: undefined,
        },
        ...this.props?.targetLanguageCode ? [] : [{
            id: "language",
            title: "Target Language",
            dataIndex: "targetLanguageCode",
            sorter: true,
            width: 140,
            fixed: undefined,
        }],
        {
            id: "shared",
            title: "Shared",
            dataIndex: "shared",
            sorter: true,
            width: 80,
            fixed: undefined,
            render: (text: boolean, record:any) => {

                function hasMixedResponses(items:any) {
                    const shared = items.some((obj:any) => obj.hasOwnProperty('code'));
                    const notShared = items.some((obj:any) => !obj.hasOwnProperty('code'));
                    return shared && notShared;
                }

                if ( text ) {
                    return <div style={{
                        textAlign: "center",
                        display: "block",
                        width: "100%",
                        fontSize: "large"
                    }}>
                        <CheckCircleOutlined style={{color:'var(--primary-color)'}}/>
                        { hasMixedResponses(record.history) && <Tooltip
                            title={
                                <p style={{ marginBottom: "3px" }}>
                                    This share has attempts with different statuses.
                                </p>
                            }
                            placement="top"
                            color={"var(--tertiary-color)"}
                            overlayInnerStyle={{ width: "328px" }}
                            >
                                <InfoCircleOutlined
                                    style={{
                                        marginLeft: "4px",
                                        color: "var(--red)",
                                        cursor: "help",
                                    }}
                                />
                            </Tooltip>
                        }
                    </div>
                }
                return <div style={{
                    textAlign: "center",
                    display: "block",
                    width: "100%",
                    fontSize: "large"
                }}>
                    <CloseCircleOutlined style={{color:'var(--red)'}}/>
                </div>
            },
        },
        {
            id: "lastShared",
            title: "Last attempt",
            dataIndex: "lastShared",
            sorter: true,
            width: 140,
            fixed: undefined,
            render: (text: string) => <TimezoneConverter date={text} />,
        },
        {
            id: "firstShared",
            title: "First attempt",
            dataIndex: "firstShared",
            sorter: true,
            width: 140,
            fixed: undefined,
            render: (text: string) => <TimezoneConverter date={text} />,
        },
        {
            id: "status",
            title: "Status",
            dataIndex: "status",
            sorter: true,
            width: 90,
            align: "center" as any,
            fixed: undefined,
        },
        {
            id: "attempts",
            title: "Attempts",
            dataIndex: "attempts",
            sorter: true,
            width: 60,
            align: "center",
            fixed: undefined,
            render: (text: string, record: any) => {
                return Number(text) > 0 ? <SharingAttemptsModal attempts={text} history={record.history} documentId={record.documentId} /> : '0'
            }
        }
    ] as any;


    renderActions() {
        const userPermissions = this.props.userContext?.userDetails?.permissions || [];
        const canEditSharing = checkPermissions(userPermissions, [
            { customerApplication: "Google", permissionName: "Project Sharing", action: "edit" }
        ]);

        return canEditSharing ? <>
            <ShareProjectButton projectId={this.props.shortId} varStatus={this.props.varStatus} salesOrder={this.props.salesOrder} />
            <ShareDocumentsButton documentIds={this.state.selectedRows.map((item:any) => item.documentId)} varStatus={this.props.varStatus} salesOrder={this.props.salesOrder} />
        </> : <></>
    }

    onResize = () => {
          this.setState({ ...this.state, tableWidth: window.innerWidth - 61 });
    }

    public filtersToQuery() {
        const query: Record<any, any> = Object.assign({}, this.state.filterData,
            this.props.documentId ? { document: [this.props.documentId] } : {}, 
            this.props.targetLanguageCode ? { targetLanguageCode: [this.props.targetLanguageCode] } : {});
    
        Object.keys(query).forEach(k => {
          query[k] = DateFilterObj.getDateRange(query[k])
        });

    
        return query;
      }

    private removeWindowResizeListener() {
        window.removeEventListener("resize", this.onResize);
    }

    public rowMap:Map<string, any> = new Map();

    public floodPrevention = () => {
        clearTimeout(this.floodTimer);
        this.floodTimer = undefined;
        this.setState({ rowData: Array.from( this.rowMap.values() ), changedIds: this.state.changedIds } );
    }

    public floodTimer?:number;

    async componentDidMount(): Promise<void> {
        window.addEventListener("resize", this.onResize);
        await this.getData();
        this.setState({ activeFilters: this.filters, selectedFilters: this.filters.map((filter: any) => filter.name) })
        this.onResize();
    }
    
    componentDidUpdate(prevProps: TableProps<any>, prevState: TableState<any>) {
        if ( prevState.reqId !== this.state.reqId ) {
            const socket = this.props.userContext.gpSocket.sharing;
            socket.off( `search:row:${prevState.reqId}` )
            socket.off( `search:meta:${prevState.reqId}` )

            // console.log( `search:row:${this.state.reqId}` )
            socket.on( `search:row:${this.state.reqId}`, ( row:any ) => {
                // console.log('row: ', row)
                
                const rows = Array.isArray( row ) ? row : [ row ];

                
                rows.forEach( ( item:any, index:number ) => {
                    item = {...item, key: index.toString()}
                    this.rowMap.set( item._id, item );
                } )

                // socket.emit( "bind:ids", Array.from( this.rowMap.keys() ) )

                this.setState({ 
                    loading: false, 
                    changedIds: new Set(), 
                    rowData: Array.from( this.rowMap.values() ) 
                } );

            } );

            // console.log( this.rowMap )

            socket.on( `search:meta:${this.state.reqId}`, ( meta:any ) => {
                this.setState({ currentPage: meta.page, meta: meta })
            }  )
        }
    }

    componentWillUnmount() {
        const socket = this.props.userContext.gpSocket.sharing;
        socket.off( `search:row:${this.state.reqId}` )
        socket.off( `search:meta:${this.state.reqId}` )
        Array.from(( this.rowMap.keys() ) ).forEach( key => {
            socket.off( key );
        } );

        this.removeWindowResizeListener();
    }

    private getSortObject(sortField: string | undefined, sortOrder: string): object {
        return {
          [sortField ?? "lastShared"]: sortOrder === "ascend" ? 1 : -1
        };
    }

    async getData(): Promise<void> {
        const
            socket = this.props.userContext.gpSocket.sharing,
            {sortOrder, sortField} = this.state;

        this.rowMap.clear();

        socket.emit( "search:abort" );

        this.setState( { reqId: uuid(), loading: true }, () => {
            const sort = this.getSortObject(sortField, sortOrder);

            const args = {
                shortId: this.props.shortId,
                filter: filterEmptyValues(this.filtersToQuery()),
                sort: sort,
                reqId: this.state.reqId
            }

            socket.emit(`getHistoryForProject`, args);

        })
    }

    send(): Promise<void> {
        return Promise.resolve(undefined);
    }

    updateAllFilters(updated: any) {
        const { filterData } = this.state;
        filterData[updated.target.name] = updated.target.value;
        this.setState({ filterData, selectedRows: [] })
    }

    onUpdateFilter = (updated: any) => {
        const { filterData } = this.state;
        if (updated.target.value) {
          filterData[updated.target.name] = updated.target.value;
        }
        else {
          delete filterData[updated.target.name];
        }
        this.setState({ filterData, selectedRows: [] }, this.getData)
      }
    
    onClearFilter(update: any) {
        console.log('cleared filter', update)
    }

    clear() {
        this.setState({ filters: {}, filterData: {}, selectedRows: [] })
        this.getData();
    }

    onApplyMoreLess(list: any[]) {
        const newActiveFilters = this.filters.filter(filter => list.includes(filter.name));
        this.setState({ activeFilters: newActiveFilters });
    }
    
    onChangeMoreLess(list: any[]) {
        this.setState({ selectedFilters: list })
    }

    filtersRef: any = createRef();

    renderSharingFilters() {
        
        const { activeFilters, filterData, filtersLoading, loading } = this.state;

        const activeFiltersNames = new Set(activeFilters.map((filter:any) => filter.name));

        const notActiveFilters = this.filters
            .filter((item:any) => !activeFiltersNames.has(item.name))
            .map((item:any) => ({ ...item, active: false }));

        const allFiltersData = [...activeFilters, ...notActiveFilters];
        
        return (
          <div className="filterWrapperRef sharing-tab" ref={this.filtersRef}>
            {activeFilters.length && <FiltersBar
              title={'Filters'}
              onChange={this.updateAllFilters}
              filters={allFiltersData.map((filter: any) => {
                return {
                    id: filter.id,
                    component: filter.component,
                    title: filter.title,
                    key: filter.name,
                    name: filter.name,
                    value: Array.isArray(filter.name)
                    ? filter.name.map(
                        (name: any) => filterData[name]
                    )
                    : filterData[filter.name],
                    // onRemove: this.onRemoveFromList,
                    onChange: this.onUpdateFilter,
                    onClearFilter: this.onClearFilter,
                    disabled: false,
                    active: filter.active,
                    props: []
                };
            })}
              filtersLoading={filtersLoading}
              appliedFilters={this.state.selectedFilters}
              onClearAll={(params: any) => {
                  this.clear();
              }}
              loading={loading}
              additionalButtons={[
                <Button
                  size={"small"}
                  disabled={this.state.loading}
                  key={"reload"}
                  onClick={async () => {
                    this.getData()
                  }}
                >
                  <ReloadOutlined spin={this.state.loading} /> Reload
                </Button>
              ]}
              controlsEnabled={false}
              onApplyMoreLess={(list) => this.onApplyMoreLess(list)}
              onChangeMoreLess={(list) => this.onChangeMoreLess(list)}
            />}
          </div>
        );
      }
    
    rowClassName = (record: any): string => {
        return 'row'
    }

    selectAll = (all: boolean) => {
        if (all) {
            this.setState({
                selectedRows: this.state.rowData.map((item:any) => {
                    return {...item, _id: item.key, id: item.key}
                })
            });
        } else {
            this.setState({selectedRows: []});
        }
    }

    select = (e: any) => {
        const
            {selectedRows} = this.state,
            exists = selectedRows.filter((r:any) => r.key === e.key);

        let
            selected = [];

        if (exists.length) {
            selected = selectedRows.filter((r:any) => r.key !== e.key);
        } else {
            selected = selectedRows.concat([e]);
        }

        this.setState({selectedRows: selected});
    }

    sort(
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
        extra: TableCurrentDataSource<any>
    ) {

        const {order, field} = sorter as SorterResult<any>;
        this.setState({
            sortOrder: order || (this.state.sortOrder === "descend" ? "ascend" : "descend"),
            sortField: order !== undefined ? field : this.defaultSortField as any
        }, () => {
            this.getData();
        })
    }

    render() {
        // NOTE: The base table is expecting _id to be an unique string
        const dataSrc = this.state.rowData ? this.state.rowData.map((item:any) => {return {...item, _id: item.key}}) : [];

        return <>
            <div className="sharing-actions">
                {this.renderActions()}
            </div>
            {this.renderSharingFilters()}
            <BaseTable
                className='sharing-table'
                dataSource={dataSrc}
                columns={ this.columns }
                onColumnSort={(sorter: any) => this.sort({}, {}, sorter, {} as any)}
                sortBy={{ key: this.state.sortField, order: this.state.sortOrder === 'ascend' ? 'asc' : this.state.sortOrder === 'descend' ? 'desc' : this.state.sortOrder}}
                loading={this.state.loading}
                rowClassName={this.rowClassName}
                rowSelection={{
                  selectedRowKeys: this.state.selectedRows.map((r: any) => r.key) as Key[],
                  onSelectAll: this.selectAll,
                  onSelect: this.select,
                  fixed: true
                }}
                rowHeight={this.props.rowHeight}
                enabledRowSelection={true}
                itemsPerPage={this.state.itemsPerPage}
                showFilters={this.state.showFilters}
                CellRenderer={this.props.CellRender}
                hasSidebar={false}
                filtersRef={this.props.filtersRef}
                width={this.state.tableWidth}
                height={500}
                enabledVirtualScrollbar={false}
                emptyRenderer={<EmptyOverlay reset={()=>{this.clear()}} loading={this.state.loading} />}
            />
    </>
    }
}

export default function SharingTab({
  shortId,
  targetLanguageCode,
  documentId,
  varStatus,
  salesOrder
}: any) {
  const context = useMercuryContext();
  return (
    <SharingSearch
      id={"Projects"}
      shortId={shortId}
      documentId={documentId}
      targetLanguageCode={targetLanguageCode}
      varStatus={varStatus}
      salesOrder={salesOrder}
      userContext={context}
    />
  );
}
