import { Descriptions } from "antd";
import TimezoneConverter from "../../../timezone-converter";

interface DetailField {
  label: string;
  value: any;
  editable?: boolean;
  type?: "text" | "select" | "date" | "number" | "float" | "textarea";
  required?: boolean;
}

interface DetailsProps {
  details: Record<string, any>;
  fetch: (updating: any) => void;
  catToolStatus?: string;
}

const ManualProjectInformation: React.FC<DetailsProps> = (props) => {
  const {
    shortId,
    rwsDeliveryLink,
    buganizerId,
    productionStatus,
    clientPO,
    clientQuoteLink,
    complexity,
    costCode,
    headsUpAccuracy,
    headsUpDate,
    headsUpReceived,
    internalCost,
    internalQuoteLink,
    lptPMNotes,
    priority,
    product,
    projectDropDate,
    projectName,
    quoteStatus,
    quoteSubmission,
    so,
    surface,
    testCases,
    testLeadHours,
    testerHours,
    testingProjectType,
    testingStart,
  } = props.details;

  const displayFields: { [key: string]: DetailField } = {
    buganizerId: { label: "Buganizer", value: buganizerId, type: "text" },
    product: {
      label: "Products",
      value: product,
      type: "select",
    },
    projectName: { label: "Project Name", value: projectName, type: "text" },
    shortId: {
      label: "Polyglot ID",
      value: shortId ? shortId : <i>Not Set</i>,
      type: "text",
      editable: false,
    },
    testingProjectType: {
      label: "Project Type",
      value: testingProjectType,
      type: "select",
    },
    projectDropDate: {
      label: "Project Drop date",
      value: projectDropDate,
      type: "date",
    },
    lptPMNotes: { label: "LPT PM Notes", value: lptPMNotes, type: "textarea" },
    rwsDeliveryLink: {
      label: "RWS Delivery (link)",
      value: rwsDeliveryLink ? rwsDeliveryLink : <i>Not Set</i>,
      type: "text",
      editable: false,
    },
    priority: {
      label: "Priority",
      value: priority,
      type: "select",
    },
    testCases: { label: "Test cases/locale", value: testCases, type: "float" },
    complexity: {
      label: "Complexity",
      value: complexity,
      type: "select",
    },
    surface: {
      label: "Surface",
      value: surface,
      type: "select",
    },
    productionStatus: {
      label: "Production Status",
      value: productionStatus,
      type: "select",
    },
    headsUpReceived: {
      label: "Heads-up Received",
      value: headsUpReceived ? "Yes" : "No",
      type: "select",
    },
    headsUpDate: { label: "Heads-up date", value: headsUpDate, type: "date" },
    headsUpAccuracy: {
      label: "HU (heads-up) Accuracy",
      value: headsUpAccuracy,
      type: "select",
    },
    testingStart: { label: "Testing Start", value: testingStart, type: "date" },
    quoteSubmission: {
      label: "Quote Submission",
      value: quoteSubmission,
      type: "date",
    },
    quoteStatus: {
      label: "Quote Status",
      value: quoteStatus,
      type: "select",
    },
    clientQuoteLink: {
      label: "Client Quote (link)",
      value: clientQuoteLink,
      type: "text",
    },
    internalQuoteLink: {
      label: "Internal Quote (link)",
      value: internalQuoteLink,
      type: "text",
    },
    internalCost: {
      label: "Internal Cost",
      value: internalCost,
      type: "float",
    },
    so: { label: "SO", value: so, type: "select" },
    clientPO: { label: "Client PO", value: clientPO, type: "text" },
    testerHours: { label: "Tester Hours", value: testerHours, type: "number" },
    testLeadHours: {
      label: "Test Lead Hours",
      value: testLeadHours,
      type: "number",
    },
    costCode: {
      label: "Cost Code",
      value: costCode,
      type: "select",
    },
    catToolStatus: {
      label: "Polyglot Status",
      value: props.catToolStatus ? props.catToolStatus : <i>Not Set</i>,
      type: "text",
      editable: false,
    },
  };

  return (
    <>
      <Descriptions bordered={true} column={2} size={"small"}>
        {Object.entries(displayFields).map(([key, field]: any) => (
          <Descriptions.Item
            label={field.label}
            key={key}
            span={1}
            contentStyle={{
              wordBreak: "break-word",
              overflowWrap: "break-word",
              maxWidth: "200px",
            }}
          >
            {field.type === "date" ? (
              <TimezoneConverter date={field.value} />
            ) : (
              field.value
            )}{" "}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </>
  );
};

export default ManualProjectInformation;