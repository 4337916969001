import React, {
  useEffect,
  useState,
  useCallback,
  FunctionComponent,
} from "react";
import { Helmet } from "react-helmet";

import { useMercuryContext } from "../user-context";

import { Button, Table, Tooltip } from "antd";
import { ColumnsType, TableProps } from "antd/lib/table";

import PageTitle from "../ui/PageTitle";
import BackTopButton from "../ui/BackTopButton";
import Total from "../ui/Total";

import "./FailedDownload.scss";
import { Error } from "../stories/Errors/Error";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
// import { usePermissions } from "../hooks/usePermissions";


interface FailedProject {
  projects: number;
  product: string;
  key?: React.Key;
  name?: string;
}

const FailedDownload: FunctionComponent = () => {
  const { gpClient:client } = useMercuryContext(),
    [projects, setProjects] = useState<FailedProject[]>([]),
    [loading, setLoading] = useState<boolean>(true),
    [error, setError] = useState<boolean>(false),
    [total, setTotal] = useState<number>(0),
    [selectedRows, setSelectedRows] = useState<FailedProject[]>([]),
    [currentPage, setCurrentPage] = useState<number>(1);

    // const { hasPermissions } = usePermissions();

  const getData = useCallback(async () => {
    setLoading(true);

    const { data } = await client.get(
      `/google-gp-v1/FailedDownload/paginate?page=${
        currentPage - 1
      }`
    );

    setProjects(
      data.documents?.map((p: FailedProject, index: number) => {
        return { ...p, key: index };
      })
    );

    setTotal(data?.total);

    setError(false);
    setLoading(false);
  }, [client, currentPage]);

  useEffect(() => {
    try {
      getData();
    } catch (error: any) {
      console.error(error.toString());
      setLoading(false);
      setError(true);
    }

    return () => {
      setProjects([]);
    };
  }, [client, getData]);

  const handleClickReDownload = () => {
    // TODO: call re download endpoint, TBD on backend
    if (selectedRows.length !== 0) {
      console.log("TO BE Re-downloaded", selectedRows);
    }

    setSelectedRows([]);
  };

  const handleChangeTable: TableProps<FailedProject>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    if (pagination.current) {
      setCurrentPage(pagination.current);
    }

  };

  const columns: ColumnsType<FailedProject> = [
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Payload",
      dataIndex: "payload",
    },
    {
      title: "Error Message",
      dataIndex: "error",
    },
    {
      title: "Resolved",
      dataIndex: "resolved",
      width: 80,
      render: (text, record) =>
        text ? (
          <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
        ) : (
          <CloseOutlined style={{ color: "var(--red)" }} />
        ),
    },
    {
      title: "SQS ID",
      dataIndex: "sqsId",
    },
  ];

  if (error) {
    return <Error reload={getData} error={"Please reload"} />;
  }
  // example on how to implement a permission check
  // const hasAccess = hasPermissions([{ customerApplication: "Google", permissionName: "Test Stefan", action: "create" }]); 

  return (
    <div className="manage-page manage-failed-download">
      <Helmet>
        <title>Manage Failed to download projects - Mercury © RWS</title>
      </Helmet>
      <PageTitle title="Failed to download projects" />
        {/* {hasAccess ? <p>Permissions - User has access</p> : <p>Permissions - NO access!!!!!!</p>} */}
      <div className="top">
        <div className="actions">
          <Tooltip
            title={
              selectedRows.length === 0
                ? "Select at least 1 project"
                : "Re-download selected projects"
            }
            placement="right"
            color={"var(--tertiary-color)"}
          >
            <Button
              type="primary"
              disabled={selectedRows.length === 0 ? true : false}
              onClick={() => handleClickReDownload()}
            >
              Re-download
            </Button>
          </Tooltip>
        </div>
        <Total number={total} text="failed projects" />
      </div>
      <Table
        columns={columns}
        dataSource={projects}
        loading={loading}
        className="manage-page-table"
        size="small"
        onChange={handleChangeTable}
        rowSelection={{
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: FailedProject[]
          ) => {
            setSelectedRows(selectedRows);
          },
          selectedRowKeys: selectedRows.map(
            (r: FailedProject) => r.key
          ) as React.Key[],
        }}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 50,
          current: currentPage,
          total: total,
          showSizeChanger: false,
        }}
      />
      <BackTopButton />
    </div>
  );
};

export default FailedDownload;
