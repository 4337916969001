import React, { useState, useCallback, useEffect } from "react";
import { AutoComplete, Spin } from "antd";
import { WebSocket as MockWebSocket } from "mock-socket";
import "./AutoComplete.scss";
import { useMercuryContext } from "../../user-context";

interface IOption {
  value: string;
  title: string;
  url: string;
  _id: string;
}

interface IForm {
  setFieldsValue: (data: any) => void;
}

interface IAutoCompleteComponentProps {
  form?: IForm;
  name?: string;
  websocket?: string;
  emit?: string;
  handleMainMenuSelection?: (selection: string) => void;
  navigate?: (url: string) => void;
}

const { Option } = AutoComplete;

const AutoCompleteComponent: React.FC<IAutoCompleteComponentProps> = ({
  form = { setFieldsValue: () => {} },
  name = "linkproject",
  websocket,
  emit,
  handleMainMenuSelection,
  navigate
}) => {
  const context = useMercuryContext();

  const [value, setValue] = useState<string>(""),
    [options, setOptions] = useState<IOption[]>([]),
    [loadingOptions, setLoadingOptions] = useState<boolean>(false),
    [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const onSearch = (data: string) => {
    if (data) {
      if (data.length > 1) {
        getOptionsData(data);
      } else {
        setOptions([]);
      }
      setValue(data);
      form.setFieldsValue({ [name]: data });
    } else {
      setValue("");
      setOptions([]);
      form.setFieldsValue({ [name]: "" });
    }
  };

  const onSelect = (value: string, option: any) => {
    setValue(value);
    form.setFieldsValue({ [name]: value });
    setIsDropdownOpen(false);
    if (handleMainMenuSelection) {
      handleMainMenuSelection('projects');
    }
    if (navigate) {
      closeDropdown();
      navigate(option.url);
    }
  };

  useEffect(() => {
    let socket: MockWebSocket | any;

    if (websocket) {
      socket = new MockWebSocket(websocket);
    } else {
      socket = context?.gpSocket?.project;
    }

    if (socket && typeof socket.on === "function") {
      socket.on(emit, (data: any) => {
        setOptions((prevOptions) => [...prevOptions, data]);
      });

      return () => {
        if (typeof socket.off === "function") {
          socket.off(emit);
        }
      };
    } else {
      console.error("Socket is not initialized or does not have 'on' method");
    }
  }, [context, websocket, emit]);

  const closeDropdown = () => {
    setIsDropdownOpen(false);
    setLoadingOptions(false);
    setValue("");
    setOptions([]);
  };

  const getOptionsData = useCallback(
    async (search: string) => {
      setLoadingOptions(true);
      setOptions([]);
  
      const socket = websocket ? new MockWebSocket(websocket) : context?.gpSocket?.project;
  
      if (socket && typeof socket.emit === "function") {
        socket.emit(emit, { search, limit: 10 }, () => {
          setLoadingOptions(false);
        });
      } else {
        // Use mock web socket if set
        if (socket.readyState === WebSocket.OPEN) {
          socket.send(JSON.stringify({ search, limit: 10 }));
        } else {
          socket.onopen = () => {
            socket.send(JSON.stringify({ search, limit: 10 }));
          };
        }
  
        socket.onmessage = (event: MessageEvent) => {
          const data = JSON.parse(event.data);
          setOptions(data);
          setLoadingOptions(false);
        };
  
        socket.onerror = () => {
          console.error("WebSocket error occurred");
          setLoadingOptions(false);
        };
      }
    },
    [context, websocket, emit]
  );

  const handleDropdownChange = (open: boolean) => {
    setIsDropdownOpen(open);
  };

  const renderedOptions = options.map((o) => {
    return (
      <Option key={o._id} value={o.value} url={o.url}>
        {o.value}
      </Option>
    );
  });

  return (
    <div className="search-project-autocomplete">
      {loadingOptions && <Spin size="small" style={{ marginRight: "10px" }} />}
      <AutoComplete
        style={{ minWidth: '150px' }}
        onSearch={onSearch}
        onSelect={onSelect}
        value={value}
        popupMatchSelectWidth={value.length > 1 ? 850 : 250}
        onDropdownVisibleChange={handleDropdownChange}
        open={isDropdownOpen}
        defaultActiveFirstOption={true}
        allowClear
        onClear={closeDropdown}
        placeholder="Search project"
        notFoundContent={
          value.length <= 1
            ? "Please type at least 2 characters"
            : loadingOptions
            ? "Loading..."
            : "No Match"
        }
      >
        {renderedOptions}
      </AutoComplete>
    </div>
  );
};

export default AutoCompleteComponent;