import React from "react";
import { Drawer, Form, Input, Button, InputNumber } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { usePermissions } from "../../../hooks/usePermissions";

interface DrawerWithFormProps {
  form: any;
  openDrawer: () => void;
  closeDrawer: () => void;
  isOpen: boolean;
  type: string;
  onSubmit: (values: any) => void;
  submitting: boolean;
}

const DelayNotificationForm: React.FC<DrawerWithFormProps> = ({
  form,
  openDrawer,
  closeDrawer,
  isOpen,
  type,
  onSubmit,
  submitting,
}) => {
  const { hasPermissions } = usePermissions();
  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      onSubmit(values);
    } catch (error) {
      console.error("Validation Failed:", error);
    }
  };

  const handleFloatInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !/[0-9.]/.test(event.key) &&
      !["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].includes(
        event.key
      )
    ) {
      event.preventDefault();
    }
  };

  const canAddNotifications = hasPermissions([
    {
      customerApplication: "Google",
      permissionName: "Delayed Status Notification",
      action: "create",
    },
  ]);

  return (
    <>
      <Button
        disabled={!canAddNotifications}
        type="primary"
        icon={<PlusOutlined />}
        onClick={openDrawer}
      >
        Add New
      </Button>

      <Drawer
        title={`${type} Delayed Status Notification`}
        open={isOpen}
        onClose={closeDrawer}
        className="manage-pages-drawer"
        footer={
          <div style={{ textAlign: "right" }}>
            <Button onClick={closeDrawer} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleSubmit} loading={submitting}>
              Submit
            </Button>
          </div>
        }
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Workflow Status"
            name="status"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Delay Threshold"
            name="threshold"
            rules={[
              { required: true, message: "Please enter the number of hours" },
              {
                type: "number",
                min: 1,
                max: 24,
                message: "Hours should be between 1 and 24",
              },
            ]}
          >
            <InputNumber
              onKeyDown={handleFloatInput}
              min={1}
              max={24}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            label="Notification Email"
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Invalid email format",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default DelayNotificationForm;
