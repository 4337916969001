import { useEffect, useState, useCallback } from "react";
import { Button, Modal, Form, Popconfirm } from "antd";
import { useMercuryContext } from "../../user-context";
import { failedNotification, successfulNotification } from "../../utils/notifications";
import { LinkOutlined, WarningOutlined } from "@ant-design/icons";
import AutoCompleteComponent from "../../stories/AutoComplete/AutoComplete";

const LinkProject = ({ initialValues, reload }: any) => {
  const { gpClient } = useMercuryContext();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [details, setDetails] = useState(initialValues);
  const [popVisible, setPopVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setDetails(initialValues);
  }, [initialValues]);

  const toggleModal = useCallback(() => {
    setIsModalOpen((prev) => !prev);
    if (isModalOpen) {
      form.resetFields();
    }
  }, [isModalOpen, form]);

  const handleResponse = useCallback((response: any, successMessage: any) => {
    if (response?.status === 200 || response?.status === 201) {
      reload(() => {
        successfulNotification("Success", successMessage);
      });
      setIsModalOpen(false);
      form.resetFields();
    } else {
      throw new Error("Unexpected response from server");
    }
  }, [reload, form]);

  const handleError = useCallback((error: any) => {
    const errorData = error?.response?.data || {};
    const errorMessage =
      errorData?.message ||
      errorData?.errorResponse?.errmsg ||
      errorData?.details?.toString() ||
      "We've found an internal error saving data. Please, try again";
    failedNotification("Error", errorMessage);
  }, []);

  const linkProject = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);
      const newShortId = values.linkproject.split(':')[0].trim();
      const response = await gpClient.patch(
        `/google-gp-v1/manualProjects/linkProject/${initialValues.id}`,
        { shortId: newShortId }
      );
      handleResponse(response, details?.shortId ? `The project: ${details?.shortId} has been unlinked` : `The project: ${newShortId} has been linked`);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const unlinkProject = async () => {
    setPopVisible(false);
    try {
      setLoading(true);
      const response = await gpClient.patch(
        `/google-gp-v1/manualProjects/unlinkProject/${initialValues.id}`
      );
      handleResponse(response, details?.shortId ? `The project: ${details?.shortId} has been unlinked` : `The project: ${details?.shortId} has been linked`);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const showPopConfirm = () => {
    setPopVisible(true);
  };

  const handlePopCancel = () => {
    setPopVisible(false);
  };

  return (
    <>
      <Popconfirm
        open={popVisible}
        title={`Are you sure you want to unlink project: ${details?.shortId} ?`}
        onConfirm={unlinkProject}
        onCancel={handlePopCancel}
        okText="Yes"
        cancelText="No"
        okType="danger"
        icon={<WarningOutlined style={{ color: "var(--red)" }} />}
        cancelButtonProps={{ type: "primary" }}
        okButtonProps={{ type: "primary" }}
        placement="top"
      >
        <Button
          type="primary"
          onClick={details?.shortId ? showPopConfirm : toggleModal}
          icon={<LinkOutlined />}
        >
          {details?.shortId ? "Unlink Project" : "Link Project"}
        </Button>
      </Popconfirm>
      <Modal
        open={isModalOpen}
        className="autocomplete-project-modal"
        title={details?.shortId ? `Unlink Project` : `Link Project`}
        okText="Ok"
        cancelText="Cancel"
        onCancel={toggleModal}
        maskClosable={false}
        onOk={linkProject}
        destroyOnClose={true}
        confirmLoading={loading}
        width={800}
        forceRender
      >
        <Form
          name="autocomplete-project-form"
          form={form}
          layout="vertical"
        >
          <Form.Item
            name="linkproject"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <AutoCompleteComponent
              form={form}
              name={"linkproject"}
              emit={"autocomplete"}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default LinkProject;