import { LikeFilled, LikeOutlined } from '@ant-design/icons';
import {
    Tooltip,
    Form,
    Button,
    List,
    Mentions,
    Typography
} from 'antd';
import { Comment as CommentComponent } from '@ant-design/compatible';
import React, { createElement, useState } from 'react';
import { DateTime } from "luxon"
import Avatar from "../Avatar/Avatar";
// import CustomMentions from "./Mentions"
import "./Comments.scss";
import varStatus from '../../components/filters/varStatus';

interface CommentUser {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    name: string;
    online: boolean;
}

interface Mentionable {
    _id: string;
    name: string;
    email?:string;
}

interface Comment {
    _id: string;
    userDoc?: CommentUser;
    text: string;
    replies?: Reply[];
    firstName: string;
    lastName: string;
    email: string;
    name: string;
    createdAt: string;
    isReply?: boolean;
    setLike: ( id:string, user:string, type?:string, collection?:string ) => Promise<void>;
    addReply: ( id:string, text:string, user:string, type?:string, collection?:string ) => Promise<void>;
    likes: string[];
    currentUser: string;
    mentionables?: Mentionable[]
}

interface Commenter {
    _id: string;
    onComment:( id:string, text:string, user:string, type?:string, collection?:string ) => Promise<void>;
    placeholder?:string;
    user:string;
    name: string;
    mentionables?: Mentionable[];
    type?: string;
    collection?:string;
}

interface Reply extends Omit<Comment, "replies"|"addReply"|"currentUser" > {
    isReply: boolean;
    currentUser?:string;
}

const
    { Option } = Mentions;

const { Link } = Typography;

const Commenter = ( props:Commenter ) => {

    const
        [ text, setText ] = useState(""),
        [ commenting, setCommenting ] = useState( false ),
        [ sending, setSending ] = useState( false );

    async function comment() {
        setSending( true );
        await props.onComment( props._id, text, props.user, props.type, props.collection );
        setSending( false );
        setText("");
        setCommenting( false )
    }

    const handleClickCancel = () => {
        setText("");
        setCommenting(!commenting);
    }

    return commenting
        ? (
            <div className={"comment-commenter-container"}>
                <Form.Item>
                    <Mentions rows={4} placeholder={props.placeholder} disabled={sending} onChange={( e ) => setText( e )} value={text} onSelect={e =>{
                        // console.log( "selected", e )
                        // @ts-ignore
                        return e.name;
                    }}>
                        {
                            props.mentionables && props.mentionables.length && props.mentionables.map( ( mentionable ) => {
                                // @ts-ignore
                                return <Option key={mentionable._id} name={mentionable.name} value={mentionable._id} id={mentionable._id}>{mentionable.name}{ mentionable.email ? ` ( ${mentionable.email} )`: ""}</Option>
                            })
                        }
                    </Mentions>
                </Form.Item>
                <Form.Item className={"comment-reply-buttons"}>
                    <Button onClick={() => handleClickCancel()}>Cancel</Button>
                    <Button disabled={sending || text.length === 0} htmlType="submit" loading={false} onClick={comment} type="primary">
                        Add Comment
                    </Button>
                </Form.Item>
            </div>
        )
        : <Link
            onClick={() => setCommenting(!commenting)}
        >
            Add a comment
        </Link>
}

const Reply = ( props:Reply ) => {
    const actions = [
        // Maybe???
        <Tooltip key="comment-basic-like" title="Like">
            <span onClick={() => props.setLike( props._id, props.currentUser! )}>
                {createElement(props.likes && props.likes.includes(props.currentUser!) ? LikeFilled : LikeOutlined)}
                <span className="comment-action">{props.likes.length}</span>
            </span>
        </Tooltip>
    ];

    return <CommentComponent
        actions={actions}
        author={<span>{props.name} ( {props.email} )</span>}
        avatar={<Avatar {...props} size={50}/>}
        content={
            <p className={"comment-text"}>{
                props.text
            }</p>
        }
        datetime={
            <Tooltip title={DateTime.fromISO( props.createdAt ).toFormat( "YYYY-MM-DD HH:MM:SS")}>
                <span>{DateTime.fromISO( props.createdAt ).toRelativeCalendar()}</span>
            </Tooltip>
        }
    />

}

const Comment = ( props:Comment ) => {

    const
        [ replyText, setReplyText ] = useState( "" ),
        [ disabled, setDisabled ] = useState( false ),
        [ replying, setReplying ] = useState( false );

    const actions = [
        // Maybe???
        <Tooltip key="comment-basic-like" title="Like">
            <span onClick={() => props.setLike( props._id, props.currentUser )}>
                {createElement(props.likes && props.likes.includes(props.currentUser) ? LikeFilled : LikeOutlined)}
                <span className="comment-action">{props.likes && props.likes.length}</span>
            </span>
        </Tooltip>,
        !replying && !props.isReply && props.replies && props.replies.length === 0 && (
            <Link
              key="comment-basic-reply-to"
              onClick={() => setReplying(!replying)}
            >
              Reply to {props.firstName}
            </Link>
          )
    ];

    async function reply() {
        setDisabled( true )
        await props.addReply( props._id, replyText, props.currentUser );
        setDisabled( false );
        setReplyText( "" );
        setReplying( false );
    }

    return <CommentComponent
        actions={actions}
        author={<span>{props.name} ( {props.email} )</span>}
        avatar={<Avatar {...props} size={50}/>}
        content={
            <p className={"comment-text"}>{
                props.text
            }</p>
        }
        datetime={
            <Tooltip title={DateTime.fromISO( props.createdAt ).toFormat( "YYYY-MM-DD HH:MM:SS")}>
                <span>{DateTime.fromISO( props.createdAt ).toRelativeCalendar()}</span>
            </Tooltip>
        }
        >
        {
            props.replies && props.replies.length ? [
                <List
                    key={"replies"}
                    dataSource={props.replies.map((reply, key) => {
                        return {...reply, isReply: true, currentUser: props.currentUser, setLike: props.setLike, key, addReply: () => {} }
                    })}
                    header={props.replies.length ? `${props.replies.length} ${props.replies.length > 1 ? 'replies' : 'reply'}` : null }
                    itemLayout="horizontal"
                    renderItem={props => <Reply {...props} />}
                />,
                !replying && !props.isReply && props.replies && props.replies.length > 0 && (
                    <Link
                      key="comment-basic-reply-to"
                      onClick={() => setReplying(!replying)}
                    >
                      Reply to {props.firstName}
                    </Link>
                  ),
                replying && <div className={"comment-reply-container"} key={"comment-reply-container"}>
                  <Form.Item>
                    <Mentions rows={4}  onChange={( e ) => setReplyText( e )} value={replyText}>
                        {
                            props.mentionables && props.mentionables.length && props.mentionables.map( ( mentionable ) => {
                                return <Option key={mentionable._id} value={mentionable.name}>{mentionable.name}</Option>
                            })
                        }
                    </Mentions>
                  </Form.Item>
                  <Form.Item className={"comment-reply-buttons"}>
                    <Button disabled={disabled} htmlType="submit" loading={false} onClick={reply} type="primary">
                      Add Comment
                    </Button>
                    <Button disabled={disabled} onClick={() => setReplying(false)}>Cancel</Button>
                  </Form.Item>
                </div>
            ] : replying && <div className={"comment-reply-container"} key={"comment-reply-container"}>
              <Form.Item>
                <Mentions rows={4} onChange={( e ) => setReplyText( e )} value={replyText}>
                    {
                        props.mentionables && props.mentionables.length && props.mentionables.map( ( mentionable ) => {
                            return <Option key={mentionable._id} value={mentionable.name}>{mentionable.name}</Option>
                        })
                    }
                </Mentions>
              </Form.Item>
              <Form.Item className={"comment-reply-buttons"}>
                <Button disabled={disabled} htmlType="submit" loading={false} onClick={reply} type="primary">
                  Add Reply
                </Button>
                <Button disabled={disabled} onClick={() => setReplying(false)}>Cancel</Button>
              </Form.Item>
            </div>
        }
    </CommentComponent>
};

export { Comment, Commenter }
export default Comment;
