import { Descriptions, Switch, Tooltip } from "antd";
import TimezoneConverter from "../../../timezone-converter";
import PolyglotLogo from "../../../stories/assets/polyglot-logo.svg";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { failedNotification } from "../../../utils/notifications";
import { useMercuryContext } from "../../../user-context";
import { useState } from "react";

const DocumentGeneralInformation = (props: any) => {
  const { gpClient, userDetails } = useMercuryContext();
  const { details, fetch } = props;
  const [updating, setUpdating] = useState(false);

  const {
    documentId,
    product,
    catToolStatus,
    sourceLanguageCode,
    creationTimestamp,
    lastUpdatedTimestamp,
    preprocessingDueTimestamp,
    translationDueTimestamp,
    translationDeliveredTimestamp,
    postprocessingDueTimestamp,
    postprocessingDelivered,
    customVendorDocumentMetadata,
    legallySensitive,
    id,
  } = details;
  const taskId = documentId?.split("/")?.[1];

  const {
    lastImportedTimestamp,
    lastSharedDate,
    importedTimestamp,
    autoCompleteBlocked,
    autoCompleteBlockedReason,
    silenced,
  } = customVendorDocumentMetadata || {};

  const update = async (payload: any) => {
    const data = {
      ids: [id],
      ...payload,
      customVendorDocumentMetadata: {
        ...payload?.customVendorDocumentMetadata,
        ...(payload?.customVendorDocumentMetadata?.hasOwnProperty(
          "silenced"
        ) && {
          silencedBy: userDetails?.id,
        }),
      },
    };
    setUpdating(true);
    try {
      await gpClient.put("/google-gp-v1/document/merge", data);
      fetch(setUpdating);
    } catch (error: any) {
      failedNotification(error.response.data.error, error.response.data.detail);
    }
  };

  return (
    <Descriptions
      bordered={true}
      column={2}
      size={"small"}
      className="document-general-information"
    >
      <Descriptions.Item label="Product">{product}</Descriptions.Item>
      <Descriptions.Item label="Source Language">
        {sourceLanguageCode}
      </Descriptions.Item>
      <Descriptions.Item label="Pre-Processing Due Date">
        <TimezoneConverter date={preprocessingDueTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Translation Due Date">
        <TimezoneConverter date={translationDueTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Translation Delivered">
        <TimezoneConverter date={translationDeliveredTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Post-Processing Due Date">
        <TimezoneConverter date={postprocessingDueTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Post-Processing Deliverd Date">
        <TimezoneConverter
          date={postprocessingDelivered?.deliveredTimestamppp}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Last Imported Timestamp">
        <TimezoneConverter date={lastImportedTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Last Update Timestamp">
        <TimezoneConverter date={lastUpdatedTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Status">{catToolStatus}</Descriptions.Item>
      <Descriptions.Item label="Received Date">
        <TimezoneConverter date={importedTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Last Shared Date">
        <TimezoneConverter date={lastSharedDate} />
      </Descriptions.Item>
      <Descriptions.Item label="Creation Timestamp">
        <TimezoneConverter date={creationTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Polyglot">
        <a
          href={`https://localization.google.com/polyglot/tasks/${taskId}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ userSelect: "none", display: "flex", alignItems: "center" }}
          title="View document in Polyglot"
        >
          {documentId}
          <img
            src={PolyglotLogo}
            alt="polyglot"
            style={{ width: "auto", height: "20px", marginLeft: "4px" }}
          />
        </a>
      </Descriptions.Item>
      <Descriptions.Item label="Auto Complete Blocked">
        {autoCompleteBlocked ? (
          <Tooltip title={autoCompleteBlockedReason}>
            <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
          </Tooltip>
        ) : (
          <CloseOutlined style={{ color: "var(--red)" }} />
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Legally Sensitive">
        {legallySensitive?.isSet ? (
          <CheckOutlined
            style={{ color: "var(--tertiary-color)" }}
            className="icon"
          />
        ) : (
          <CloseOutlined style={{ color: "var(--red)" }} className="icon" />
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Silence Notifications">
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={silenced}
          loading={updating}
          onChange={async (v) => {
            update({
              customVendorDocumentMetadata: {
                silenced: v,
              },
            });
          }}
        />
      </Descriptions.Item>
    </Descriptions>
  );
};

export default DocumentGeneralInformation;
