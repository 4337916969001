// Custom hook to check permissions on functional components
// for class components see Utilities/checkPermissions.tsx

import { useMercuryContext } from "../user-context";

interface PermissionCheck {
  permissionName: string;
  customerApplication: string;
  action: "create" | "edit" | "delete" | "read";
}

interface IPermission {
  read: boolean;
  create: boolean;
  delete: boolean;
  edit: boolean;
  customerApplication: string;
  name: string;
  permissionId: string;
}

export const usePermissions = () => {
  const { userDetails } = useMercuryContext();
  const userPermissions: IPermission[] = userDetails?.permissions || [];

  const hasPermissions = (requiredPermissions: PermissionCheck[]): boolean => {
    return requiredPermissions.some((requiredPermission) =>
      userPermissions.some(
        (perm) =>
          perm.name === requiredPermission.permissionName &&
          perm[requiredPermission.action] === true &&
          perm.customerApplication === requiredPermission.customerApplication
      )
    );
  };

  return { hasPermissions };
};
