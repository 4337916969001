import { Helmet } from "react-helmet";
import PageTitle from "../ui/PageTitle";
import "../components/managePages/delayed-status-notifications/dsNotification.scss";
import DSNotificationForm from "../components/managePages/delayed-status-notifications/DSNotificationForm";
import { ReloadOutlined } from "@ant-design/icons";
import { Button, Form } from "antd";
import DSNotificationsTable from "../components/managePages/delayed-status-notifications/DSNotificationsTable";
import { useEffect, useState } from "react";
import { useMercuryContext } from "../user-context";
import {
  failedNotification,
  successfulNotification,
} from "../utils/notifications";

const ManageDSNotifications = () => {
  const [form] = Form.useForm();
  const { gpClient } = useMercuryContext();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [currentId, setCurrentId] = useState("");

  const openDrawer = (values?: any) => {
    if (Object.keys(values)?.length) {
      setCurrentId(values?.id);
      form.setFieldsValue(values);
    }
    setIsOpen(true);
  };

  const closeDrawer = () => {
    setIsOpen(false);
    form.resetFields();
  };

  const fetchNotifications = async (showSuccessNotification = false) => {
    setLoading(true);
    try {
      const res = await gpClient.get(`/google-gp-v1/DSNotifications`);
      setNotifications(res?.data);
      closeDrawer();
      if (showSuccessNotification) {
        successfulNotification(
          "Delayed status notifications reloaded",
          "The delayed status notifications has been reloaded"
        );
      }
    } catch (error) {
      failedNotification("Error fetching notifications", error?.toString());
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleNotification = async (values: any) => {
    setSubmitting(true);
    try {
      if (!currentId) {
        await gpClient.post("/google-gp-v1/DSNotifications", values);
      } else {
        await gpClient.patch(
          `/google-gp-v1/DSNotifications/${currentId}`,
          values
        );
      }
      closeDrawer();
      fetchNotifications();
      successfulNotification(
        `Delayed status notifications is ${currentId ? "updated" : "added"}`,
        "Please refresh your page if the update is not visible."
      );
    } catch (error) {
      failedNotification("Error updating notifications", error?.toString());
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="manage-page manage-delayed-status-notification-page">
      <Helmet>
        <title>Manage Delayed Status Notifications - Mercury © RWS</title>
      </Helmet>
      <PageTitle title="Manage Delayed Status Notifications" />
      <DSNotificationForm
        form={form}
        isOpen={isOpen}
        openDrawer={openDrawer}
        closeDrawer={closeDrawer}
        type={currentId ? "Edit" : "Add"}
        onSubmit={(values) => handleNotification(values)}
        submitting={submitting}
      />
      <Button
        icon={<ReloadOutlined />}
        onClick={() => fetchNotifications(true)}
        loading={loading}
      >
        Refresh
      </Button>
      <DSNotificationsTable
        data={notifications}
        loading={loading}
        editDrawer={(values: any) => openDrawer(values)}
        fetchNotifications={fetchNotifications}
      />
    </div>
  );
};

export default ManageDSNotifications;
