import React, { useState, useEffect } from "react";
import { Dropdown, Avatar, Typography, Button, Badge, Collapse } from "antd";
import { BellOutlined } from "@ant-design/icons";
import { useLocation, Link } from "react-router-dom";
import { useMercuryContext } from "../user-context";
import SetTimezone from "../components/headerComponents/SetTimezone";
import { LogoutButton as Logout } from "../Components";
import Loading from "../ui/Loading";
import { matchAdminItem } from "../utils/helpers";
import "./profile.scss";
import SesameAccountDialog from "../stories/SesameAccount/SesameAccountDialog";
import { failedNotification, successfulNotification } from "../utils/notifications";
import { usePermissions } from "../hooks/usePermissions";

interface NotificationsProps {
  handleMainMenuSelection: (key: string | null) => void;
}

const Notifications: React.FC<NotificationsProps> = ({
  handleMainMenuSelection,
}) => {
  const { userDetails } = useMercuryContext();
  if (userDetails.notifications.length === 0) {
    return null;
  }

  return (
    <Button
      type="text"
      icon={<BellOutlined />}
      className="profile-menu-item profile-menu-item__notifications"
    >
      <Link to="/notifications" onClick={() => handleMainMenuSelection(null)}>
        Notifications ({userDetails.notifications.length})
      </Link>
    </Button>
  );
};

const UserData: React.FC = () => {
  const { userDetails } = useMercuryContext();
  const { Text } = Typography;

  return (
    <div
      className="top-wrap"
      style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
    >
      {userDetails.picture ? (
        <Avatar size={32} src={userDetails.picture} />
      ) : (
        <Avatar
          size={32}
          style={{
            backgroundColor: stringToColour(userDetails.name),
            fontSize: 14,
          }}
        >
          {userDetails.firstName[0]}
          {userDetails.lastName[0]}
        </Avatar>
      )}
      <div
        className="details-wrap"
        style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}
      >
        <Text strong>{userDetails.name}</Text>
        <Text>{userDetails.email}</Text>
      </div>
    </div>
  );
};

function stringToColour(str: string): string {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
}

interface SettingsProps {
  isMainOpen: boolean;
}

const Settings: React.FC<SettingsProps> = ({ isMainOpen }) => {
  const [activePanel, setActivePanel] = useState<string | undefined>();

  const handlePanelChange = (key: string | string[]) => {
    if (typeof key === "string") {
      setActivePanel(key);
    } else if (Array.isArray(key) && key.length > 0) {
      setActivePanel(key[0]);
    } else {
      setActivePanel(undefined);
    }
  };

  useEffect(() => {
    if (!isMainOpen) {
      setActivePanel(undefined);
    }
  }, [isMainOpen]);

  const items = [
    {
      key: "1",
      label: "Settings",
      children: <SetTimezone />,
    },
  ];

  return (
    <Collapse
      ghost
      className="profile-menu-item profile-menu-item__settings"
      activeKey={activePanel}
      onChange={handlePanelChange}
      items={items}
    />
  );
};

interface AdminAreaProps {
  adminData: {
    adminMenuItems: Array<{
      id: string;
      title: string;
      children: React.ReactNode;
    }>;
    openAdminMenu: (children: React.ReactNode) => void;
  };
  setIsMainOpen: any;
  handleMainMenuSelection: (key: string | null) => void;
}

const AdminArea: React.FC<AdminAreaProps> = ({
  adminData,
  setIsMainOpen,
  handleMainMenuSelection,
}) => {
  const location = useLocation();
  const currentLocation = location.pathname;
  const matchAdminMenuItem = matchAdminItem(adminData.adminMenuItems, currentLocation);


function filterInactiveMenuItems(menu: any[]): any[] {
  return menu
    .map(parent => {
      const filteredChildren = parent.children?.map((child: any) => {
        if (child.submenu) {
          child.submenu = child.submenu.filter((sub: any) => sub.active);
          return child.submenu.length > 0 ? child : null;
        }
        return child.active ? child : null;
      }).filter(Boolean);

      return filteredChildren.length > 0 ? { ...parent, children: filteredChildren } : null;
    })
    .filter(Boolean);
}

// remove all menu items that don't have at least 1 child
const adminMenu = filterInactiveMenuItems(adminData.adminMenuItems)

  const items = [
    {
      key: "admin-area",
      label: "Admin Area",
      children: adminMenu.map((item) => {
        const isMatching = matchAdminMenuItem?.[0]?.parentId === item.id;
        return (
          <button
            key={item.id}
            onClick={() => {
              setIsMainOpen(false);
              handleMainMenuSelection(null);
              adminData.openAdminMenu(item.children);
            }}
            className={`admin-link ${isMatching ? "admin-link-active" : ""}`}
          >
            {item.title}
          </button>
        );
      }),
    },
  ];

  return <Collapse items={adminMenu.length > 0 ? items : []} className="menu admin-menu" />;
};

interface SesameAreaProps {
  isMainOpen: boolean;
  setIsMainOpen?: any
}

const SesameArea: React.FC<SesameAreaProps> = ({ isMainOpen, setIsMainOpen }) => {
  const location = useLocation();
  const { userDetails, gpClient } = useMercuryContext();
  const [activePanel, setActivePanel] = useState<string | undefined>();
  const { hasPermissions } = usePermissions();
  const isActive = /\/sesame(\/\d+)?$/.test(location.pathname)
    ? "admin-link-active"
    : "";

  const handleSubmit = async (data: any, callback: any) => {
    try {
      await gpClient.post(`/google-gp-v1/sesameAccounts`, data);
      callback();
      successfulNotification(
        "Success", "New Account details added successfully to Sesame Tracker"
      );
    } catch (error: any) {
      callback();
      failedNotification("Error", "Failed to add new account. Please try again");
    }
  };

  useEffect(() => {
    if (!isMainOpen) {
      setActivePanel(undefined);
    }
  }, [isMainOpen]);

  const hasSesameAdminCreateAccess = hasPermissions([{ customerApplication: "Google", permissionName: "Sesame Admin", action: "create" }]);
  
  const items = [
    {
      key: "1",
      label: "Sesame Area",
      children: (
        <>
          <Button type="text" className={`admin-link ${isActive}`} >
            <Link to="/sesame" onClick={() => setIsMainOpen(false)}>Sesame Account</Link>
          </Button>
          { hasSesameAdminCreateAccess && <SesameAccountDialog
              onSubmit={(data, callback) => handleSubmit(data, callback)}
              requesterEmail={userDetails?.email}
              isMenuItem
              buttonLabel="Make a new Request"
              setIsMainOpen={setIsMainOpen}
            />}
        </>
      ),
    },
  ];

  return (
    <Collapse
      ghost
      className="profile-menu-item profile-menu-item__settings"
      items={items}
      activeKey={activePanel}
    />
  );
};

interface ProfileProps {
  adminData: any;
  handleMainMenuSelection: (key: any) => void;
}

const Profile: React.FC<ProfileProps> = ({
  adminData,
  handleMainMenuSelection,
}) => {
  const { isAuthLoading, userDetails, flags } = useMercuryContext();
  const [isMainOpen, setIsMainOpen] = useState(false);

  if (isAuthLoading) {
    return <Loading />;
  }

  const toggleMainMenu = (open:boolean, info:any) => {
    if (info?.source === 'trigger') {
      setIsMainOpen(open);
    }
  };

  const items = [
    { label: <UserData />, key: "0" },
    {
      label: (
        <Notifications handleMainMenuSelection={handleMainMenuSelection} />
      ),
      key: "1",
    },
    {
      label: (
        <AdminArea
          adminData={adminData}
          setIsMainOpen={setIsMainOpen}
          handleMainMenuSelection={handleMainMenuSelection}
        />
      ),
      key: "3",
    },
    { label: <Settings isMainOpen={isMainOpen} />, key: "4" },
    flags?.sesameAccount && {
      label: <SesameArea isMainOpen={isMainOpen} setIsMainOpen={setIsMainOpen}/>,
      key: "5",
    },
    { label: <Logout />, key: "6" },
  ].filter(Boolean);

  return (
    userDetails.email && (
      <Dropdown
        menu={{ items }}
        trigger={["click"]}
        placement="bottomRight"
        onOpenChange={toggleMainMenu}
        open={isMainOpen}
        overlayClassName="profile-menu"
      >
        <button
          className="ant-dropdown-link profile-wrap"
          onClick={(e) => e.preventDefault()}
          style={{ padding: "0 10px" }}
        >
          <Badge
            count={userDetails.notifications.length}
            style={{ backgroundColor: "var(--tertiary-color)" }}
            size="small"
          >
            {userDetails.picture ? (
              <Avatar
                size={32}
                src={userDetails.picture}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <Avatar
                size={32}
                style={{
                  cursor: "pointer",
                  backgroundColor: stringToColour(userDetails.name),
                  fontSize: 14,
                }}
              >
                {userDetails.firstName[0]}
                {userDetails.lastName[0]}
              </Avatar>
            )}
          </Badge>
        </button>
      </Dropdown>
    )
  );
};

export default Profile;
