import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useMercuryContext } from "../user-context";

interface ProtectedRouteProps {
  children: ReactNode;
  permissions: {
    customerApplication: string;
    permissionName: string;
    action: "create" | "edit" | "delete" | "read";
  }[];
  redirectPath?: string;
}

interface IPermission {
  read: boolean;
  create: boolean;
  delete: boolean;
  edit: boolean;
  customerApplication: string;
  name: string;
  permissionId: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  permissions,
  redirectPath,
}) => {
  const { userDetails } = useMercuryContext();
  const userPermissions: IPermission[] = userDetails?.permissions || [];

  const rdPath = redirectPath ? `/${redirectPath}` : "/unauthorized";

  const hasPermission = permissions.some((requiredPermission) =>
    userPermissions.some(
      (perm) =>
        perm.name === requiredPermission.permissionName &&
        perm[requiredPermission.action] === true &&
        perm.customerApplication === requiredPermission.customerApplication
    )
  );

  if (!hasPermission) {
    return <Navigate to={rdPath} replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
