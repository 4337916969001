import { Table, Tooltip, Spin } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import TimezoneConverter from "../../../timezone-converter";

const RequestedLanguages = ({ plpDetails, loading }: any) => {
  
  const languagesDetails = plpDetails?.map((el: any, id: number) => {
    return {
      key: id,
      ...el,
    };
  });

  return (
    <>
      {loading ? (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Spin size="small" style={{ margin: "0 10px" }} />
          Loading...
        </div>
      ) : (
        <div style={{ margin: "0 10px", border: "1px solid #d9d9d9" }}>
          <Table
            dataSource={languagesDetails}
            columns={[
              {
                title: <span>&nbsp;</span>,
                dataIndex: "warning",
                align: "right",
                render: (_text, record) => (
                  record?.warning && (
                    <Tooltip title={record.warning} placement="right" color={"var(--red)"}>
                      <InfoCircleOutlined
                        style={{ color: "var(--red)" }}
                      />
                    </Tooltip>
                  )
                ),
              },
              {
                title: "Target Language Code",
                dataIndex: "targetLanguageCode",
                align: "left",
                render: (text, record) => (
                  <span style={{ color: record?.warning && !record.exists ? "var(--red)" : "inherit" }}>{text}</span>
                ),
              },
              {
                title: "Estimated Delivery Date",
                dataIndex: "estimatedDeliveryDate",
                align: "left",
                render: (text) => {
                  return <TimezoneConverter date={text} />;
                },
              },
              {
                title: "Google Requested Date",
                dataIndex: "googleRequestedDate",
                align: "left",
                render: (text) => {
                  return <TimezoneConverter date={text} />;
                },
              },
              {
                title: "Rws Delivery Date",
                dataIndex: "rwsDeliveryDate",
                align: "left",
                render: (text) => {
                  return <TimezoneConverter date={text} />;
                },
              },
              {
                title: "Polyglot Status",
                dataIndex: "polyglotStatus",
                align: "left",
                render: (text) => text || <i>Not Set</i>,
              },
              {
                title: "Month Completed",
                dataIndex: "monthCompleted",
                align: "left",
                render: (text: string) => {
                  if (!text) {
                    return <i className={"muted"}>Not set</i>
                  }
                  try {
                    new Date(text);
                    return <TimezoneConverter date={text} format={"MM-YYYY"} />
                  }
                  catch (e) {
                    return <i className={"muted"}>Not set</i>
                  }
                },
              },
              {
                title: "Invoice Month",
                dataIndex: "invoiceMonth",
                align: "left",
                render: (text: string) => {
                  if (!text) {
                    return <i className={"muted"}>Not set</i>
                  }
                  try {
                    new Date(text);
                    return <TimezoneConverter date={text} format={"MM-YYYY"} />
                  }
                  catch (e) {
                    return <i className={"muted"}>Not set</i>
                  }
                },
              },
              {
                title: "Invoice Number",
                dataIndex: "invoiceNumber",
                align: "left",
                render: (text) => text || <i>Not Set</i>,
              },
              {
                title: "Total Cost",
                dataIndex: "totalCost",
                align: "left",
                render: (text) => text || <i>Not Set</i>,
              },
              {
                title: "VAR Status",
                dataIndex: "varStatus",
                align: "left",
                render: (text) => text || <i>Not Set</i>,
              },
              {
                title: "OTD",
                dataIndex: "otd",
                align: "left",
                render: (text) => (text ? text : <i>Not Set</i>),
              },
              {
                title: "Extension Applied",
                dataIndex: "extensionApplied",
                align: "left",
                render: (text) => (text ? text : <i>Not Set</i>),
              },
            ]}
            pagination={false}
          />
        </div>)}
    </>
  );
};

export default RequestedLanguages;