import { FiniteListWithAsyncLoaderAbstract, ListProps } from "../../Components";
import { useMercuryContext } from "../../user-context";

export const fetchProjectTypes = async (context: any) => {

  const { data } = await context.gpClient.get(
    "/google-gp-v1/manualProjects/listFields/testingProjectType" 
  );
  return data.map((item: any) => ({
    title: item.label,
    value: item.value,
  }));
};

class TestingProjectType extends FiniteListWithAsyncLoaderAbstract {
  fetch() {
    return fetchProjectTypes(this.props.context);
  }
}

export default (props: ListProps) => {
  const context = useMercuryContext();
  return <TestingProjectType context={context} {...props} />;
};
