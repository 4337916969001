import React, { FunctionComponent } from "react";
import "./customTooltip.scss";

interface TooltipProps {
  text: string;
  length?: number; // Minimum string length to display the tooltip (default: 20)
  children?: React.ReactNode;
}

export const CustomTooltip: FunctionComponent<TooltipProps> = ({
  text = "",
  length = 20,
  children,
}) => {
  return (
    <div className="custom-tooltip" title={text?.length >= length ? text : ""}>
      {children || text}
    </div>
  );
};
