import {Helmet} from "react-helmet";
import logo from "../assets/images/RWS_logo-colour.svg";
import {Tooltip} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import React, {Component} from "react";

import RWSId from "./AuthVendors/RWS-id";
import Moravia from "./AuthVendors/Moravia";
import RWSWorldWide from "./AuthVendors/RWSWorldWide"
import RWSOne from "./AuthVendors/RWSOneEntraID";
import {useMercuryContext, UserContext} from "./index";
import {IMsalContext} from "@azure/msal-react/dist/MsalContext";
import {Auth0ContextInterface} from "@auth0/auth0-react/src/auth0-context";
import Cookies from "js-cookie";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {useAuth0} from "@auth0/auth0-react";

import "./LoginPage.scss"
import {Loader} from "../Components";
import {InteractionStatus} from "@azure/msal-browser";
// import {Simulate} from "react-dom/test-utils";
// import progress = Simulate.progress;

interface loginProps {
    context: UserContext;
    MSAL: IMsalContext;
    auth0: Auth0ContextInterface;
    isAuthenticated:boolean;
}

export enum vendors {
    RWSID= "RWSID",
    MORAVIA= "fMoravia",
    WORLDWIDE="worldwide",
    RWSONE="rwsOne"
}

interface loginState {
    currentAuth: vendors;
    loading: boolean;
}

class LoginPage extends Component<loginProps, loginState> {

    state = {
        currentAuth: Cookies.get("auth_method") as vendors,
        loading: true
    }

    RWSId:RWSId;
    MORAVIA:Moravia;
    Worldwde:RWSWorldWide;
    RWSOne: RWSOne;

    constructor( props:loginProps) {
        super( props )
        this.RWSId = new RWSId(props.auth0, props.context);
        this.MORAVIA = new Moravia( props.MSAL, props.context)
        this.Worldwde = new RWSWorldWide( props.MSAL, props.context );
        this.RWSOne = new RWSOne( props.MSAL, props.context );


        [
            this.RWSId,
            this.MORAVIA,
            this.Worldwde,
            this.RWSOne
        ].forEach( ( provider ) => {
            provider.on( "token", ( token ) => {
                props.context.setToken( token );
            })

            provider.on( "loggingOut", () => {
                this.setState( { loading: true } );
            })

            provider.on( "loggedOut", () => {
                this.setState({ loading: false } );
            } )

            provider.on( "loggingIn", () => {
                this.setState({ loading: true } );
            })
        })

        this.MORAVIA.on( "errored", async ( e ) => {
            await new Promise((accept) => {
                setTimeout( () => {
                    this.login(vendors.MORAVIA)
                    accept(null)
                }, 2000 )
            });
        })

    }

    async componentDidMount() {
        if ( this.state.currentAuth && !Cookies.get( "logged_out") ) {
            await this.login( this.state.currentAuth );
        }
        else
        if ( this.props.context.loginRequired ){
            this.setState({ loading: false })
        }
     }

    async login(id:vendors) {
        this.setState({currentAuth: id}, () => {
            Cookies.set( "auth_method", id );
        })

        this.props.context.switchProvider(id);
        switch( id ) {
            case vendors.RWSID:
                await this.RWSId.login()
                break;
            case vendors.WORLDWIDE:
                await this.Worldwde.login();
                break;
            case vendors.MORAVIA:
                await this.MORAVIA.login();
                break;
            case vendors.RWSONE:
                await this.RWSOne.login();
                break;
        }
    }


    render() {

        const
            { currentAuth, loading } = this.state,
            { flags } = this.props.context;

        if ( loading ) {
            return <Loader animate={true} logo={true} title={"Authenticating..."}/>
        }

        return (
            <div className="login-page">
                <Helmet>
                    <title>Login - Mercury © RWS</title>
                </Helmet>
                <div className="container">
                    <div className="logo">
                        <img src={logo} alt="logo" />
                        <h1>Mercury</h1>
                    </div>
                    <div className="intro">
                        <h1>Hello, Bonjour, Hallå!</h1>
                        <h3>Please select how you would like to login</h3>
                    </div>
                    <div className="cards">
                        {flags.rwsOneAuthenticationMethod && (
                            <div
                                className={`card card--rwsone ${
                                    currentAuth === vendors.RWSONE ? "card--active" : ""
                                }`}
                                onClick={() => this.login(vendors.RWSONE)}
                            >
                                <span>RWS One</span>
                            </div>
                        )}
                        {flags.rwsIdLogin && (
                            <div
                                className={`card card--rws ${
                                    currentAuth === vendors.RWSID ? "card--active" : ""
                                }`}
                                onClick={() => this.login(vendors.RWSID)}
                            >
                                <span>RWS ID</span>
                            </div>
                        )}
                        {flags.azureAdLogin && (
                            <div
                                className={`card card--moravia ${
                                    currentAuth === vendors.MORAVIA ? "card--active" : ""
                                }`}
                                onClick={() => this.login(vendors.MORAVIA)}
                            >
                                <span>fMoravia Azure AD</span>
                            </div>
                        )}
                        {flags.worldwideLogin && (
                            <div
                                className={`card card--worldwide ${
                                    currentAuth === vendors.WORLDWIDE ? "card--active" : ""
                                }`}
                                onClick={() => this.login(vendors.WORLDWIDE)}
                            >
                                <span>RWS Worldwide</span>
                            </div>
                        )}
                    </div>
                </div>
                <Tooltip
                    title={
                        <div>
                            <p style={{ marginBottom: "3px" }}>If you encounter any issues:</p>
                            <p style={{ marginBottom: "unset" }}>Press Ctrl + Shift + Delete</p>
                            <p style={{ marginBottom: "unset" }}>Select time range: All time</p>
                            <p style={{ marginBottom: "unset" }}>
                                Check 'Cookies and other site data'
                            </p>
                            <p style={{ marginBottom: "unset" }}>Press Clear data. Thank you</p>
                        </div>
                    }
                    placement={"left"}
                >
                    <InfoCircleOutlined className="info" />
                </Tooltip>
            </div>
        )
    }
}


const Login = function () {

    const
        context = useMercuryContext(),
        MSAL = useMsal(),
        auth0 = useAuth0(),
        isAzureAuthenticated = useIsAuthenticated(),
        {
            inProgress
        } = MSAL;


    if ( inProgress !== InteractionStatus.None ) {
        return <Loader animate={true} logo={true} title={"Processing..."}/>
    }

    return <LoginPage context={context} MSAL={MSAL} auth0={auth0} isAuthenticated={isAzureAuthenticated} />
}

export default Login;
