import { Input, Modal, Row, Table, Typography } from "antd";
import Total from "../../../ui/Total";
import { sorter } from "../../../utils/helpers";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import {
  failedNotification,
  successfulNotification,
} from "../../../utils/notifications";
import { useMercuryContext } from "../../../user-context";
import { usePermissions } from "../../../hooks/usePermissions";

const { Search } = Input;
const { Title, Link } = Typography;

interface DSNotificationsTableProps {
  data: any[];
  editDrawer: (record: any) => void;
  loading: boolean;
  fetchNotifications: () => void;
}

const DSNotificationsTable: React.FC<DSNotificationsTableProps> = ({
  data,
  editDrawer,
  loading,
  fetchNotifications,
}) => {
  const { gpClient } = useMercuryContext();
  const { hasPermissions } = usePermissions();
  const [filteredNotifications, setFilteredNotifications] = useState<any[]>([]);
  const [sortedInfo, setSortedInfo] = useState<any>(null);
  const [searchText, setSearchText] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rowToDelete, setRowToDelete] = useState<any>(null);
  const [isDeleting, setIsDeleting] = useState<any>(null);
  const canEditNotifications = hasPermissions([
    {
      customerApplication: "Google",
      permissionName: "Delayed Status Notification",
      action: "edit",
    },
  ]);
  const canDeleteNotifications = hasPermissions([
    {
      customerApplication: "Google",
      permissionName: "Delayed Status Notification",
      action: "delete",
    },
  ]);

  useEffect(() => {
    handleSearch();
  }, [searchText, data]);

  const columns = [
    {
      title: "Workflow Status",
      dataIndex: "status",
      sorter: (a: any, b: any) => sorter(a.status, b.status),
      sortOrder: sortedInfo?.field === "status" && sortedInfo?.order,
    },
    {
      title: "Delay Threshold",
      dataIndex: "threshold",
      sorter: (a: any, b: any) => sorter(a.threshold, b.threshold),
      sortOrder: sortedInfo?.field === "threshold" && sortedInfo?.order,
    },
    {
      title: "Notification Email",
      dataIndex: "email",
      sorter: (a: any, b: any) => sorter(a.email, b.email),
      sortOrder: sortedInfo?.field === "email" && sortedInfo?.order,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      width: 150,
      render: (_text: any, row: any) => {
        const actions = [];

        actions.push(
          <Link
            onClick={() => editDrawer(row)}
            style={{ marginRight: "10px" }}
            disabled={!canEditNotifications}
          >
            <EditOutlined /> Edit
          </Link>
        );

        actions.push(
          <Link
            onClick={() => showDeleteModal(row)}
            style={{ color: canDeleteNotifications ? "red" : "" }}
            disabled={!canDeleteNotifications}
          >
            <DeleteOutlined /> Delete
          </Link>
        );

        return (
          <>
            {actions.map((action, index) => (
              <span key={index}>{action}</span>
            ))}
          </>
        );
      },
    },
  ];

  const handleChangeTable = (pagination: any, filters: any, sorter: any) => {
    setSortedInfo(sorter);
  };

  const handleSearch = () => {
    const filterTable = data.filter((item: any) =>
      item?.status?.toString().toLowerCase().includes(searchText?.toLowerCase())
    );

    setFilteredNotifications(filterTable);
  };

  const showDeleteModal = (row: any) => {
    setIsModalVisible(true);
    setRowToDelete(row);
  };

  const handleDelete = async () => {
    setIsDeleting(true);

    try {
      await gpClient.delete(`/google-gp-v1/DSNotifications/${rowToDelete.id}`);

      successfulNotification("Success", "Notification deleted successfully!");
      setIsModalVisible(false);
      setRowToDelete(null);
      fetchNotifications();
    } catch (error: any) {
      failedNotification(
        "Failed to delete the item. Please try again.",
        error?.message
      );
    } finally {
      setIsDeleting(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setRowToDelete(null);
  };

  return (
    <>
      <Search
        style={{ width: "300px", margin: "0 0 10px 0" }}
        placeholder="Search by title"
        onSearch={(value) => setSearchText(value)}
        allowClear
        enterButton
      />
      <Row className="title-wrap">
        <Title level={5} style={{ margin: "0" }}>
          Delayed Status Notifications List
        </Title>
        <Total number={data?.length} text="Notifications" />
      </Row>

      <Table
        loading={loading}
        columns={columns}
        dataSource={
          !filteredNotifications?.length ? data : filteredNotifications
        }
        size="small"
        className="manage-page-table"
        style={{ width: "100%" }}
        onChange={handleChangeTable}
        pagination={{
          position: ["bottomCenter"],
          defaultPageSize: 20,
        }}
      />

      <Modal
        title="Confirm Delete"
        open={isModalVisible}
        onOk={handleDelete}
        onCancel={handleCancel}
        okText="Delete"
        okButtonProps={{ danger: true }}
        confirmLoading={isDeleting}
      >
        Are you sure you want to delete this notification?
      </Modal>
    </>
  );
};

export default DSNotificationsTable;
