import React from "react";
import {
    DateFilter,
    ListFilter,
    BooleanFilter,
    TextFilter,
    CustomTooltip
} from "../../Components";
import {useMercuryContext} from "../../user-context";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {Tooltip} from "antd"
import {BlockOutlined, CheckOutlined, CloseOutlined} from "@ant-design/icons";
import SharingPercentage from "../../ui/SharingPercentage";
import TimezoneConverter from "../../timezone-converter";
import ProductFilter from "../Product.filter";
import UrgencyFilter from "../Urgency.filter";
import ProductAreaFilter from "../ProductArea.filter";
import ProjectTypeFilter from "../ProjectType.filter";
import ComponentTypeFilter from "../ComponentType.filter";
import StatusFilter from "../Status.filter";
import WorkflowTypeFilter from "../WorkflowType.filter";
import OwnerFilter from "../Owner.filter";
import DueDateTierFilter from "../DueDateTier.filter";
import NumberFilter from "../MongoNumber.filter";
import {AlignType} from "rc-table/lib/interface";
import OwnerAssign from "../Owner.assign";
import UserBadge from "../../stories/UserIdentity/UserBadge";
import EmpowerCCFilter from "../EmpowerCC.filter";
import {Link, Route, Routes, useLocation} from "react-router-dom";
import DetailsPage from "./ProjectDetails/ProjectDetails";
import ProjectShare from "../Project.share";
import BatchEditing from "../Batch.editing";
import BatchHours from "../Batch.hours";
import {DateTime} from "luxon";
import SalesOrderFilter from "../SalesOrder.filter";
import CurrencyFormat from "react-currency-format";
import PolyglotLogo from '../../stories/assets/polyglot-logo.svg'
import VarStatusFilter from "../VarStatus.filter";
import NotFoundPage from "../../routes/NotFoundPage";
import TableWSAbstract from "../../stories/Table/TableWS.abstract";
import {TableProps} from "antd/lib/table";
import {TableState} from "../../stories/Table/TableBase";
import uuid from "uuid-browser";
import GGLResyncButton from "./GGLResyncButton";
import { creationTimestampColumn, 
    expeditedColumn, 
    invoiceMonthColumn, 
    lastImportedTimestampColumn, 
    lastUpdatedTimestampColumn, 
    onHoldColumn, 
    poTypeColumn, 
    PODateColumn 
    } from "../columnsConfig";
import { lastImportedTimestampFilter,
    lastUpdatedTimestampFilter,
    sharingStatusFilter,
    creationTimestampFilter,
    poTypeFilter,
    expeditedFilter,
    onHoldFilter,
    etaFromLxDFilter
    } from "../filtersConfig";
import {InvoicingBulkAction} from "../InvoicingBulk.action";

import "./Projects.scss"
import ProjectApprovalStatusFilter from "../ProjectApprovalStatus.filter";
import { checkPermissions } from "../../Utilities/checkPermissions";

function TableCell( props: any ) {

    const
        rowData = props.rowData,
        text = rowData[props.column.dataIndex],
        record = rowData;

    if ( props.render ) {
        return props.render(text, record);
    }

    return text;
}

class ProjectSearch extends TableWSAbstract<any> {

    pageTitle = "Projects - Mercury";
    title = "Projects";
    pageClass = "projectTable"
    defaultSortField = "importedTimestamp";
    FilterComponents = [
        {
            id: "projectId",
            name: "shortId",
            title: "Project ID",
            component: ListFilter,
            active: true
        },
        {
            id: "helixId",
            name: "helixId",
            title: "Helix ID",
            component: ListFilter,
            active: true
        },
        {
            id: "receivedDate",
            name: "importedTimestamp",
            title: "Received Date",
            component: DateFilter,
            active: true
        },
        {
            id: "product",
            name: "products",
            title: "Product",
            component: ProductFilter,
            active: true
        },
        {
            id: "urgency",
            name: "turnaroundTimeUrgency",
            title: "Urgency",
            component: UrgencyFilter,
            active: true
        },
        {
            id: "productArea",
            name: "productArea",
            title: "Product Area",
            component: ProductAreaFilter,
            active: true
        },
        {
            id: "projectType",
            name: "projectType",
            title: "Project Type",
            component: ProjectTypeFilter,
            active: false
        },
        {
            id: "componentType",
            name: "componentType",
            title: "Component Type",
            component: ComponentTypeFilter,
            active: false
        },
        {
            id: "status",
            name: "projectStatus",
            title: "Status",
            component: StatusFilter,
            active: true
        },
        sharingStatusFilter,
        {
            id: "stt",
            name: "stt",
            title: "STT",
            component: BooleanFilter
        },
        {
            id: "workflowType",
            name: "workflowType",
            title: "Workflow Type",
            component: WorkflowTypeFilter
        },
        poTypeFilter,
        expeditedFilter,
        {
            id:"extension",
            name:"noExtension",
            title: "No extension",
            component: BooleanFilter
        },
        {
            id:"launched",
            name:"launched",
            title: "Launched",
            component: BooleanFilter
        },
        onHoldFilter,
        {
            id:"poIssued",
            name:"POIssued",
            title: "PO Issued",
            component: BooleanFilter
        },
        {
            id: "empowerCC",
            name: "costCode",
            title: "Empower CC",
            component: EmpowerCCFilter
        },
        {
            id: "owner",
            name: "currentOwnerId",
            title: "Owner",
            component: OwnerFilter
        },
        {
            id: "invoiceName",
            name: "invoiceName",
            title: "Invoice name",
            component: TextFilter
        },
        {
            id: "salesOrder",
            name: "salesOrder",
            title: "Sales order",
            component: SalesOrderFilter
        },
        {
            id: "lastDueDate",
            name: "lastDueDate",
            title: "Latest Due date",
            component: DateFilter
        },
        {
            id: "dueDate",
            name: "dueDate",
            title: "Earliest Due date",
            component: DateFilter
        },
        {
            id: "poNumber",
            name: "PONumber",
            title: "PO Number",
            component: ListFilter,
            active: true
        },
        {
            id: "languageCount",
            name: "languageCount",
            title: "Language Count",
            component: NumberFilter,
            active: false
        },
        {
            id: "dueDateTier",
            title: "Due Date Tier",
            name: "dueDateTier",
            component: DueDateTierFilter,
            active: false
        },
        {
            id: "PODate",
            title: "PO Date",
            name: "PODate",
            component: DateFilter,
            active: false
        },
        {
            id: "sourceLanguageCode",
            title: "Source Language",
            name: "sourceLanguageCode",
            component: ListFilter,
            active: false
        },
        {
            id: "invoiceMonth",
            title: "Invoice Month",
            name: "invoiceMonth",
            component: DateFilter,
            active: false
        },
        {
            id: "varStatus",
            name: "varStatus",
            title: "Invoice status",
            component: VarStatusFilter,
            active: false
        },
        creationTimestampFilter,
        lastImportedTimestampFilter,
        lastUpdatedTimestampFilter,
        {
            id: "projectApprovalStatus",
            name: "projectApprovalStatus",
            title: "Project Approval Status",
            component: ProjectApprovalStatusFilter
        },
        etaFromLxDFilter
    ];
    CellRenderer= TableCell;
    columns = [
        {
            id: "projectId",
            title: "Project ID",
            dataIndex: "shortId",
            sorter: true,
            width: 140,
            fixed: undefined,
            render: (id: string, record: any) => {
                return (
                    <>
                        <a
                            href={`/project/${id}/?type=projectId`}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="View project details in a new tab"
                        >
                            <BlockOutlined style={{marginRight:'3px'}}/>
                        </a>
                        <Link
                            to={`/project/${id}?type=projectId`}
                            id={id}
                            title="View project details"
                        >
                            {id}
                        </Link>
                        { record.projectType !== 'WMT' && <a
                            href={`https://localization.google.com/polyglot?project_id=${id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="View project in Polyglot"
                            style={{userSelect: 'none'}}
                        >
                            <img src={PolyglotLogo} alt="polyglot" style={{marginLeft: '3px', width: 'auto', height: '20px'}}/>
                        </a> }
                    </>
                )
            }
        },
        {
            id: "helixId",
            title: "Helix ID",
            dataIndex: "helixId",
            sorter: true,
            fixed: undefined,
            width: 130,
            render: (id: string, record: any) =>
                id ? (
                        <a
                            href={`https://sdl.appiancloud.com/suite/sites/jobTracking`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <BlockOutlined className="project-link-icon"/> {id}
                        </a>
                ) : <i className={"muted"}>Not set</i>
        },
        // {
        //     id: "shortId",
        //     title: "Short ID",
        //     dataIndex: "shortId",
        //     sorter: true,
        //     width: 130,
        //     render: (id: string, record: any) =>
        //         record.catToolProjectId !== null ? (
        //             <Link
        //                 to={`project/${id}?type=shortId`}
        //                 id={id}
        //                 className={"project-link"}
        //             >
        //                 {id}
        //             </Link>
        //         ) : (
        //             <>
        //                 <a
        //                     href={`/project/${id}/?type=shortId`}
        //                     target="_blank"
        //                     rel="noopener noreferrer"
        //                 >
        //                     <BlockOutlined className="project-link-icon"/>
        //                 </a>
        //                 <Link
        //                     to={`project/${id}?type=shortId`}
        //                     id={id}
        //                     className={"project-link"}
        //                 >
        //                     {id}
        //                 </Link>
        //             </>
        //         ),
        // },
        {
            id: "title",
            title: "Title",
            dataIndex: "title",
            sorter: true,
            width: 400,
            fixed: undefined,
            render: (text: string) => <CustomTooltip text={text} length={57}/>,
        },
        {
            id: "product",
            title: "Product",
            dataIndex: "product",
            sorter: true,
            width: 200,
            fixed: undefined,
            render: (text: string) => <CustomTooltip text={text} length={25}/>,
        },
        {
            id: "status",
            title: "Status",
            dataIndex: "projectStatus",
            sorter: true,
            width: 200,
            fixed: undefined,
        },
        {
            id: "component",
            title: "Component Type",
            dataIndex: "componentType",
            sorter: true,
            width: 145,
            fixed: undefined,
        },
        {
            id: "urgency",
            title: "Urgency",
            dataIndex: "turnaroundTimeUrgency",
            sorter: true,
            width: 100,
            fixed: undefined,
        },
        {
            id: "projectTypes",
            title: "Project Type",
            dataIndex: "projectType",
            sorter: true,
            width: 110,
            fixed: undefined,
        },
        {
            id: "sharing",
            title: "Sharing",
            dataIndex: "sharingStatus",
            sorter: true,
            width: 90,
            align: "center" as any,
            render: (text: string) => <SharingPercentage percentage={text} date={undefined}/>,
            fixed: undefined,
        },
        {
            id: "stt",
            title: <Tooltip title="Straight to Translation" placement="left" color={"var(--tertiary-color)"}>STT</Tooltip> as any,
            dataIndex: "STT",
            sorter: true,
            width: 60,
            align: "center",
            render: (text: string) =>
                text ? (
                    <CheckOutlined style={{color: "var(--tertiary-color)"}}/>
                ) : (
                    <CloseOutlined style={{color: "var(--red)"}}/>
                ),
            fixed: undefined,
        },
        {
            id: "dueDate", // dueDate = earliest due date
            title: "Earliest Due Date",
            dataIndex: "dueDate",
            sorter: true,
            width: 150,
            render: (text: string) => <TimezoneConverter date={text}/>,
            fixed: undefined,
        },
        {
            id: "lastDueDate",
            title: "Latest Due Date",
            dataIndex: "lastDueDate",
            sorter: true,
            width: 150,
            render: (text: string) => <TimezoneConverter date={text}/>,
            fixed: undefined,
        },
        {
            id: "dueDateTier",
            title: "Due Date Tier",
            dataIndex: "highestDueDateTier",
            sorter: true,
            width: 120,
            fixed: undefined,
            render: (text: string) => <CustomTooltip text={text} length={15}/>,
        },
        {
            id: "receivedDate",
            title: "Received Date",
            dataIndex: "importedTimestamp",
            sorter: true,
            width: 150,
            render: (text: string) => <TimezoneConverter date={text}/>,
            fixed: undefined,
        },
        {
            id: "owner",
            title: "Owner",
            dataIndex: "ownerName",
            width: 260,
            align: "left" as AlignType,
            render: (text: any, record: any) => {
                const
                    user = record.ownerName;
                return user ? (
                    <UserBadge name={record.ownerName} email={record.ownerEmail} avatarSize={22} hideEmail={true} />
                )
                    : (
                        <OwnerAssign
                            options={["take", "assign"]}
                            block={true}
                            text={"Assign"}
                            selectedRows={[record]}
                            reload={this.getData}
                            bust={this.bustCache}
                            buttonSize={"small"}
                        />
                    );
            },
            sorter: true,
            fixed: undefined,
        },
        {
            id: "workflowType",
            name: "workflowType",
            title: "Workflow Type",
            dataIndex: "workflowTypeTitle",
            align: "left" as AlignType,
            width: 130,
            sorter: true,
            render: ( text:string ) => {
                return text ? <CustomTooltip text={text} length={15}/> : <i className={"muted"}>Not set</i>
            },
            fixed: undefined,
        },
        poTypeColumn,
        // {
        //     id: "dueDateTier",
        //     name: "dueDateTier",
        //     title:"Due Date Tier",
        //     dataIndex: "highestDueDateTier",
        //     align: "center" as AlignType,
        //     width: 150,
        // },
        expeditedColumn,
        {
            id: "noExtension",
            name: "noExtension",
            title:"No Extension",
            dataIndex: "noExtension",
            align: "center" as AlignType,
            sorter: true,
            width: 120,
            render: (text: string) =>
                text ? (
                    <CheckOutlined style={{color: "var(--tertiary-color)"}}/>
                ) : (
                    <CloseOutlined style={{color: "var(--red)"}}/>
                ),
            fixed: undefined,
        },
        {
            id: "launched",
            name: "launched",
            title: "Launched",
            dataIndex: "launched",
            align: "center" as AlignType,
            sorter: true,
            width: 95,
            render: (text: string) =>
                text ? (
                    <CheckOutlined style={{color: "var(--tertiary-color)"}}/>
                ) : (
                    <CloseOutlined style={{color: "var(--red)"}}/>
                ),
            fixed: undefined,
        },
        onHoldColumn,
        invoiceMonthColumn,
        {
            id: "poNumber",
            name: "poNumber",
            title: "PO Number",
            dataIndex: "PONumber",
            align: "left" as AlignType,
            width: 110,
            sorter: true,
            fixed: undefined,
            render: (text: string) => <CustomTooltip text={text} length={13}/>,
        },
        {
            id: "poIssued",
            name: "poIssued",
            title: "PO Issued",
            dataIndex: "POIssued",
            align: "center" as AlignType,
            width: 95,
            sorter: true,
            fixed: undefined,
            render: (text: string) =>
                text ? (
                    <CheckOutlined style={{color: "var(--tertiary-color)"}}/>
                ) : (
                    <CloseOutlined style={{color: "var(--red)"}}/>
                ),
        },
        PODateColumn,
        {
            id: "salesOrder",
            name: "salesOrder",
            title: "Sales Order",
            dataIndex: "salesOrder",
            align: "center" as AlignType,
            width: 105,
            sorter: true,
            render: ( text:string ) => {
                return text ? <CustomTooltip text={text} length={12}/> : <i className={"muted"}>Not set</i>
            },
            fixed: undefined,
        },
        {
            id: "invoiceName",
            name: "invoiceName",
            title: "Invoice Name",
            dataIndex: "invoiceName",
            width: 130,
            sorter: true,
            render: ( text:string ) => {
                return text ? <CustomTooltip text={text} length={15}/> : <i className={"muted"}>Not set</i>
            },
            fixed: undefined,
        },
        {
            id: "costCode",
            name: "costCode",
            title: "Empower CC",
            dataIndex: "costCode",
            align: "center" as AlignType,
            width: 130,
            sorter: true,
            fixed: undefined,
        },
        {
            id: "sourceLanguageCode",
            name: "sourceLanguageCode",
            title: <Tooltip title="Source Language" placement="left" color={"var(--tertiary-color)"}>Source La...</Tooltip> as any,
            dataIndex: "sourceLanguageCode",
            width: 105,
            sorter: true,
            fixed: undefined,
        },
        {
            id: "languageCount",
            name: "languageCount",
            title: <Tooltip title="Language Count" placement="left" color={"var(--tertiary-color)"}>Count...</Tooltip> as any,
            dataIndex: "languageCount",
            align: "center" as AlignType,
            width: 90,
            sorter: true,
            fixed: undefined
        },
        {
            id: "notes",
            name: "notes",
            title: "Project Notes",
            dataIndex: "notes",
            fixed: undefined,
            width: 170,
            // render: ( note:any ) => {
            //     if ( note ) {
            //         return (
            //             <Tooltip title={<div>
            //                 <span>{note.text}</span> -
            //                 <i style={{marginLeft:'3px'}}>{note.commenter}</i>
            //             </div>}>
            //             <div>
            //                 <span>{note.text}</span> -
            //                 <i style={{marginLeft:'3px'}}>{note.commenter}</i>
            //             </div>
            //         </Tooltip> )
            //     }
            //     return null
            // }
            render: ( note:any ) => {
                if ( note ) {
                    return (
                        <CustomTooltip text={`${note.text} - ${note.commenter}`} length={25}/> )
                }
                return null
            }
        },
        {
            id:"totalCharge",
            name: "totalVarCharge",
            title: "VAR Charge",
            dataIndex: "totalVarCharge",
            fixed: undefined,
            width: 120,
            sorter: true,
            render: ( text:string ) => !text ? <span className="currency">{"$0.00"}</span> : <CurrencyFormat value={text} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value:any) => <span className={"currency"}><CustomTooltip text={value} length={16}/></span>} />
        },
        {
            id:"totalMercuryCharge",
            name: "totalCharge",
            title: <Tooltip title="Mercury Charge" placement="left" color={"var(--tertiary-color)"}>M Charge</Tooltip> as any,
            dataIndex: "totalCharge",
            fixed: undefined,
            width: 120,
            sorter: true,
            render: ( text:string ) => !text ? <span className="currency">{"$0.000"}</span> : <CurrencyFormat value={text} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={3} fixedDecimalScale={true} />
        },
        {
            id: "varStatus",
            name: "varStatus",
            title: "Invoice status",
            dataIndex: "varStatus",
            fixed: undefined,
            width: 120,
            sorter: true
        },
        creationTimestampColumn,
        lastImportedTimestampColumn,
        lastUpdatedTimestampColumn,
        {
            id: "etaFromLxD",
            title: "ETA from LxD",
            dataIndex: "etaFromLxD",
            sorter: true,
            width: 150,
            render: (text: string) => <TimezoneConverter date={text}/>,
            fixed: undefined,
        },
        {
            id: "projectApprovalStatus",
            title: "Project Approval Status",
            dataIndex: "projectApprovalStatus",
            sorter: true,
            width: 200,
            fixed: undefined,
            render: (text: string, record: any) => record.projectType === "DTP_V2" && !text ? "Pending" : text,
        },
        // {
        //     id:"chargeDifference",
        //     name: "chargeDifference",
        //     title: "Charge difference",
        //     dataIndex: "totalChargeDifference",
        //     fixed: undefined,
        //     width: 100,
        //     render: ( text:string ) => !text ? <span className="currency">{"$0.00"}</span> : <CurrencyFormat value={text} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value:any) => <span className={"currency"}><CustomTooltip text={value} length={15}/></span>} />
        // },

    ] as any;

    renderActions() {
        const userPermissions = this.props.context?.userDetails?.permissions || [];
        const canEditProjectSharing = checkPermissions(userPermissions, [{ customerApplication: "Google", permissionName: "Project Sharing", action: "edit" }]);

        return (
          <>
            {
                canEditProjectSharing && <ProjectShare
                selectedRows={this.state.selectedRows}
                reload={this.getData}
                />
            }
            <OwnerAssign
              selectedRows={this.state.selectedRows}
              reload={this.getData}
              bust={this.bustCache}
              dropdownPlacement={'topLeft'}
            />
            <BatchEditing
              selectedRows={this.state.selectedRows}
              reload={() => this.getData()}
              bust={this.bustCache}
            />
            <BatchHours
              selectedRows={this.state.selectedRows}
              reload={this.getData}
              bust={this.bustCache}
              contentType={'project'}
            />
                {
                    this.props.context.flags.gglResyncSearch &&
                    <GGLResyncButton
                        projects={this.state.selectedRows}
                    />
                }
            { this.props.context.flags.projectBulkInvoiceButtons && <InvoicingBulkAction selectedRows={this.state.selectedRows} contentType={'project'} userContext={this.userContext} reload={() => this.getData() } /> }
          </>
        );
    }

    rowClassName = (record: any, index: number): string => {

        const validStatuses = [
            "NEW",
            "IN_PREPROCESSING",
            "PREPROCESSING_COMPLETE",
            "PENDING",
            "IN_TRANSLATION",
            "IN_PROOFREADING",
            "SIGN_OFF_WAITING",
            "IN_COPY_EDIT"
        ];

        let className = ""

        if ( validStatuses.indexOf( record.catToolStatus ) !== -1 ) {

            const
                now = DateTime.now(),
                SoD = DateTime.fromISO( record.dueDate ).startOf('day'),
                EoD = DateTime.fromISO( record.dueDate ).endOf('day');


            if ( now >= SoD && now <= EoD ) {
                className = `metaRow warning`
            }

            if ( now >= DateTime.fromISO( record.dueDate) ) {
                className = `metaRow critical`
            }

        }
        return `${className} ${this.state.changedIds?.has(record._id) ? "rowUpdated" : "" }`
    }

    defaultDisabledColumns = [
        "helixId",
        "projectTypes",
        "dueDateTier",
        "workflowType",
        "expedited",
        "noExtension",
        "launched",
        "On hold",
        "poNumber",
        "poIssued",
        "PODate",
        "salesOrder",
        "invoiceName",
        "costCode",
        "sourceLanguageCode",
        "languageCount",
        "notes",
        "totalCharge",
        "totalMercuryCharge",
    ];

    public rowMap:Map<string, any> = new Map();

    public floodPrevention = () => {
        clearTimeout(this.floodTimer);
        this.floodTimer = undefined;
        this.setState({ rowData: Array.from( this.rowMap.values() ), changedIds: this.state.changedIds } );
    }

    public floodTimer?:number;

    async componentDidMount(): Promise<void> {

        await super.componentDidMount();

        const
            socket = this.props.context.gpSocket.project;

        socket.on( "tail", (doc:any) => {

            this.rowMap.set( doc._id, doc );
            this.state.changedIds?.add( doc._id )

            const { rowData } = this.state;

            rowData.unshift( doc );

            this.rowMap.clear();

            rowData.forEach( ( row:any ) => this.rowMap.set( row._id, row ))

            this.setState({ rowData: Array.from( this.rowMap.values() ), changedIds: this.state.changedIds } );
        } )
    }

    componentDidUpdate(prevProps: TableProps<any>, prevState: TableState<any>) {
        super.componentDidUpdate(prevProps as any, prevState);
        if ( prevState.reqId !== this.state.reqId ) {
            const socket = this.props.context.gpSocket.project;
            socket.off( `search:row:${prevState.reqId}` )
            socket.off( `search:meta:${prevState.reqId}` )
            socket.on( `search:row:${this.state.reqId}`, ( row:any ) => {

                const rows = Array.isArray( row ) ? row : [ row ];

                rows.forEach( ( item:any ) => {
                    this.rowMap.set( item._id, item );
                    socket.on( item._id, ( doc:any ) => {
                        this.state.changedIds?.add( doc._id )
                        if ( this.rowMap.has( doc._id ) ) {
                            this.rowMap.set( doc._id, doc );
                            if ( this.floodTimer ) {
                                clearTimeout(this.floodTimer);
                                this.floodTimer = undefined;
                            }
                            this.floodTimer = setTimeout( () => this.floodPrevention(), 500 ) as any;
                        }
                    })
                } )
                this.setState({ loading:false, changedIds: new Set(), rowData: Array.from( this.rowMap.values() ) } );
            } );

            socket.on( `search:meta:${this.state.reqId}`, ( meta:any ) => {
                this.setState({selectedRows: [], currentPage: meta.page, meta: meta })
            }  )
        }
    }

    componentWillUnmount() {
        const socket = this.props.context.gpSocket.project;
        super.componentWillUnmount();
        socket.off( `search:row:${this.state.reqId}` )
        socket.off( `search:meta:${this.state.reqId}` )
        socket.off( `tail` );
        Array.from(( this.rowMap.keys() ) ).forEach( key => {
            socket.off( key );
        } );
    }

    async getData(): Promise<void> {

        const
            socket = this.userContext.gpSocket.project,
            {sortOrder, sortField, currentPage, itemsPerPage, rowData} = this.state,
            ids = rowData.map( row => row._id);

        this.rowMap.clear();

        socket.emit( "unbind:ids",  ids );
        socket.emit( "search:abort" );

        ids.forEach( id => {
            socket.off( id );
        })

        this.setState( { reqId: uuid(), loading: true }, () => {
            const
                filters = this.filtersToQuery(),
                sort = {
                    [sortField ?? "importedTimestamp"]: sortOrder === "ascend" ? 1 : -1
                };

            if (
                sort.importedTimestamp === -1 &&
                Object.keys( filters ).length === 0 ||
                (
                    Object.keys( filters ).length === 1 &&
                    filters.importedTimestamp?.length > 1 &&
                    new Date( filters.importedTimestamp[1] ) > new Date()
                )
            ) {
                socket.emit( "tail", () => console.info( "tailing for new projects activated..."))
            }
            else {
                socket.emit( "untail", () => console.info( "tailing de-activated"))
            }

            socket.emit(`search`, {
                filter: this.filtersToQuery(),
                // filter: this.filters,
                sort: {
                    [sortField ?? "created_at"]: sortOrder === "ascend" ? 1 : -1
                },
                pagination: {
                    pageNumber: currentPage || 1,
                    resultsPerPage: itemsPerPage
                },
                reqId: this.state.reqId
            }, () => {
                this.setState({ loading: false, changedIds: new Set() } )
            });
        })
    }

    send(): Promise<void> {
        return Promise.resolve(undefined);
    }

}

export default function Project () {
    const
        context = useMercuryContext(),
        location = useLocation(),
        breaks = useBreakpoint();

    return(
        <Routes location={location}>
            <Route
                path="/"
                //@ts-ignore
                element={<ProjectSearch id={"Projects"} displayHideFilters={true} context={context as any} useLegacyEndpoints={false} breaks={breaks as any} />}
            />
            <Route
                path="/project/:shortId"
                element={<DetailsPage id={"Projects"} key={location.key}/>}
            />
            <Route
                path='*'
                element={<NotFoundPage />}
            />
        </Routes>
    )
}
