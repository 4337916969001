import React, { useState, useRef, useEffect, useCallback } from "react";
import { useMercuryContext } from "../user-context";
import { Helmet } from "react-helmet";

import { Form } from "antd";

import PageTitle from "../ui/PageTitle";
import EditUser from "../components/managePages/users/EditUser";
import AddUser from "../components/managePages/users/AddUser";
import UserTable from "../components/managePages/users/UserTable";
import BackTopButton from "../ui/BackTopButton";

import {
  failedNotification,
  successfulNotification,
} from "../utils/notifications";

import "../components/managePages/users/manage-users.scss";

const initialEditingValue = { isEditing: false, editingRow: "" };

const ManageUsers = () => {
  const { accessToken, umsClient: Client, client:mcasClient, flags } = useMercuryContext(),
    [isLoading, setIsLoading] = useState(false),
    [visibleEditDrawer, setVisibleEditDrawer] = useState(false),
    [users, setUsers] = useState(null),
    [sortedInfo, setSortedInfo] = useState(""),
    [editing, setEditing] = useState(initialEditingValue),
    [total, setTotal] = useState(0),
    [editingForm] = Form.useForm(),
    [newUserForm] = Form.useForm(),
    editFormRef = useRef(null),
    [visibleNewUserDrawer, setVisibleNewUserDrawer] = useState(false),
    [permissionsList, setPermissioonsList] = useState([]),
    [applicationsList, setApplicationsList] = useState([]),
    [rolesList, setRolesList] = useState([]);

  // fetch all users
  const fetchUserList = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await Client.get(`/core-ums-v2/users?limit=1000&sort[firstName]=desc`);

      setUsers(data.sort((a, b) => a.firstName.localeCompare(b.firstName)));
      setTotal(data.length);
    } catch (error) {
      console.error('Error fetching users list: ', error);
    }
    setIsLoading(false);
  }, [Client, flags.umsVersion]);

  const createUser = async (data) => {
    try {
      const { _id, key, ...patch } = data;

      const result = await Client.post(
        `/core-ums-v2/users`,
        patch
      );

      if (result?.response?.data?.code === 11000) {
        failedNotification(
          "Failed to create user",
          "Duplicate key. Email already exists."
        );
      } else {
        successfulNotification(
          `Successfully submitted`,
          `User (${result.data.firstName}) was created. Please notify the user to login again.`
        );
      }
    } catch (error) {
      failedNotification(
        "Failed to create user",
        error?.response?.data?.detail
      );
    }
    handleRefresh();
  };

  const updateUser = async (newData) => {
    try {
      setEditing({ ...editing, isEditing: true });
      const { _id, key, ...patch } = newData;

      const result = await Client.patch(
        `/core-ums-v2/users/${_id}`,
        patch
      );
      successfulNotification(
        `Successfully submitted`,
        `User (${result.data.firstName}) was updated. Please notify the user to login again.`
      );
    } catch (error) {
      failedNotification(
        `Failed to update user`,
        error?.response?.data?.detail
      );
    }
    setEditing(initialEditingValue);
    handleRefresh();
  };

  // on load
  useEffect(() => {
    if (!users) {
      fetchUserList();
    }
  }, [users, fetchUserList]);

  // fetch all permissions
  const fetchPermissionsList = useCallback(async () => {
    try {
      const { data } = await Client.get(
        `core-ums-v2/permissions`
      );
      // fix to add unique key
      const dataWithKey = data.map((item) => {
        return {
          ...item,
          key: item.id,
        };
      });
      setPermissioonsList(dataWithKey);
    } catch (error) {
      console.error('Error fetching permissions list: ', error);
    }
  }, [Client, flags.umsVersion]);

  // fetch all customer applications
  const fetchApplicationsList = useCallback(async () => {
    try {
      const { data } = await mcasClient.get("core-mcas-v1");

      setApplicationsList(data);
    } catch (error) {
      console.error('Error fetching applications list: ', error);
    }
  }, [mcasClient]);

  const fetchRolesList = useCallback(async () => {
    try {
      const { data } = await Client.get(
        `/core-ums-v2/roles`
      );

      setRolesList(data);
    } catch (error) {
      console.error('Error fetching roles list: ', error);
    }
  }, [Client, flags.umsVersion]);

  useEffect(() => {
    if (!applicationsList?.length) {
      fetchApplicationsList();
    }
    if (!permissionsList?.length) {
      fetchPermissionsList();
    }
    if (!rolesList?.length) {
      fetchRolesList();
    }
  }, [
    fetchApplicationsList,
    applicationsList,
    fetchPermissionsList,
    permissionsList,
    rolesList,
    fetchRolesList,
  ]);

  const handleRefresh = () => {
    accessToken && fetchUserList(accessToken);
  };

  function showEditDrawer() {
    setVisibleEditDrawer(true);
  }

  const closeEditDrawer = (e) => {
    setVisibleEditDrawer(false);
    editingForm.resetFields();
    e !== undefined && setEditing(initialEditingValue);
  };


  // const defaultValues = {
  //   firstNameValue: editing.editingRow.firstName
  // };

  // fix to update initial values of edit user form
  useEffect(() => {
    if (editFormRef.current) {
      editingForm.resetFields();
    }
  }, [editFormRef, editingForm, editing.editingRow.firstName]);

  // on save of edit user
  const onFinishEditing = (newValues) => {
    const permissions = [];

    // fix for antd not extracting data from table/checkboxes
    for (const [key, value] of Object.entries(newValues)) {
      // check if is a permission
      if (key.indexOf("permissions") > -1) {
        // extract id
        const id = key.split("-").pop("");

        // extact new key
        const newKey = key.split("~").pop("").replace(`-${id}`, "");

        const foundPermission = permissions.find((p) => p.permissionId === id);

        if (foundPermission) {
          foundPermission[newKey] = value ? value : false;
        } else {
          const newPermission = { permissionId: id };
          newPermission[newKey] = value ? value : false;
          permissions.push(newPermission);
        }
      }
    }

    const {
      adminValue,
      vendorManagerValue,
      isActiveValue,
      firstNameValue,
      lastNameValue,
      emailValue,
      customerApplicationsValue,
      rolesValue,
    } = newValues;

    const newData = {
      _id: editing.editingRow._id,
      key: editing.editingRow.key,
      firstName: firstNameValue,
      lastName: lastNameValue,
      email: emailValue,
      admin: adminValue,
      isActive: isActiveValue,
      vendorManager: vendorManagerValue,
      deleted: false,
      customerApplications: customerApplicationsValue,
      roles: rolesValue,
      userLevelPermissions: permissions,
    };

    updateUser(newData);

    closeEditDrawer();
  };

  // on save of edit user
  const onFinishNewUser = (values) => {
    const permissions = [];

    // fix for antd not extracting data from table/checkboxes
    for (const [key, value] of Object.entries(values)) {
      // check if is a permission
      if (key.indexOf("permissions") > -1) {
        // extract id
        const id = key.split("-").pop("");

        // extact new key
        const newKey = key.split("~").pop("").replace(`-${id}`, "");

        const foundPermission = permissions.find((p) => p.permissionId === id);

        if (foundPermission) {
          foundPermission[newKey] = value ? value : false;
        } else {
          const newPermission = { permissionId: id };
          newPermission[newKey] = value ? value : false;
          permissions.push(newPermission);
        }
      }
    }

    const {
      adminValue,
      vendorManagerValue,
      isActiveValue,
      firstNameValue,
      lastNameValue,
      emailValue,
      customerApplicationsValue,
      rolesValue,
    } = values;

    const data = {
      firstName: firstNameValue,
      lastName: lastNameValue,
      email: emailValue,
      admin: adminValue,
      isActive: isActiveValue,
      vendorManager: vendorManagerValue,
      deleted: false,
      customerApplications: customerApplicationsValue,
      roles: rolesValue,
      userLevelPermissions: permissions,
    };

    createUser(data);
  };

  const handleChangeTable = (pagination, filters, sorter, extra) => {
    setSortedInfo(sorter);
  };

  const handleClickEdit = (record) => {
    setEditing({ ...editing, isEditing: true, editingRow: record });
    showEditDrawer();
  };

  // add new user functionality
  const showNewUserDrawer = () => {
    setVisibleNewUserDrawer(true);
  };

  const closeNewUserDrawer = () => {
    setVisibleNewUserDrawer(false);
    newUserForm.resetFields();
  };

  return (
    <div className="manage-page manage-users-page">
      <Helmet>
        <title>Manage Users - Mercury © RWS</title>
      </Helmet>
      <PageTitle title="Manage Users" />
      <AddUser
        handlers={{ showNewUserDrawer, closeNewUserDrawer, onFinishNewUser }}
        values={{
          visibleNewUserDrawer,
          newUserForm,
          applicationsList,
          rolesList,
          permissionsList,
        }}
      />

      {editing.isEditing && (
        <EditUser
          handlers={{
            closeEditDrawer,
            onFinishEditing,
          }}
          values={{
            visibleEditDrawer,
            editingForm,
            editFormRef,
            applicationsList,
            rolesList,
            permissionsList,
            editing,
          }}
        />
      )}
      <UserTable
        values={{ users, sortedInfo, editing, total, isLoading }}
        handlers={{
          handleChangeTable,
          handleClickEdit,
          handleRefresh,
        }}
      />
      <BackTopButton />
    </div>
  );
};

export default ManageUsers;
