import {useCallback, useEffect, useState} from "react";
import {useMercuryContext} from "../user-context";
import {Helmet} from "react-helmet";
import axios from "axios";
import {useNavigate, useLocation} from "react-router-dom";
import {BASE_URL} from "../utils/variables";
import {defaultHeaders} from "../api/APIUtils";

import InvoicingDetailsPageHeader from "../components/invoicingProjectDetailsPage/InvoicingDetailsPageHeader";
import GeneralInformation from "../components/invoicingProjectDetailsPage/invoicingGI/GeneralInformation";
import InvoicingActions from "../components/invoicingProjectDetailsPage/invoicingActions/InvoicingActions";
import InvoicingTable from "../components/invoicingProjectDetailsPage/invoicingTable/InvoicingTable";

import BackTopButton from "../ui/BackTopButton";
import LoadingStar from "../ui/LoadingStar";

import { Tabs} from "antd";
import { CreditCardOutlined } from "@ant-design/icons";

import { failedNotification, successfulNotification } from "../utils/notifications";

import uuid from "uuid-browser";

const InvoicingDetailsPage = (props: any) => {
  const { accessToken, gpSocket, gpClient } = useMercuryContext();
  const navigate = useNavigate(),
      location = useLocation();

  const currentProjectId = location?.pathname.replace('/invoicing-project/', '');

  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState<any>({});
  const [totalTableProjects, setTotalTableProjects] = useState();
  const [sortData, setSortData] = useState<any>({
    order: "ascend",
    columnKey: "plpLang",
  });
  const [filterData, setFilterData] = useState<any>({});
  const [activeActions, setActiveActions] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsID, setSelectedRowsID] = useState([]);
  const [tableData, setTableData] = useState<any>([]);
  const [isEditingWWC, setIsEditingWWC] = useState(false);
  const [isEditingHours, setIsEditingHours] = useState(false);
  const [isEditingPO, setIsEditingPO] = useState(false);
  const [isNewRate, setIsNewRate] = useState(false);
  const [isProcessingReload, setIsProcessingReload] = useState(false);
  const [isProcessingEstimateSubmission, setIsProcessingEstimateSubmission] =
      useState(false);
  const [isProcessingAccrualSubmission, setIsProcessingAccrualSubmission] =
      useState(false);

  const [pmFee, setPMFee] = useState(0.0);
  const [ deletedPLPs, setDeletedPLPs ] = useState([]);
  const [ recovering, setRecovering ] = useState(false);
  const [ projectType, setProjectType ] = useState(false);

  const socket = gpSocket.plp;

  // fetch project details
  const fetchProject = async (useIsLoading = true) => {
    // project endpoint
    try {
      setIsLoading(useIsLoading);
      const result = await gpClient.get(`/google-gp-v1/project/shortId/${currentProjectId}`);

      setDetails(result?.data);
      let projectAdjustment = [];
      [result?.data].forEach((item, index) =>
          projectAdjustment.push({
            key: index,
            ...item,
          })
      )

      setTotalTableProjects(result?.data?.plps.length);
      setDeletedPLPs(result?.data?.deletedPlps ? result?.data?.deletedPlps: []);
      setProjectType(result?.data?.customVendorProjectMetadata?.projectType)
      setTableData(
          result?.data?.plps.map((plp: any, plpIndex: any) => {
            let plpObject: any = {
              key: `${plpIndex}`,
              id: plp?._id,
              plpLang: plp?.targetLanguageCode,
              projectType: plp?.customVendorPLPMetadata?.projectType,
              wmtPONumber: plp?.invoiceData?.wmtPONumber,
              service: plp?.serviceName,
              status: plp?.catToolStatus,
              receivedDate: plp?.customVendorPLPMetadata?.importedTimestamp,
              dueDate: plp?.latestTranslationDueTimestamp,
              varStatus: plp?.customVendorPLPMetadata?.varStatus,
              updatingVarStatus: false,
              receivedGGL:
              plp?.customVendorPLPMetadata?.lastImportedTimestamp,
              wwc: plp?.totalWeightedWordCount,
              serviceHour: plp?.workLog?.serviceHours
                  ? plp?.workLog?.serviceHours
                  : 0,
              dtpHour: plp?.workLog?.dtpHours ? plp?.workLog?.dtpHours : 0,
              engHour: plp?.workLog?.engineeringHours
                  ? plp?.workLog?.engineeringHours
                  : 0,
              rate: plp?.invoiceData?.rateNameApplied
                  ? plp?.invoiceData?.rateNameApplied
                  : "-",
              pmSurcharge: plp?.invoiceData?.pmFeeCharge
                  ? plp?.invoiceData?.pmFeeCharge
                  : 0,
              weekendSurcharge: plp?.invoiceData?.weekendSurcharge
                  ? plp?.invoiceData?.weekendSurcharge
                  : "0",
              weekendSurchargeApplies: plp?.invoiceData
                  ?.weekendSurchargeApplies
                  ? plp?.invoiceData?.weekendSurchargeApplies
                  : false,
              invoiceErrors: plp?.invoiceData?.invoiceErrors
                  ? plp?.invoiceData?.invoiceErrors
                  : [],
              totalCharge: plp?.invoiceData?.totalCharge
                  ? plp?.invoiceData?.totalCharge
                  : 0,
              serviceRate: plp?.invoiceData?.serviceRate
                  ? plp?.invoiceData?.serviceRate
                  : 0,
              importedTimestamp: plp?.customVendorPLPMetadata?.importedTimestamp
            };

            if (result?.data?.customVendorProjectMetadata.projectType === 'QV') {
              plpObject["startingNumberOfIntents"] =
                  result?.data?.queryVariationProjectMetadata?.startingNumberOfIntents;

              plpObject["finalNumberOfIntents"] =
                  plp?.queryVariationPLPMetadata?.finalNumberOfIntents;

            }

            return plpObject;

          })
      );
    } catch (error: any) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const fetchPMFee = useCallback( async (token: any) => {
    // Step 1: Count occurrences of each pmFeeRate
    const pmFeeRateCount = details?.plps?.reduce((count:any, doc:any) => {
      const rate = doc.invoiceData.pmFeeRate;
      if (rate != null && doc.plpStatus !== "CANCELED") {
        count[rate] = (count[rate] || 0) + 1;
      }
      return count;
    }, {});

    let mostCommonRate = null;

    if (pmFeeRateCount) {
      // Step 2: Find the pmFeeRate with the highest occurrence
      mostCommonRate = Object.keys(pmFeeRateCount).reduce<string>(
        (a, b) => (pmFeeRateCount[a] > pmFeeRateCount[b] ? a : b),
        ""
      );
    }

    if (mostCommonRate && mostCommonRate !== null && !isNaN(Number(mostCommonRate))) {
      setPMFee(Number(mostCommonRate));
    } else {
      const pmFeeEndpoint = `${BASE_URL}/pmFee`;
      try {
        setIsLoading(true);
        await axios
            .get(pmFeeEndpoint, {
              headers: defaultHeaders(token),
            })
            .then((response) => {
              setPMFee(response.data.fee);
            });
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  }, [details] );

  useEffect(() => {
    fetchProject();
  }, []);

  useEffect(() => {
    accessToken && details && fetchPMFee(accessToken);
  }, [accessToken, details, fetchPMFee, pmFee]);

  const handleSubmitNewPMFee = async (newPmFee: number) => {
    const endpoint = `google-gp-v1/plp/setPmFeeByProjectId/${details?.catToolProjectId}`;

    try {
      await gpClient.patch(endpoint, {pmFee: newPmFee.toString()}).then((result: any) => {
        console.info(result)

        successfulNotification(
          `The updated PM Fee charges should appear in the table shortly`,
          "If they don’t, please try reloading the page."
        );
      });
    } catch (error) {
      console.error(error)
        failedNotification("Failed to save the new PM Fee Rate");
    } finally {
      await quietRefresh();
        setPMFee(newPmFee);
    }
  };

  const handleDeleteProject = async () => {
    try {
      return await gpClient.delete(`/google-gp-v1/project/softDelete/${details._id}`).then((data:any) => {
        successfulNotification(
          'Project Deleted',
          `The project has been deleted. ${data?.data?.deletedPLPs} PLPs have been deleted.`
        );

        navigate(`/invoicing`);
      });
    } catch (error: any) {
      if (error.response) {
        failedNotification(
            error.response.data.error,
            error.response.data.detail
        );
      } else {
        failedNotification("Failed to delete the project");
      }
    }
  };

  // default filter values
  const filterValues: any = {
    plpLang: [],
    service: [],
    status: [],
    varStatus: [],
    receivedGGL: [],
  };

  // populate filter values on load
  tableData && tableData.map(
      (item: any) =>
          filterValues.plpLang.push(item.plpLang) &&
          filterValues.service.push(item.serviceName) &&
          filterValues.status.push(item.status) &&
          filterValues.varStatus.push(item.varStatus) &&
          filterValues.receivedGGL.push(item.receivedGGL)
  );

  // Handle table row selection
  const handleTableRowSelection = {
    // will disable all rows that have Invoiced status
    getCheckboxProps: (record: any) => {
      return {
        disabled:
            record.varStatus && record.varStatus.toUpperCase() === "INVOICE",
      };
    },
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelectedRows(selectedRows);
      setSelectedRowsID(selectedRowKeys);
      if (selectedRows.length > 0) {
        setActiveActions(true);
      } else setActiveActions(false);
    },
  };

  const selectAllPLPS = () => {
    setSelectedRowsID(tableData.map((plp: any) => plp.key));
    setSelectedRows(tableData);
  };

  let rows = selectedRows;

  const LQEcheck = (editWWC: any) => {
    if (details?.customVendorProjectMetadata?.projectType === "LQE_VIDEO") {
      if (!editWWC) {
        selectAllPLPS();
      }
      rows = tableData;
    }
  };

  // handle change of details table
  const handleChangeDetailsTable = (pagination: any, filters: any, sorter: any, extra: any) => {
    setSortData(sorter);
    setTotalTableProjects(extra.currentDataSource.length);

    // fix to set an empty object if there are no values instead of undefined keys
    const checkFilters = Object.values(filters).filter((item) => item !== null);

    if (checkFilters.length >= 1) {
      setFilterData(filters);
    } else {
      setFilterData({});
    }
  };

  const clearTableSelection = () => {
    setSelectedRows([]);
    setSelectedRowsID([]);
    setActiveActions(false);
  };

  // Clear Filters and Sorter
  const handleClearFilterSort = () => {
    setFilterData({});
    setSortData({});
  };

  // ACTIONS
  // Edit button
  const handleClickEditWWC = () => {
    LQEcheck(true);
    setIsEditingWWC(true);
  };

  const handleClickEditHours = () => {
    setIsEditingHours(true);
  };
  const handleClickSetPONumber = () => {
    LQEcheck(isEditingWWC);
    setIsEditingPO(true);
  };

  const handleClickRate = () => {
    setIsNewRate(true);
  };

  const handleClickReload = async () => {
    try {
      setIsProcessingReload(true);
      await quietRefresh();
    } catch (error) {
      console.error("Error while reloading");
    } finally {
      setIsProcessingReload(false);
    }
  };

  const quietRefresh = async () => {
    try {
      await fetchProject(false);
      successfulNotification(
          "Project data reloaded",
          "The project data has been reloaded"
      );
    } catch (error) {
      failedNotification(
          "Failed to reload",
          "Unable to reload the project data at this time, please try again. If the issue persists please contact support."
      );
    } finally {
      clearTableSelection();
    }
  };

  const quietRefreshUseCallBack = useCallback( async () => {
    try {
      await fetchProject(false);
      successfulNotification(
          "Project data reloaded",
          "The project data has been reloaded"
      );
    } catch (error) {
      failedNotification(
          "Failed to reload",
          "Unable to reload the project data at this time, please try again. If the issue persists please contact support."
      );
    } finally {
      clearTableSelection();
    }
  }, [accessToken]);


  const handleFinishEditingWWC = async (submitted: boolean, values: any) => {
    try {
      // Check if form was submitted or cancelled
      if (!submitted) {
        setIsEditingWWC(false);
        return;
      }

      // Submit the form
      let headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      let submittedPLPPromises = [];

      let data = {
        ids: selectedRows.map((row: any) => row.id),
        totalWeightedWordCount: values.totalWeightedWordCount
      }

      submittedPLPPromises = selectedRows.map((plp: any, index: any) => {
        return axios
            .patch(`${process.env.REACT_APP_SERVICES_API_URL}/google-gp-v1/plp/${plp.id}/setQuantities`, data, {
              headers: headers,
            })
            .then((response) => {

              let updatedPLP = response?.data?.[index];
              successfulNotification(
                  `PLP Quantities updated`,
                  `The quantities for ${updatedPLP.targetLanguageCode} have been updated`
              );

              // Update the table entry
              let updatedTableData = tableData.map((tableEntry: any) => {
                if (tableEntry.id === updatedPLP._id) {
                  tableEntry.wwc = updatedPLP?.workLog?.totalWeightedWordCount;
                }

                return tableEntry;
              });

              setTableData(updatedTableData);
            })
            .catch((error) => {
              if (error.response) {
                failedNotification(
                    error.response.data.error,
                    error.response.data.detail
                );
              } else {
                failedNotification("Failed to update the PLP");
              }
            });
      });

      await Promise.allSettled(submittedPLPPromises);

      setIsEditingWWC(false);
    } catch (error) {
      failedNotification("Failed to update the PLPs");
    } finally {
      // Reload the project data in the background
      quietRefresh();
    }
  };

  const handleFinishEditingHours = async (submitted: boolean, values: any) => {
    try {
      // Check if form was submitted or cancelled
      if (!submitted) {
        setIsEditingHours(false);
        return;
      }

      // Submit the form
      let headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      let submittedPLPPromises = [];

      let data = {
          ids: selectedRows.map((row: any) => row.id),
          workLog: values
      }

      submittedPLPPromises = selectedRows.map((plp: any, index: number) => {

        return axios
            .patch(`${process.env.REACT_APP_SERVICES_API_URL}/google-gp-v1/plp/${plp.id}/setQuantities`, data, {
              headers: headers,
            })
            .then((response) => {

              let updatedPLP = response?.data?.[index];
              successfulNotification(
                  `PLP Quantities updated`,
                  `The quantities for ${updatedPLP.targetLanguageCode} have been updated`
              );

              // Update the table entry
              let updatedTableData = tableData.map((tableEntry: any) => {
                if (tableEntry.id === updatedPLP._id) {
                  tableEntry.serviceHour = updatedPLP?.workLog?.serviceHours;
                  tableEntry.dtpHour = updatedPLP?.workLog?.dtpHours;
                  tableEntry.engHour = updatedPLP?.workLog?.engineeringHours;
                }

                return tableEntry;
              });

              setTableData(updatedTableData);
            })
            .catch((error) => {
              if (error.response) {
                failedNotification(
                    error.response.data.error,
                    error.response.data.detail
                );
              } else {
                failedNotification("Failed to update the PLP");
              }
            });
      });

      await Promise.allSettled(submittedPLPPromises);

      setIsEditingHours(false);
    } catch (error) {
      failedNotification("Failed to update the PLPs");
    } finally {
      // Reload the project data in the background
      quietRefresh();
    }
  };

  const handleSetWMTPONumber = async (submitted: boolean, values: any) => {
    try {
      // Check if form was submitted or cancelled
      if (!submitted) {
        setIsEditingPO(false);
        return;
      }

      let payload = {
        ids: selectedRows.map((row: any) => row.id),
        wmtPONumber: values.wmtPONumber
      }
      let submittedPLPPromises = [];

      submittedPLPPromises = selectedRows.map(async (plp: any, index: number) => {
        try {
          return await gpClient.patch(`/google-gp-v1/plp/${plp.id}/setQuantities`, payload).then((result: any) => {
            let updatedPLP = result.data?.[index];
                successfulNotification(
                    `PLP Quantities updated`,
                    `The quantities for ${updatedPLP.targetLanguageCode} have been updated`
                );

                // Update the table entry
                let updatedTableData = tableData.map((tableEntry: any) => {
                  if (tableEntry.id === updatedPLP._id) {
                    tableEntry.wwc = updatedPLP?.totalWeightedWordCount;
                    tableEntry.serviceHour = updatedPLP?.workLog?.serviceHours;
                    tableEntry.dtpHour = updatedPLP?.workLog?.dtpHours;
                    tableEntry.engHour = updatedPLP?.workLog?.engineeringHours;
                    tableEntry.pmSurcharge = updatedPLP?.invoiceData?.pmFeeCharge;
                  }

                  return tableEntry;
                });

                setTableData(updatedTableData);
          });
        } catch (error: any) {
          if (error.response) {
            failedNotification(
                error.response.data.error,
                error.response.data.detail
            );
          } else {
            failedNotification("Failed to update the PLP");
          }
        }
      });

      await Promise.allSettled(submittedPLPPromises);

      setIsEditingPO(false);
    } catch (error) {
      failedNotification("Failed to update the PLPs");
    } finally {
      // Reload the project data in the background
      quietRefresh();
    }
  };

  const handleClickFinishRate = async (submitted: boolean, values: any) => {
    try {
      // Check if form was submitted or cancelled
      if (!submitted) {
        setIsNewRate(false);
        return;
      }
      const
          { rates } = values,
          headers = {
            Authorization: `Bearer ${accessToken}`,
          },
          submittedPLPPromises = selectedRows.map((plp: any) => {

            const rate = rates.find( ( rate: any ) => {
              return rate.target === plp.plpLang;
            })


            return new Promise( ( accept: any) => {
              if ( !rate ) {
                failedNotification(
                    `PLP matching Rates failed`,
                    `There are no matching rates for ${plp.plpLang}, match has failed`
                );
                return accept( true );
              }

              // Create the data to post
              const data = {
                rateNameApplied: rate.name,
                wordRate: rate.wordRate,
                serviceRate: rate.serviceRate,
                dtpRate: rate.dtpRate,
                engineeringRate: rate.engineeringRate,
                weekendSurchargeApplies: rate.weekendSurchargeApplies,
                weekendSurcharge: rate.weekendSurcharge,
                pmFeeRate: pmFee,
                service: rate.service,
                rateId: rate._id
              };

              return axios
                  .post(`${BASE_URL}/plp/${plp.id}/setRate`, data, {
                    headers: headers,
                  })
                  .then((response) => {
                    let updatedPLP = response.data;

                    if (
                        updatedPLP.invoiceData.invoiceErrors.length === 0
                    ) {
                      // Notify the user of success
                      successfulNotification(
                          `PLP matching Rates updated`,
                          `The matching rates for ${updatedPLP.sourceLanguageCode} and ${updatedPLP.targetLanguageCode} have been updated`
                      );
                    } else {
                      // Notify the user of failure
                      failedNotification(
                          `PLP matching Rates failed`,
                          `There are no matching rates for ${updatedPLP.sourceLanguageCode} and ${updatedPLP.targetLanguageCode}, match has failed`
                      );
                    }

                    // Update the table entry
                    let updatedTableData = tableData.map((tableEntry: any) => {
                      if (tableEntry.id === updatedPLP._id) {
                        tableEntry.rate = updatedPLP?.invoiceData?.rateNameApplied;
                        tableEntry.weekendSurcharge =
                            updatedPLP?.invoiceData?.weekendSurcharge;
                        tableEntry.weekendSurchargeApplies =
                            updatedPLP?.invoiceData?.weekendSurchargeApplies;
                        tableEntry.pmSurcharge = updatedPLP?.invoiceData?.pmFeeCharge;
                      }

                      return tableEntry;
                    });

                    setTableData(updatedTableData);
                  })
                  .catch((error) => {
                    if (error.response) {
                      failedNotification(
                          error.response.data.error,
                          error.response.data.detail
                      );
                    }
                  })
                  .finally( accept )
            })
          });

      await Promise.allSettled(submittedPLPPromises);

      setIsNewRate(false);
    } catch (error: any) {
      failedNotification(error.response.data.error, error.response.data.detail);
    } finally {
      // Reload the project data in the background
      quietRefresh();
    }
  };
  const handleSubmitPLPRecovery = async (values: any) => {

    // No items from the checkbox selected
    if( !values?.deletedPLPs ) {
      successfulNotification(
          `No PLP Recovered`,
          `No PLP was selected for Recovery`
      );
      return;
    }

    try {
      socket.emit( "recover", values?.deletedPLPs, ( res: any ) => {
        if ( res.error ) {
          failedNotification("Failed to restore the PLPs", res.error );
        }
      } )
    } catch (error) {
      failedNotification("Failed to restore the PLPs");
    } finally {
      // Reload the project data in the background
      quietRefresh();
    }

  }

  const handleClickPLPRecovery = (value: any) => {
    setRecovering(value);
  };


  // Delete button
  const handleClickDelete = async () => {
    try {
      // Submit the estimate for each selected PLP

      socket.emit( "softDelete", selectedRows.map((plp: any) => plp.id), ( res: any ) => {
        if ( res.error ) {
          failedNotification("Failed to delete the PLPs", res.error );
        }
      } )
    } catch (error) {
      failedNotification("Failed to delete the PLPs");
    } finally {
      // Reload the project data in the background
      quietRefresh();
    }
  };

  // Estimate button
  const handleClickEstimate = async () => {

    try {

      LQEcheck(isEditingWWC);

      setIsProcessingEstimateSubmission(true);

      // Submit the estimate for each selected PLP
      let headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      let submittedPLPPromises = [];

      submittedPLPPromises = rows.map((plp: any) => {
        if (
            plp.varStatus.toUpperCase() === "NEW" ||
            plp.varStatus.toUpperCase() === "ESTIMATE" ||
            plp.varStatus.toUpperCase() === "ESTIMATE_FAILED"
        ) {
          // Set the status to updating
          let updatedTableData = tableData.map((tableEntry: any) => {
            if (tableEntry.id === plp._id) {
              tableEntry.invoiceErrors = [];
              tableEntry.updatingVarStatus = true;
            }

            return tableEntry;
          });

          setTableData(updatedTableData);

          // Submit the estimate
          return axios
              .post(
                  `${BASE_URL}/plp/${plp.id}/submitEstimate`,
                  {},
                  {
                    headers: headers,
                  }
              )
              .then((response) => {
                // Get the updated PLP
                let updatedPLP = response.data;

                if (updatedPLP.invoiceData.invoiceErrors.length === 0) {
                  // Notify the user of success
                  successfulNotification(
                      `PLP Estimate Submitted`,
                      `The estimate for ${updatedPLP.targetLanguageCode} has been updated`
                  );
                } else {
                  // Notify the user of failure
                  failedNotification(
                      "Failed to submit PLP Estimate",
                      "Please review the errors below"
                  );
                }

                // Update the table entry with the new status
                let updatedTableData = tableData.map((tableEntry: any) => {
                  if (tableEntry.id === updatedPLP._id) {
                    tableEntry.invoiceErrors =
                        updatedPLP?.invoiceData?.invoiceErrors;
                    tableEntry.varStatus =
                        updatedPLP?.customVendorPLPMetadata?.varStatus;
                    tableEntry.updatingVarStatus = false;
                  }

                  return tableEntry;
                });

                setTableData(updatedTableData);
              })
              .catch((error) => {
                // Display and error to the user
                if (error.response) {
                  failedNotification(
                      error.response.data.error,
                      error.response.data.detail
                  );
                } else {
                  failedNotification("Failed to update the PLP");
                }

                // Update the table entry
                let updatedTableData = tableData.map((tableEntry: any) => {
                  if (tableEntry.id === plp._id) {
                    tableEntry.updatingVarStatus = false;
                  }

                  return tableEntry;
                });

                setTableData(updatedTableData);
              });
        } else {
          failedNotification(
              `Cannot submit estimate for PLP ${plp.plpLang}, the status of the PLP must be NEW, ESTIMATE or ESTIMATE_FAILED`
          );
          return Promise.resolve();
        }
      });

      await Promise.allSettled(submittedPLPPromises);
    } catch (error) {
      failedNotification("Failed to update the PLPs");
    } finally {
      setIsProcessingEstimateSubmission(false);
      // Reload the project data in the background
      quietRefresh();
    }
  };

  const
      enqueue = (() => {
        let
            running = 0,
            tasks: any = [];

        return async (task: any) => {
          tasks.push(task);
          if (running >= 1) return;

          ++running;
          while (tasks.length) {
            try {
              await tasks.shift()();
            } catch(err) {
              console.error(err);
            }
          }
          --running;
          if ( tasks.length === 0 ) {
            setIsProcessingAccrualSubmission(false);
            // Reload the project data in the background
            quietRefresh();
          }
        }
      })(),
      addPLP = ( plp: any ) => {
        return new Promise( ( accept ) => {

          if (
              plp.varStatus.toUpperCase() === "ESTIMATE" ||
              plp.varStatus.toUpperCase() === "ACCRUAL" ||
              plp.varStatus.toUpperCase() === "ACCRUAL_FAILED"
          ) {
            // Set the status to updating
            let updatedTableData = tableData.map((tableEntry: any) => {

              if (tableEntry.id === plp.id) {
                tableEntry.invoiceErrors = [];
                tableEntry.updatingVarStatus = true;
              }

              return tableEntry;
            });

            setTableData(updatedTableData);

            // Submit the accrual
            axios
                .post(
                    `${BASE_URL}/plp/${plp.id}/submitAccrual`,
                    {},
                    {
                      headers: {
                        Authorization: `Bearer ${accessToken}`,
                      },
                    }
                )
                .then((response) => {
                  // Get the updated PLP
                  let updatedPLP = response.data;

                  if (updatedPLP.invoiceData.invoiceErrors.length === 0) {
                    // Notify the user of success
                    successfulNotification(
                        `PLP Accrual Submitted`,
                        `The estimate for ${updatedPLP.targetLanguageCode} has been updated`
                    );
                  } else {
                    // Notify the user of failure
                    failedNotification(
                        "Failed to submit PLP Accrual",
                        "Please review the errors below"
                    );
                  }

                  // Update the table entry with the new status
                  let updatedTableData = tableData.map((tableEntry: any) => {
                    if (tableEntry.id === updatedPLP.id) {
                      tableEntry.invoiceErrors =
                          updatedPLP?.invoiceData?.invoiceErrors;
                      tableEntry.varStatus =
                          updatedPLP?.customVendorPLPMetadata?.varStatus;
                      tableEntry.updatingVarStatus = false;
                    }

                    return tableEntry;
                  });

                  setTableData(updatedTableData);
                  //@ts-ignore
                  accept();
                })
                .catch((error) => {
                  // Display and error to the user
                  if (error.response) {
                    failedNotification(
                        error.response.data.error,
                        error.response.data.detail
                    );
                  } else {
                    failedNotification("Failed to update the PLP");
                  }

                  // Update the table entry
                  let updatedTableData = tableData.map((tableEntry: any) => {
                    if (tableEntry.id === plp.id) {
                      tableEntry.updatingVarStatus = false;
                    }

                    return tableEntry;
                  });
                  setTableData(updatedTableData);
                  //@ts-ignore
                  accept();
                });
          } else {
            failedNotification(
                `Cannot submit accrual for PLP ${plp.plpLang}, the status of the PLP must be ESTIMATE, ACCRUAL or ACCRUAL_FAILED`
            );
            //@ts-ignore
            return accept();
          }

        } )
      }

  // Accrual button
  const handleClickAccrue = async () => {
    try {

      LQEcheck(isEditingWWC);

      setIsProcessingAccrualSubmission(true);

      // Submit the accrual for each selected PLP
      rows.map((plp) => {
        return enqueue( async () => addPLP( plp ) );
      });

    } catch (error) {
      failedNotification("Failed to update the PLPs");
    }
  };

  // Invoice button
  const handleClickInvoice = () => {
    console.log("Invoice btn was clicked");
  };

  // Apply Different Rate button
  const handleClickDifferentRate = () => {
    console.log("DifferentRate btn was clicked");
  };
  // Details Table END

  const submitQVData = useCallback( async (keyValue: any) => {
    const qvUpdateEndpoint = `${BASE_URL}/project/${currentProjectId}/qv`;
    let result = ""
    let postData = {
      ...keyValue
    }

    try {
      result = await axios.patch(qvUpdateEndpoint, postData , {headers: defaultHeaders(accessToken)})
      successfulNotification("Success", "QV data Updated")
      await quietRefreshUseCallBack();
    } catch(error) {
      failedNotification("Error", "Failed to update QV Data")
    }
    return result;

  }, [accessToken, currentProjectId, quietRefreshUseCallBack])

  const submitPLPQVData = useCallback(async (selectedRows: any, keyValue: any) => {

    let postData = {
      ...keyValue
    }
    try {

      let endpoints = selectedRows.map(({ id }: any) => {
        return `${BASE_URL}/plp/${id}/qv`;
      })
      await Promise.all(endpoints.map((endpoint: any) => axios.patch(endpoint, postData ,{ headers: defaultHeaders(accessToken) })))
      await quietRefreshUseCallBack();

    } catch (error) {
      failedNotification("Error", "Failed to update QV Data")
    }

  }, [accessToken, quietRefreshUseCallBack])

  // data that will be passed to general Information
  const giData = details && {
    product: details?.product,
    sourceLanguage: details?.sourceLanguageCode,
    urgency: details?.turnaroundTimeUrgency,
    componentType: details?.componentType,
    projectStatus: details?.customVendorProjectMetadata?.projectStatus,
    varStatus: details?.customVendorProjectMetadata?.varStatus,
    receivedDate: details?.customVendorProjectMetadata?.importedTimestamp,
    latestTranslationDueDate: details?.lastUpdatedTimestamp,
    poN:
        details?.customVendorProjectMetadata?.projectType === "WMT"
            ? details?.plps?.[0]?.invoiceData.wmtPONumber
            : details?.plps?.[0]?.documents?.[0]?.translationPurchaseOrder?.number,
    invoiceName: details?.customVendorProjectMetadata?.invoiceName,
    projectType: details?.customVendorProjectMetadata?.projectType,
  };

const tabItems = [
  {
    key: '1',
    label: <span><CreditCardOutlined /> Invoicing Details</span>,
    children: <><InvoicingActions
        values={{
          activeActions,
          selectedRows,
          isEditingWWC,
          isEditingHours,
          isEditingPO,
          isNewRate,
          isProcessingEstimateSubmission,
          isProcessingAccrualSubmission,
          isProcessingReload,
          details,
          deletedPLPs,
          recovering,
          accessToken,
          pmFee
        }}
        handlers={{
          handleClickEditWWC,
          handleClickEditHours,
          handleClickSetPONumber,
          handleClickDelete,
          handleClickEstimate,
          handleClickAccrue,
          handleClickInvoice,
          handleClickDifferentRate,
          handleDeleteProject,
          handleSubmitNewPMFee,
          handleFinishEditingWWC,
          handleFinishEditingHours,
          handleSetWMTPONumber,
          handleClickFinishRate,
          handleClickRate,
          handleClickReload,
          handleSubmitPLPRecovery,
          handleClickPLPRecovery,
          submitQVData,
          submitPLPQVData,
          quietRefresh
        }}
        //@ts-ignore
        tableData={tableData}
    />
    <InvoicingTable
        handlers={{
          handleTableRowSelection,
          handleChangeDetailsTable,
          handleClearFilterSort,
          handleClickFinishRate,
        }}
        values={{
          filterData,
          filterValues,
          sortData,
          totalTableProjects,
          selectedRows,
          isNewRate,
          details,
          selectedRowsID,
          isLoading,
          projectType,
        }}
        tableData={tableData}
    />
  </>
  },
]

  return isLoading ? (
      <LoadingStar />
  ) : (
      <>
        <Helmet>
          <title>{currentProjectId} - Mercury © RWS</title>
        </Helmet>

        <div className="project-details-content">
          <InvoicingDetailsPageHeader
              projectId={currentProjectId}
              title={details?.title}
          />
          <div
              style={{ padding: "5px 0 10px 0" }}
              className="project-details-body"
          >
            <GeneralInformation {...{ details }} data={giData} />
            <Tabs defaultActiveKey="1" size="small" items={tabItems}>

              {/*{flags.adjustmentsTab && (*/}
              {/*    <TabPane*/}
              {/*        tab={*/}
              {/*          <span>*/}
              {/*      <FormOutlined />*/}
              {/*      Adjustments*/}
              {/*    </span>*/}
              {/*        }*/}
              {/*        key="3"*/}
              {/*        className="adjustments"*/}
              {/*    >*/}
              {/*      <div>*/}
              {/*        <Button*/}
              {/*            onClick={handleClickAdjustment}*/}
              {/*            className="action-btn adjustments-btn"*/}
              {/*            style={{ margin: "0 15px 0 15px" }}*/}
              {/*            icon={<FormOutlined />}*/}
              {/*        >*/}
              {/*          Add Adjustment*/}
              {/*        </Button>*/}
              {/*        /!*{showAddProjectAdjustmentModal && (*!/*/}
              {/*        /!*    <AddProjectAdjustment*!/*/}
              {/*        /!*        setShowAddProjectAdjustmentModal={hideAdjustmentModal}*!/*/}
              {/*        /!*        onAdjustmentCreated={handleClickReload}*!/*/}
              {/*        /!*        stage={gadjs_stage}*!/*/}
              {/*        /!*        project={adjustment}*!/*/}
              {/*        /!*    />*!/*/}
              {/*        /!*)}*!/*/}
              {/*      </div>*/}
              {/*      <Collapse*/}
              {/*          defaultActiveKey={["1"]}*/}
              {/*          style={{ margin: "15px 15px" }}*/}
              {/*      >*/}
              {/*        <Panel header="Adjustments" key="1">*/}
              {/*          <div style={{ margin: "15px 15px 0px 15px" }}>*/}
              {/*            <ListByProjectId*/}
              {/*                stage={gadjs_stage}*/}
              {/*                byProjectId={currentProjectId && currentProjectId}*/}
              {/*            />*/}
              {/*          </div>*/}
              {/*        </Panel>*/}
              {/*      </Collapse>*/}
              {/*    </TabPane>*/}
              {/*)}*/}
            </Tabs>
            <BackTopButton />
          </div>
        </div>
      </>
  );
};

export default InvoicingDetailsPage;
