interface PermissionCheck {
  permissionName: string;
  customerApplication: string;
  action: "create" | "edit" | "delete" | "read";
}

interface IPermission {
  read: boolean;
  create: boolean;
  delete: boolean;
  edit: boolean;
  customerApplication: string;
  name: string;
  permissionId: string;
}

export const checkPermissions = (
  userPermissions: IPermission[],
  requiredPermissions: PermissionCheck[]
): boolean => {
  return requiredPermissions.some((requiredPermission) =>
    userPermissions.some(
      (perm) =>
        perm.name === requiredPermission.permissionName &&
        perm[requiredPermission.action] === true &&
        perm.customerApplication === requiredPermission.customerApplication
    )
  );
};
