import { FiniteListWithAsyncLoaderAbstract, ListProps } from "../../Components";
import { useMercuryContext } from "../../user-context";

class BaseFiniteServiceFilter extends FiniteListWithAsyncLoaderAbstract {
  useNameAsValue = false;

  async fetch() {
    const { data } = await this.props.context.client.get(
      "/core-gss-v1/service"
    );

    return data.map((item: any) => ({
      title: item.name,
      value: this.useNameAsValue ? item.name : item.id,
    }));
  }
}

class FiniteServiceFilterById extends BaseFiniteServiceFilter {}

class FiniteServiceFilterByName extends BaseFiniteServiceFilter {
  useNameAsValue = true;
}

const ServiceFilterById = (props: ListProps) => {
  const context = useMercuryContext();
  return <FiniteServiceFilterById context={context} {...props} width={350} />;
};

const ServiceFilterByName = (props: ListProps) => {
  const context = useMercuryContext();
  return <FiniteServiceFilterByName context={context} {...props} width={350} />;
};

export { ServiceFilterById, ServiceFilterByName };
