import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import { useMercuryContext } from "../../user-context";
import {
  failedNotification,
  successfulNotification,
} from "../../utils/notifications";
import SessionStorage from "../../Utilities/LocalStorage";
import { fetchManualProjectFields } from "./manualProjectFields";

interface DetailField {
  label: string;
  editable?: boolean;
  type?: "text" | "select" | "date" | "number" | "float" | "textarea" | "month";
  options?: { title: string; value: string | boolean }[];
  required?: boolean;
  checked: boolean;
}

const storedCostCodes = new SessionStorage("costCode");

const ManualProjectBatchUpdate = (props: any) => {
  const { gpSocket, client, gpClient } = useMercuryContext();
  const [form] = Form.useForm();
  const [fieldsData, setFieldsData] = useState<Record<string, any> | null>(
    null
  );
  const [loadingFields, setLoadingFields] = useState<boolean>(true);
  const [fieldError, setFieldError] = useState<string | null>(null);
  const [costCodes, setCostCodes] = useState<any[]>([]);
  const [, setLoadingCostCodes] = useState<boolean>(true);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [selectedFields, setSelectedFields] = useState<{
    [key: string]: boolean;
  }>({});
  const rows = props.selectedRows || [];

  useEffect(() => {
    const costCodes = storedCostCodes;

    if (costCodes?.data) {
      setCostCodes(costCodes.data);
      setLoadingCostCodes(false);
    } else {
      const fetchCostCodes = async () => {
        setLoadingCostCodes(true);
        try {
          const { data } = await client.get("/core-ccs-v1");
          const rows = data.map((item: any) => ({
            title: item.code,
            value: item.code,
          }));

          setCostCodes(rows);
          costCodes.data = data;
        } catch (error) {
          console.error("Error fetching cost codes:", error);
        } finally {
          setLoadingCostCodes(false);
        }
      };

      fetchCostCodes();
    }

    const fetchFieldsData = async () => {
      setLoadingFields(true);
      setFieldError(null);

      try {
        const data = await fetchManualProjectFields(gpClient, [
          "productionStatus",
          "so",
        ]);
        setFieldsData(data);
      } catch (err: any) {
        setFieldError(err?.toString());
      } finally {
        setLoadingFields(false);
      }
    };

    fetchFieldsData();
  }, []);

  const fields: { [key: string]: DetailField } = {
    productionStatus: {
      label: "Production Status",
      type: "select",
      options: fieldsData?.productionStatus,
      checked: false,
    },
    quoteSubmission: {
      label: "Quote Submission",
      type: "date",
      checked: false,
    },
    so: {
      label: "SO",
      type: "select",
      options: fieldsData?.so,
      checked: false,
    },
    clientPO: { label: "Client PO", type: "text", checked: false },
    costCode: {
      label: "Cost Code",
      type: "select",
      options: costCodes,
      checked: false,
    },
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();

      const selectedValues = Object.keys(values)
        .filter(
          (key) =>
            selectedFields[key] &&
            values[key] !== undefined &&
            values[key] !== ""
        )
        .reduce((obj, key) => {
          obj[key] = values[key];
          return obj;
        }, {} as Record<string, any>);

      if (Object.keys(selectedValues).length === 0) {
        console.warn("No valid fields selected for update.");
        failedNotification(
          "No fields selected",
          "Please select and fill fields before submitting."
        );
        return;
      }

      const body = { ...selectedValues };
      const ids = rows?.map((p: any) => p._id);
      const buganizers = rows?.map((p: any) => p.buganizerId);

      await new Promise((accept, reject) => {
        gpSocket.manualproject.emit("batchUpdate", { ids, body }, (e: any) => {
          setLoading(false);
          setConfirmLoading(false);
          if (e.successIds.length > 0) {
            successfulNotification(
              `Ids(s): ${buganizers.slice(0, 2).join(", ")} have been updated ${
                buganizers.length > 2
                  ? "... " + (buganizers.length - 2) + " more"
                  : ""
              }`
            );
            setIsOpen(false);
            form.resetFields();
            accept(null);
          } else {
            failedNotification(
              "Failed to bulk update",
              `Ids(s): ${buganizers
                .slice(0, 2)
                .join(", ")} have failed to update ${
                buganizers.length > 2
                  ? "... " + (buganizers.length - 2) + " more"
                  : ""
              }`
            );
            reject(e as Error);
          }
        });
      });

      await props.reload();
    } catch (err) {
      console.error("Error in batch update:", err);
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleModalOpen = (open: boolean) => {
    if (open) {
      form.resetFields();
      setSelectedFields({});
    }
    setIsOpen(open);
  };

  const handleCheckboxChange = (key: string, checked: boolean) => {
    setSelectedFields((prev) => ({
      ...prev,
      [key]: checked,
    }));
  };

  const handleFloatInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !/[0-9.]/.test(event.key) &&
      !["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].includes(
        event.key
      )
    ) {
      event.preventDefault();
    }
  };

  const handleNumberInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !/[0-9]/.test(event.key) &&
      !["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].includes(
        event.key
      )
    ) {
      event.preventDefault();
    }
  };

  return (
    <>
      <Button
        onClick={() => handleModalOpen(true)}
        className="action-btn edit-btn"
        icon={<EditOutlined />}
        type="primary"
        loading={loading}
        disabled={rows.length === 0}
      >
        Edit
      </Button>
      <Modal
        open={isOpen}
        className="batch-modal"
        title="Batch Update"
        okText="Submit"
        cancelText="Cancel"
        onCancel={() => handleModalOpen(false)}
        maskClosable={false}
        onOk={handleSubmit}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
      >
        <Form
          form={form}
          labelCol={{
            span: 10,
          }}
          wrapperCol={{
            span: 18,
          }}
          labelAlign="left"
          name="mpBatchEditForm"
        >
          <p>
            Specify the fields to be updated on the selected projects using the
            checkboxes.
          </p>
          {Object.entries(fields).map(([key, field]) => (
            <Row gutter={18} key={key}>
              <Col span={22}>
                <Form.Item label={field.label} name={key}>
                  {field.type === "select" ? (
                    <>
                      <Select
                        disabled={!selectedFields[key]}
                        options={field.options}
                        loading={loadingFields}
                      />
                      {fieldError && (
                        <p style={{ color: "red", marginTop: 4 }}>
                          {fieldError}
                        </p>
                      )}
                    </>
                  ) : field.type === "date" ? (
                    <DatePicker disabled={!selectedFields[key]} />
                  ) : field?.type === "number" ? (
                    <InputNumber
                      disabled={!selectedFields[key]}
                      onKeyDown={handleNumberInput}
                    />
                  ) : field?.type === "float" ? (
                    <InputNumber
                      disabled={!selectedFields[key]}
                      onKeyDown={handleFloatInput}
                    />
                  ) : field?.type === "textarea" ? (
                    <Input.TextArea rows={4} disabled={!selectedFields[key]} />
                  ) : field?.type === "month" ? (
                    <DatePicker
                      picker="month"
                      disabled={!selectedFields[key]}
                    />
                  ) : (
                    <Input disabled={!selectedFields[key]} />
                  )}
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item valuePropName="checked">
                  <Checkbox
                    checked={selectedFields[key] || false}
                    onChange={(e) =>
                      handleCheckboxChange(key, e.target.checked)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          ))}
        </Form>
      </Modal>
    </>
  );
};

export default ManualProjectBatchUpdate;
