import React, {Component, createRef, FunctionComponent, Ref} from "react";
import {DateTime} from "luxon";
import {
    Button,
    Grid,
    Input,
    Modal,
    Table,
    Tooltip,
    Form,
    notification,
    Pagination
} from "antd";

import type { ColumnsType } from "antd/es/table"
import {ColumnType, Key, SorterResult, TableCurrentDataSource, TablePaginationConfig} from "antd/es/table/interface";
import {
    AlignLeftOutlined,
    CloseCircleOutlined,
    DownloadOutlined,
    FullscreenExitOutlined,
    FullscreenOutlined,
    ReloadOutlined, 
    UpOutlined
} from "@ant-design/icons";

import {Helmet} from "react-helmet";
import Title from "antd/es/typography/Title";
import LoadingStar from "../Loaders/LoadingStar";
import FilterStorage from "../../Utilities/FilterStorage";
import LocalStorage from "../../Utilities/LocalStorage";
import {UserContext} from "../../user-context";
import {ChangeEventShim} from "../../Components";
import {ExpandableConfig} from "rc-table/lib/interface";
import FiltersBar, {Filter} from "../FiltersBar/FiltersBar";

import {Error as ErrorWarning} from "../Errors/Error";

import "./Table.abstract.scss"
import SessionStorage from "../../Utilities/SessionStorage";
import {DateFilterObj} from "../DateFilter/DateFilter";
import { ManageColumns } from "../ManageColumns/ManageColumns";

import BaseTable from "./BaseTable";
import EmptyOverlay from "./EmptyOverlay";

export interface TableProps {
    id?: string;
    sorter?: boolean;
    dataIndex?: string;
    offsetHeader?: number;
    context: UserContext;
    useLegacyEndpoints: boolean;
    breaks: typeof Grid.useBreakpoint;
    displayHideFilters?: boolean;
    noSelect?:boolean;
    hasSidebar?:boolean;
    CellRender?:any;
    legacyTable?:boolean
    filtersRef?:Ref<any>;
    rowHeight?:number;
    useEstimatedRowHeight?: boolean;
}

export type TableFilters = Record<string, any>;

export interface SavedFilter {
    _id: string;
    creator: string;
    sharedWith?: string[];
    filterData: any;
    name: string;
    key: string;
}

export interface TableState<R> {
    breaks: typeof Grid.useBreakpoint;
    initialised: boolean;
    loading: boolean;
    rowData: R[];
    filters: TableFilters;
    showFilters: boolean;
    selectedRows: R[];
    currentPage: number;
    itemsPerPage: number;
    disabledFilters: Set<keyof TableFilters>;
    sortField?: string;
    sortOrder: "ascend" | "descend";
    error?: Error;
    tempColumnOrder: string[];
    columnOrder: string[];
    hiddenColumns: string[];
    pinnedColumns: string[];
    columnsWidth: ColsWidth[];
    showManageColumnsModal: boolean;
    disabledColumns: string[];
    showExportModal: boolean;
    exportFilename: string;
    users: { email: string; name: string; _id: string }[];
    workflows: { title: string; description: string; _id: string }[];
    savedFilters: SavedFilter[];
    currentSavedFilter?: SavedFilter;
    pinnedLeft?: string;
    pinnedRight?: string;
    meta: any;
    filtersLoading: boolean;
    changedIds?: Set<string>;
    reqId?:string;
}

export interface FilterComponent {
    id: string;
    name: string;
    title: string;
    component: FunctionComponent<any>;
    left?: boolean;
    active?: boolean;
}

export interface BaseRow extends Record<string, any> {
    key?: string | number;
}

export interface IPagination {
    currentPage: number;
    totalPages: number;
    totalResults?: number;
}

export interface FetchResponseData<R> {
    data: R[];
    pagination: IPagination;
}

export interface FetchResponseDataFlat<R> {
    data: R[];
}

export interface Column<R> extends Omit<ColumnType<R>, "fixed"> {
    id: string;
    title: string;
    fixed?: string | boolean;
    frozen?: string | boolean;
}

interface abstractColumn<R> extends Column<R> {
    FilterComponent: any
}

interface ColsWidth {
    key: string;
    width: number;
}


const
    {Item} = Form,
    ExportJsonExcel = require("js-export-excel");

export default abstract class TableBaseAbstract<R extends BaseRow> extends Component<TableProps, TableState<R>> {

    abstract title: string;
    abstract pageTitle: string;
    abstract FilterComponents: FilterComponent[];
    abstract columns: Column<R>[];
    abstract getData():Promise<void>;
    abstract defaultSortField: string;

    column(props: abstractColumn<R>) {

        const
            {FilterComponent, ...config} = props,
            { sortField, sortOrder } = this.state;

        return {
            filterDropdown: FilterComponent
                // @ts-ignore
                ? <FilterComponent
                    id={config.id}
                    key={config.dataIndex}
                    name={config.dataIndex}
                    //@ts-ignore
                    value={this.state.filters[config.dataIndex]}
                    onRemove={this.onRemoveFromList}
                    onChange={this.onUpdateFilter}
                    //@ts-ignore
                    disabled={this.state.disabledFilters.has(config.dataIndex)}
                    onClearFilter={this.onClearFilter}
                    forceOpen={true}
                /> : undefined,
            ...config,
            ...(config && config.sorter === true ? {
                sortOrder: sortField === config.dataIndex ? sortOrder : undefined
            } : {})
        }
    }


    public pageClass = "defaultTable";

    protected userContext: UserContext;
    private filterStore!: FilterStorage;
    private tableSettingsStore!: LocalStorage;
    private cacheStorage?: SessionStorage;
    protected bustCache = () => {
        this.cacheStorage && this.cacheStorage.clear();
    }

    public mapping?: Record<keyof R, string>;

    public columnMapping(): Column<R>[] {
        let
            cols = this.columns.filter((col) => {
                return this.state.disabledColumns.indexOf(col.id) === -1;
            })

        cols = cols.sort((a, b) => {
            return this.state.columnOrder.indexOf(a.id) - this.state.columnOrder.indexOf(b.id)
        })

        if(this.userContext.flags.newFrontendTable) {
            return cols.map((col) => {
                const existentColumn = this.state.columnsWidth.find((c)=> c.key === col.dataIndex);
                return { ...col, width: existentColumn ? existentColumn.width : col.width }
            })
        }

        return cols;
    }

    protected constructor(props: TableProps) {

        super(props);

        this.userContext = props.context;

        this.state = {
            columnOrder: [],
            hiddenColumns: [],
            pinnedColumns: [],
            breaks: props.breaks,
            initialised: false,
            loading: false,
            rowData: [],
            filters: {},
            showFilters: true,
            selectedRows: [],
            currentPage: 1,
            itemsPerPage: 100,
            disabledFilters: new Set(),
            sortOrder: "descend",
            showManageColumnsModal: false,
            tempColumnOrder: [],
            disabledColumns: [],
            columnsWidth: [],
            showExportModal: false,
            exportFilename: "",
            users: [],
            workflows: [],
            savedFilters: [],
            currentSavedFilter: undefined,
            pinnedLeft: "",
            pinnedRight: "",
            meta: {},
            filtersLoading: true
        }
    }

    get breaks() {
        return this.state.breaks;
    }

    protected clear = () => {
        this.filterStore.clear();
        this.filterStore.add({});
        this.filterStore.save();
        const data = this.tableSettingsStore.data || {};
        data.currentPage = 1;
        data.sortOrder = undefined;
        data.sortField = undefined;
        data.currentSavedFilter = undefined;
        this.tableSettingsStore.data = data;
        this.tableSettingsStore.data.controlsEnabled = false;
        this.tableSettingsStore.data.showFilters = true;
        this.bustCache();
        // old version to see all filters on clear all
        // const newFilterComponents = this.FilterComponents.map((f)=> {
        //     f.active = true;
        //     return f;
        // })

        // this.FilterComponents = newFilterComponents;

        this.tableSettingsStore.set({
            ...this.tableSettingsStore.data,
            moreLessOptions: this.FilterComponents.map(i => i.name),
            disabledFilters: this.FilterComponents.filter(f => !f.active).map((filter) => {
                return filter.name
            })
        })

        this.setState({
            filters: {},
            currentPage: 1,
            selectedRows: [],
            error: undefined,
            sortField: this.defaultSortField,
            sortOrder: "descend",
            currentSavedFilter: undefined,
        }, this.getData);

    }

    protected updateFilters = (filters: TableFilters) => {
        const updated = this.disabledFilterRules(filters);
        this.filterStore.clear();
        this.filterStore.add(updated);
        this.filterStore.save();

        this.bustCache();

        this.setState({filters: updated, currentPage: 1}, this.getData);
    }

    public disabledFilterRules(filters: TableFilters) {
        return filters;
    }

    private tempColumnData?: SessionStorage;


    set users(value: TableState<R>["users"]) {
        this.setState({users: value})
    }

    get users() {
        return this.state.users;
    }

    set workflows(value: TableState<R>["workflows"]) {
        this.setState({workflows: value})
    }

    get workflows() {
        return this.state.workflows;
    }

    protected abortController = new AbortController();

    public defaultDisabledColumns:string[] = [];

    async componentDidMount() {

        document.title = this.title;

        this.filterStore = new FilterStorage(this.props.id || this.title);
        this.tableSettingsStore = new LocalStorage(this.props.id ? this.props.id.concat("-settings") : this.title.concat("-settings"));
        this.cacheStorage = new SessionStorage(this.props.id ? this.props.id.concat("-cache") : this.title.concat("-cache"));

        this.userContext.gpSocket.filterStorage.emit("getAll", this.props.id ? this.props.id : this.title, ( data:any ) => {
            // console.log( "DATA", data )
            this.setState({ savedFilters: data, filtersLoading: false } )
        })

        this.userContext.gpSocket.filterStorage.on( `updated-${this.props.id ? this.props.id : this.title}`, ( data:any ) => {
            this.setState({ savedFilters: data, filtersLoading: false } )
        } )

        const
            settings = this.tableSettingsStore.data || await this.settingsLoad();

        if (!settings.columnOrder) {
            settings.columnOrder = this.columns.map((column) => {
                return column.id
            })

            this.tableSettingsStore.set(settings);
        }

        if (!settings.pinnedLeft) {
            settings.pinnedLeft = this.state.pinnedColumns.filter((item: any) => item.fixed === 'left').map((i: any) => i.id);
            this.tableSettingsStore.set(settings);
        }

        if (!settings.pinnedRight) {
            settings.pinnedRight = this.state.pinnedColumns.filter((item: any) => item.fixed === 'right').map((i: any) => i.id);
            this.tableSettingsStore.set(settings);
        }

        if (!settings.disabledColumns) {
            settings.disabledColumns = this.defaultDisabledColumns;
            this.tableSettingsStore.set(settings);
        }

        if (!settings.controlsEnabled) {
            settings.controlsEnabled = false;
            this.tableSettingsStore.set(settings);
        }

        if (!settings.moreLessOptions) {
            settings.moreLessOptions = [];
            this.tableSettingsStore.set(settings);
        }

        if (!settings.columnsWidth) {
            settings.columnsWidth = this.columns.map((col) => {return {key: col.dataIndex, width: col.width}})
            this.tableSettingsStore.set(settings);
        }

        if (!settings.hasOwnProperty('showFilters')) {
            settings.showFilters = true;
            this.tableSettingsStore.set(settings);
        }

        this.FilterComponents = this.FilterComponents.map((filter: FilterComponent) => {

            if (settings.moreLessOptions.length > 0 ) {
                if (settings.moreLessOptions.includes(filter.name)) {
                    filter.active = true;
                } else {
                    filter.active = false;
                }
            } else {
                filter.active = true;
            }

            if (settings.disabledFilters) {
                if (settings.disabledFilters.indexOf(filter.id) !== -1) {
                    filter.active = false;
                }
            }
            return filter;
        })

        const {
            itemsPerPage,
            currentPage,
            sortField,
            sortOrder,
            columnOrder,
            pinnedLeft,
            pinnedRight,
            disabledColumns,
            columnsWidth,
            currentSavedFilter,
            showFilters
        } = this.tableSettingsStore.data || {
            itemsPerPage: 100,
            currentPage: 1
        };

        this.tempColumnData = new SessionStorage("tempColumnData");

        const colData = this.tempColumnData.data || {};


        this.tempColumnData.set(colData);

        // END TODO

        this.setState({
            filters: this.filterStore.filters ? this.disabledFilterRules(this.filterStore.filters) : currentSavedFilter ? currentSavedFilter.filterData : {},
            itemsPerPage: itemsPerPage || 200,
            currentPage: currentPage || 1,
            sortOrder,
            sortField: sortField || this.defaultSortField,
            columnOrder,
            pinnedLeft,
            pinnedRight,
            disabledColumns,
            columnsWidth,
            currentSavedFilter,
            showFilters
        }, async () => {
            this.setState({initialised: true})
            await this.getData();
        })

        const body = document.querySelector("body");
        if ( body ) body.style.overflow = "hidden"
    }

    componentWillUnmount() {
        this.abortController.abort();
        const body = document.querySelector("body");
        if ( body ) body.style.removeProperty( "overflow" );
    }

    settingsFields = ["disabledColumns", "columnOrder", "pinnedLeft", "pinnedRight"];

    updateTimer: any;

    public async onSettingsStore() {
        // console.log( "Saving", this.filterStore, this )
    }

    public async settingsLoad() {
        return {};
    }

    componentDidUpdate(prevProps: TableProps, prevState: TableState<R>) {

        if (JSON.stringify(this.state.breaks) !== JSON.stringify(this.props.breaks)) {
            this.setState({breaks: this.props.breaks});
        }

        const
            previous = {},
            current = {};
        this.settingsFields.forEach((field) => {
            //@ts-ignore
            previous[field] = prevState[field];
        })
        this.settingsFields.forEach((field) => {
            //@ts-ignore
            current[field] = this.state[field];
        });

        if (JSON.stringify(previous) !== JSON.stringify(current)) {
            this.tableSettingsStore.set({...this.tableSettingsStore.data, ...current})
            clearTimeout(this.updateTimer);
            this.updateTimer = setTimeout(() => this.onSettingsStore(), 2000);
        }
    }

    endpoint() {
        return "/projects"
    }

    public uri() {
        return this.endpoint()
            .concat("?")
            .concat(
                Object.keys(this.state.filters)
                    .map(key => key + '=' + this.state.filters[key]).join('&')
            );
    }

    public body() {
        return this.state.filters;
    }

    updateFilter(e: ChangeEventShim, filters: TableFilters) {

        const
            {target} = e;
        filters[target.name] = target.value;
        Object.keys(filters).map((key) => {
            if (!filters[key] || (Array.isArray(filters[key]) && filters[key].length === 0)) {
                delete filters[key];
            }
            return null;
        })
    }

    public filtersToQuery() {
        const
            query: Record<any, any> = Object.assign({}, this.filters);

        const disabled = this.FilterComponents
            .filter(f => !f.active)
            .map(f => {
                return f.name
            });

        Object.keys(query).forEach(k => {

            query[k] = DateFilterObj.getDateRange(query[k])

            if (disabled.indexOf(k) !== -1) {
                delete query[k]
            }

        });

        return query;
    }

    get filters() {
        return this.state.filters;
    }

    // set filters(value) {
    //     this.bustCache();
    //     this.updateFilters(value);
    // }

    onUpdateFilter = (e: ChangeEventShim | ChangeEventShim[]) => {
        const {filters} = this.state;

        if (Array.isArray(e)) {
            e.forEach((item) => {
                this.updateFilter(item, filters);
                return null;
            })
        } else {
            this.updateFilter(e, filters);
        }

        if (this.state.currentSavedFilter) {
            this.tableSettingsStore.data = {...this.tableSettingsStore.data, controlsEnabled: true};
        }

        this.updateFilters(filters);
    }

    onClearFilter = (e: ChangeEventShim) => {
        const filters = this.state.filters;
        filters[e.target.name] = undefined;
        Object.keys(filters).forEach((key) => {
            if (!filters[key] || (Array.isArray(filters[key]) && filters[key].length === 0)) {
                delete filters[key];
            }
            return null;
        })

        this.updateFilters(filters);
    }

    onRemoveFromList = (e: ChangeEventShim) => {

        const
            filters = this.state.filters,
            {target} = e;

        if (target.name in filters && (Array.isArray(filters[target.name]) || filters[target.name] instanceof Set)) {
            let updated = filters[target.name];
            if (Array.isArray(filters[target.name])) {
                updated = new Set(filters[target.name]);
                updated.delete(target.value);
            } else {
                updated.delete(target.value);
            }
            const arr = Array.from(updated);
            filters[target.name] = arr.length === 0 ? undefined : arr;
            Object.keys(filters).forEach((key) => {
                if (!filters[key] || (Array.isArray(filters[key]) && filters[key].length === 0)) {
                    delete filters[key];
                }
                return null;
            })
            this.updateFilters(filters);
        }
    }

    toggleFilters() {
        this.tableSettingsStore.data = {
            ...this.tableSettingsStore.data,
            showFilters: !this.tableSettingsStore.data.showFilters
        };

        this.setState({showFilters: !this.state.showFilters});
    }

    set itemsPerPage(value) {
        this.bustCache()
        this.setState({itemsPerPage: value}, () => {
            const data = this.tableSettingsStore.data || {};
            data.itemsPerPage = value;
            this.tableSettingsStore.data = data;
            this.getData();
        });
    }

    get itemsPerPage() {
        return this.state.itemsPerPage || 100;
    }

    set currentPage(value) {
        this.bustCache()
        this.setState({currentPage: value}, () => {
            const data = this.tableSettingsStore.data || {};
            data.currentPage = value;
            this.tableSettingsStore.data = data;
            this.getData();
        })
    }

    get currentPage() {
        return this.state.currentPage || 1;
    }

    // get sortOrder() {
    //     console.log( "getSortOrder" );
    //     return this.state.sortOrder;
    // }
    //
    // set sortOrder(sortOrder: "ascend" | "descend") {
    //     console.log( "setSortOrder" );
    //     this.bustCache()
    //     this.setState({sortOrder}, () => {
    //         const data = this.tableSettingsStore.data || {};
    //         data.sortOrder = sortOrder;
    //         this.tableSettingsStore.data = data;
    //         this.getData();
    //     });
    // }



    get sortField() {
        return this.state.sortField || this.defaultSortField;
    }

    set sortField(sortField: string) {
        this.bustCache()
        this.setState({sortField}, () => {
            const data = this.tableSettingsStore.data || {};
            data.sortField = sortField;
            this.tableSettingsStore.data = data;
            this.getData();
        });
    }

    updateColumnWidth = (column:any) => {
        this.tableSettingsStore.set({
            ...this.tableSettingsStore.data,
            columnsWidth: [...this.tableSettingsStore.data.columnsWidth.map((c:any) => { return c.key === column.column.key ? {...c, width: column.width} : c}),]
        })

        this.setState({ columnsWidth: [...this.state.columnsWidth.map((c:any) => { return c.key === column.column.key ? {...c, width: column.width} : c}),]})
    }

    updateAllFilters = (updated: Filter[]) => {
        this.bustCache()
        const filters: Record<any, any> = {};
        updated.forEach((filter) => {
            filters[filter.name] = filter.value;
        })
        this.FilterComponents = updated;
        this.tableSettingsStore.set({
            ...this.tableSettingsStore.data,
            disabledFilters: this.FilterComponents.filter(f => !f.active).map((filter) => {
                return filter.name
            })
        })
        this.updateFilters(filters)
        this.getData();
    }

    public onExport() {

        let filteredColumns = this.columns
            .sort((a, b) => {
                // @ts-ignore
                return (
                    this.state.columnOrder.indexOf(a.id) - this.state.columnOrder.indexOf(b.id)
                );
            })
            .filter((col) => {
                // @ts-ignore
                return this.state.disabledColumns.indexOf(col.id) === -1;
            })
            .map((col) => {
                return col;
            });

        let filteredRows = this.rows.map((row) => {
            return {
                ...row,
                // @ts-ignore
                helixId: row?.helixId ? row?.helixId : 'Not Set',
                sharingStatus: row?.sharingStatus === 2 ? 'Fully Shared' : row?.sharingStatus === 1 ? 'Partially Shared' : 'Not Shared',
                STT: row?.STT ? 'Yes' : 'No',
                ownerName: row?.ownerName ? row?.ownerName : 'Not Set',
                workflowTypeTitle: row?.workflowTypeTitle ? row?.workflowTypeTitle : 'Not Set',
                expedited: row?.expedited ? 'Yes' : 'No',
                noExtension: row?.noExtension ? 'Yes' : 'No',
                launched: row?.launched ? 'Yes' : 'No',
                onHold: row?.onHold ? 'Yes' : 'No',
                invoiceMonth: row?.invoiceMonth ? DateTime.fromISO(row?.invoiceMonth).startOf('month').setLocale('en-gb').toLocaleString(DateTime.DATETIME_SHORT) : 'Not Set',
                salesOrder: row?.salesOrder ? row?.salesOrder : 'Not Set',
                invoiceName: row?.invoiceName ? row?.invoiceName : 'Not Set',
                notes: row?.notes ? row?.notes.text : 'Not Set',
                dueDate: DateTime.fromISO(row?.dueDate).setLocale('en-gb').toLocaleString(DateTime.DATETIME_SHORT),
                importedTimestamp: DateTime.fromISO(row?.importedTimestamp).setLocale('en-gb').toLocaleString(DateTime.DATETIME_SHORT),
                lastUpdatedTimestamp: DateTime.fromISO(row?.lastUpdatedTimestamp).setLocale('en-gb').toLocaleString(DateTime.DATETIME_SHORT),
                latestTranslationDueTimestamp: DateTime.fromISO(row?.latestTranslationDueTimestamp).setLocale('en-gb').toLocaleString(DateTime.DATETIME_SHORT),
                translationDueTimestamp: DateTime.fromISO(row?.translationDueTimestamp).setLocale('en-gb').toLocaleString(DateTime.DATETIME_SHORT),
                POIssued: row?.POIssued ? 'Yes' : 'No',
                legallySensitive: row?.legallySensitive?.isSet ? 'Yes' : 'No',
                PODate: DateTime.fromISO(row?.PODate).setLocale('en-gb').toLocaleString(DateTime.DATETIME_SHORT),
            };
        });

        this.setState({showExportModal: !this.state.showExportModal});

        const toExcel = new ExportJsonExcel({
            fileName: this.state.exportFilename,
            datas: [{
                sheetName: `${this.title}-exported`,
                sheetData: filteredRows,
                sheetFilter: filteredColumns.map((v: any) => v.dataIndex),
                sheetHeader: filteredColumns.map((v: any) => {
                    let header: string;
                    switch (v.dataIndex) {
                        case "STT":
                            header = "STT";
                            break;
                        case "sourceLanguageCode":
                            header = "Source Language";
                            break;
                        case "languageCount":
                            header = "Language Count";
                            break;
                        case "totalWeightedWordCount":
                            header = "Total Weighted Word Count";
                            break;
                        case "twwc":
                            header = "Total Weighted Word Count";
                            break;
                        case "totalWordCount":
                            header = "Total Word Count";
                            break;
                        case "wc":
                            header = "Total Word Count";
                            break;
                        case "targetLanguageCode":
                            header = "Target Language";
                            break;
                        case "weightedWordCount":
                            header = "Weighted Word Count";
                            break;
                        case "engineeringHours":
                            header = "Engineering Hours";
                            break;
                        case "lastUpdatedTimestamp":
                            header = "Last Updated Timestamp";
                            break;
                        case "lastImportedTimestamp":
                            header = "Last Imported Timestamp";
                            break;
                        case "creationTimestamp":
                            header = "Creation Timestamp";
                            break;
                        case "internalQuoteLink":
                            header = "Internal Quote Link";
                            break;
                        case "internalCost":
                            header = "Internal Cost";
                            break;
                        case "accountSuspended":
                            header = "Account Suspended";
                            break;
                        case "isEnrolledIn2Sv":
                            header = "Is 2SV Enrolled";
                            break;
                        case "legallySensitive":
                            header = "Legally Sensitive";
                            break;
                        default:
                            header = v.title;
                            break;
                    }
                    
                    if (v.id === "totalMercuryCharge") {
                        header = "Mercury Charge"
                    }
                    return header;
                })
            }]
        }); //new
        toExcel.saveExcel()
        return null
    }

    public onSaveFilters = async (data: { title: string, filters: FilterComponent[] }) => {


        this.userContext.gpSocket.filterStorage.emit( "create", {
            key: this.props.id || this.title,
            filterData: this.filters,
            name: data.title
        }, ( filter:any ) => {
            const
                activeFiltersList = Object.keys(filter.filterData),
                {savedFilters} = this.state;

            savedFilters.push(filter);

            this.updateFilterComponents(activeFiltersList)

            this.tableSettingsStore.data = {
                ...this.tableSettingsStore.data,
                currentSavedFilter: filter,
                moreLessOptions: activeFiltersList,
                controlsEnabled: false
            };
            this.setState({savedFilters, currentSavedFilter: filter})
        })
    }

    public onChangeLoadedFilter = async (filter: any) => {
        let defaultControlsEnabled =  false;

        const {data: dbFilter} = await this.userContext.gpClient.get(`/google-gp-v1/filterStorage/${filter._id}`, {params: {key: this.props.id ? this.props.id : this.title}})

        // compare the current filter with db filter, if are not the same controls should be enabled
        if (JSON.stringify(filter.filterData) !== (JSON.stringify(dbFilter.filterData))) {
            defaultControlsEnabled = true;
        }

        const updated = this.disabledFilterRules(filter.filterData);

        const
            activeFiltersList = Object.keys(dbFilter.filterData);

        this.updateFilterComponents(activeFiltersList)

        this.filterStore.clear();
        this.filterStore.add(updated);
        this.filterStore.save();

        this.tableSettingsStore.data = {
            ...this.tableSettingsStore.data,
            currentSavedFilter: filter,
            controlsEnabled: defaultControlsEnabled,
            moreLessOptions: activeFiltersList,
            disabledFilters: this.FilterComponents.filter(f => !f.active).map((filter) => {
                return filter.name
            })
        };
        this.bustCache();
        this.setState({filters: updated, currentSavedFilter: filter, currentPage: 1, loading: true}, async () => await this.getData() );
    }

    public onDeleteSavedFilter = async (filterId: string) => {
        const deleteFilter = await this.userContext.gpClient.delete(`/google-gp-v1/filterStorage/${filterId}`);

        if (deleteFilter.data.acknowledged && deleteFilter.data.deletedCount > 0) {
            const savedFiltersWithoutCurrent = this.state.savedFilters.filter((f) => f._id !== filterId)

            this.tableSettingsStore.data = {
                ...this.tableSettingsStore.data,
                currentSavedFilter: undefined,
                controlsEnabled: false
            };
            this.setState({savedFilters: savedFiltersWithoutCurrent, currentSavedFilter: undefined})
        } else {
            notification.open({
                message: 'Failed to delete filter',
                description:
                    'The filter was not deleted. Please try again.',
            });
        }

    }

    public onUpdateSavedFilter = async (filter: SavedFilter) => {
        const updateFilter = await this.userContext.gpClient.patch(`/google-gp-v1/filterStorage/${filter._id}`, {
            key: filter.key,
            filterData: filter.filterData,
            sharedWith: filter.sharedWith,
            name: filter.name
        });

        if (updateFilter.status === 200) {
            this.tableSettingsStore.data = {
                ...this.tableSettingsStore.data,
                currentSavedFilter: filter,
                controlsEnabled: false
            };
            this.setState({currentSavedFilter: filter})
        } else {
            notification.open({
                message: 'Failed to update filter',
                description:
                    'The filter was not updated. Please try again.',
            });
        }

    }

    public onResetSavedFilter = async (filterId: string) => {
        const {data: dbFilter} = await this.userContext.gpClient.get(`/google-gp-v1/filterStorage/${filterId}`, {params: {key: this.props.id ? this.props.id : this.title}})

        if (dbFilter) {

            if (typeof dbFilter.filterData === "string") {
                dbFilter.filterData = JSON.parse(dbFilter.filterData);
            }

            const updated = this.disabledFilterRules(dbFilter.filterData);
            this.filterStore.clear();
            this.filterStore.add(updated);
            this.filterStore.save();

            const
                activeFiltersList = Object.keys(dbFilter.filterData);

            this.updateFilterComponents(activeFiltersList)

            this.tableSettingsStore.data = {
                ...this.tableSettingsStore.data,
                currentSavedFilter: dbFilter,
                moreLessOptions: activeFiltersList,
                controlsEnabled: false
            };

            this.bustCache();

            const savedFiltersWithoutCurrent = this.state.savedFilters.filter((f) => f._id !== filterId)

            this.setState({ savedFilters: [...savedFiltersWithoutCurrent, dbFilter], filters: updated, currentSavedFilter: dbFilter, currentPage: 1 }, this.getData);
        } else {
            console.error('No filter was found.')
        }
    }

    public updateFilterComponents = (list: any) => {

        this.FilterComponents = this.FilterComponents.map((f)=> {
            if (list.includes(f.name)) {
                f.active = true;
            } else {
                f.active = false;
            }
            return f;
        })
    }

    public onChangeMoreLess = (list: any) => {

        this.tableSettingsStore.data = {
            ...this.tableSettingsStore.data,
            moreLessOptions: list
        };

    }

    public onApplyMoreLess = (list: any) => {

        this.updateFilterComponents(list)

        this.tableSettingsStore.set({
            ...this.tableSettingsStore.data,
            controlsEnabled: true,
            disabledFilters: this.FilterComponents.filter(f => !f.active).map((filter) => {
                return filter.name
            })
        })

        let updatedFilters = this.state.filters;

        // remove unchecked filters
        Object.keys(updatedFilters).forEach((item) => {
            if (!list.includes(item)) {
                delete updatedFilters[item]
            }
        })

        this.updateFilters(updatedFilters);
    }

    select = (e: R) => {

        const
            {selectedRows} = this.state,
            exists = selectedRows.filter(r => r.key === e.key);

        let
            selected = [];

        if (exists.length) {
            selected = selectedRows.filter(r => r.key !== e.key);
        } else {
            selected = selectedRows.concat([e]);
        }

        this.setState({selectedRows: selected});
    }

    selectAll = (all: boolean) => {
        if (all) {
            this.setState({
                selectedRows: this.rows.map((item) => {
                    return item
                })
            });
        } else {
            this.setState({selectedRows: []});
        }
    }

    get rows() {

        let
            rows = this.state.rowData;

        if (this.mapping) {
            rows = this.state.rowData.map((row) => {
                const translated: Record<keyof R, any> = {} as any;
                Object.keys(row).forEach((key: keyof R) => {
                    if (key in this.mapping!) {
                        translated[this.mapping![key]] = row[key];
                    } else {
                        translated[key] = row[key]
                    }
                    return null;
                });
                return translated;
            }) as R[];
        }

        return rows.map((row, i) => {

            return {
                key: row._id || i,
                ...row
            }
        });
    }

    public order() {
        const { sortOrder } = this.state;
        switch (sortOrder) {
            case "ascend":
                return "descend";
            case "descend":
            default:
                return "ascend";
        }
    }

    sort(
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: SorterResult<R> | SorterResult<R>[],
        extra: TableCurrentDataSource<R>
    ) {

        const {order, field} = sorter as SorterResult<R>;

        this.bustCache();
        this.setState({
            sortOrder: order || (this.state.sortOrder === "descend" ? "ascend" : "descend"),
            sortField: order !== undefined ? field : this.defaultSortField as any
        }, () => {

            const data = this.tableSettingsStore.data || {};
            data.sortOrder = this.state.sortOrder;
            data.sortField = this.state.sortOrder !== undefined ? field : this.defaultSortField
            this.tableSettingsStore.data = data;
            this.getData();
        })
    }

    sortById = (a: any, b: any) => {
        // @ts-ignore
        return this.state.columnOrder.indexOf(a.id) - this.state.columnOrder.indexOf(b.id)
    }

    renderActions(): any {
        return null;
    }

    public renderExportButton() {

        const { showExportModal, exportFilename } = this.state;

        return (
            <>
                <Button
                    type="default"
                    icon={<DownloadOutlined/>}
                    className="export-btn"
                    onClick={() => this.setState({showExportModal: !showExportModal})}
                    disabled={this.state.loading}
                >
                    Export
                </Button>
                <Modal
                    title={"Export screen to excel"}
                    onCancel={() => this.setState({showExportModal: !showExportModal})}
                    open={showExportModal}
                    // okText={"Save"}
                    // okButtonProps={{
                    //     icon: <DownloadOutlined />,
                    //     disabled: exportFilename.length === 0
                    // }}
                    footer={
                        <>
                            <Button
                                type="default"
                                className="export-btn"
                                onClick={() => this.setState({showExportModal: !showExportModal})}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                disabled={exportFilename.length === 0}
                                icon={<DownloadOutlined/>}
                                className="export-btn"
                                onClick={() => this.onExport()}
                            >
                                Save
                            </Button>

                        </>
                    }
                >
                    <Item>
                        <Input onChange={(e) => this.setState({exportFilename: e.target.value})}
                               value={exportFilename} type={"text"} placeholder={"Exported file name"}
                               addonAfter=".xlsx"/>
                    </Item>
                </Modal>
            </>
        )
    }

    renderPagination() {

        let { currentPage, itemsPerPage } = this.state;

        if ( this.state.meta.total === 0 || !this.state.initialised || !itemsPerPage || !currentPage ) {
            return null;
        }

        let pageOptions = [
            "50",
            "100",
            "200",
            "500",
            "1000"
        ]

        if (this.userContext.flags.newFrontendTable) {
            pageOptions = [...pageOptions, "5000", "10000"]
        }

        return <Pagination
            defaultCurrent={currentPage}
            current={currentPage}
            total={this.state.meta.total}
            pageSize={itemsPerPage}
            onChange={ ( page, pageSize ) => {
                let
                    { currentPage, itemsPerPage } = this.state,
                    update = false;

                if ( page !== currentPage ) {
                    update = true;
                    currentPage = page;
                }
                if ( pageSize !== itemsPerPage ) {
                    update = true;
                    itemsPerPage = pageSize || 1;
                }
                if ( update ) {
                    this.bustCache()
                    const data = this.tableSettingsStore.data || {};
                    data.currentPage = currentPage;
                    data.itemsPerPage = itemsPerPage;
                    this.tableSettingsStore.data = data;
                    window.scrollTo(0, 0)
                    this.setState( { currentPage, itemsPerPage }, this.getData );
                }
            }}
            responsive={true}
            showTotal={(total, range) => total ? `${total} ${total > 1 ? 'items' : 'item'}` : '' }
            disabled={this.state.loading}
            // @ts-ignore
            simple={!this.state.breaks.xl}
            pageSizeOptions={pageOptions}
        />
    }

    renderTitle() {


        return (
            <div className={"table-title-container"}>
                <div className={"table-title-left"}>
                    <Helmet>
                        <title>{this.pageTitle}</title>
                    </Helmet>
                    <Title
                        level={3}
                        className={"table-title"}
                    >
                        {this.title}

                    </Title>
                    {
                        this.props.displayHideFilters
                        && <div className={"filter-options-container"}>
                          <Tooltip
                              placement="bottom"
                              color={"var(--tertiary-color)"}
                              title={this.state.showFilters ? "Hide filters" : "View all filters"}
                          >
                            <Button
                                type="primary"
                                onClick={() => this.toggleFilters()}
                                icon={
                                    !this.state.showFilters ? (
                                        <AlignLeftOutlined/>
                                    ) : (
                                        <CloseCircleOutlined/>
                                    )
                                }
                            >
                              Filters
                            </Button>
                          </Tooltip>
                        </div>


                    }

                </div>
            </div>
        )
    }

    filtersRef = createRef<any>();

    renderFilters() {
        return (

                    <div className={"filterWrapperRef"} ref={this.filtersRef}>
                        <FiltersBar
                            title={this.title}
                            onChange={this.updateAllFilters}
                            filters={this.FilterComponents.map((filter) => {
                                return {
                                    id: filter.id,
                                    component: filter.component,
                                    title: filter.title,
                                    key: filter.name,
                                    name: filter.name,
                                    value: Array.isArray(filter.name)
                                        ? filter.name.map(
                                            name => this.state.filters[name]
                                        )
                                        : this.state.filters[filter.name],
                                    onRemove: this.onRemoveFromList,
                                    onChange: this.onUpdateFilter,
                                    disabled: this.state.disabledFilters.has(filter.name),
                                    onClearFilter: this.onClearFilter,
                                    active: filter.active
                                };
                            }) as any}
                            onSave={(data) => this.onSaveFilters && this.onSaveFilters(data as any)}
                            onLoadedFilterChange={(filter) => {
                                this.onChangeLoadedFilter(filter)
                            }}
                            onLoadedFilterDelete={(filter) => {
                                this.onDeleteSavedFilter(filter)
                            }}
                            onLoadedFilterUpdate={(filter) => {
                                this.onUpdateSavedFilter(filter)
                            }}
                            onLoadedFilterReset={(filterId) => {
                                this.onResetSavedFilter(filterId)
                            }}
                            savedFilters={this.state.savedFilters}
                            filtersLoading={this.state.filtersLoading}
                            currentSavedFilter={this.state.currentSavedFilter}
                            appliedFilters={this.state.filters as any}
                            onClearAll={(params: any) => {
                                this.clear();
                            }}
                            loading={this.state.loading}
                            additionalButtons={[
                                <Button
                                    size={"small"}
                                    disabled={this.state.loading}
                                    key={"reload"}
                                    onClick={async () => {
                                        this.bustCache();
                                        await this.getData()
                                    }}
                                >
                                    <ReloadOutlined spin={this.state.loading}/> Reload
                                </Button>
                            ]}
                            controlsEnabled={this.tableSettingsStore.data.controlsEnabled || false}
                            onApplyMoreLess={(list) => this.onApplyMoreLess(list)}
                            onChangeMoreLess={(list) => this.onChangeMoreLess(list)}
                        />
                    </div>

        );
    }

    handleSubmitManageColumns = (data:any) => {
        const leftColumn = data.filter((item: any) => item.fixed === 'left').map((i: any) => i.id)
        const rightColumn = data.filter((item: any) => item.fixed === 'right').map((i: any) => i.id)
        const disabledColumns = data.filter((item:any) => item.active === false).map((i:any) => i.id)

        this.setState({
            columnOrder: data.map((i: any) => i.id),
            pinnedColumns: data.map((fixed: any) => fixed),
            pinnedLeft: leftColumn,
            pinnedRight: rightColumn,
            disabledColumns: disabledColumns
        })
    }

    renderManageColumns() {
        let columnFixed = this.columns.sort(this.sortById).map((col) => {
            let leftCol: any = this.state.pinnedLeft;
            let rightCol: any = this.state.pinnedRight;

            let pinnedLeft: any = leftCol.some((l: any) => col.id === l);
            let pinnedRight: any = rightCol.some((r: any) => col.id === r);

            return {
                ...col,
                // @ts-ignore
                fixed: pinnedLeft ? "left" : pinnedRight ? "right" : undefined,
            };
        });

        this.columns = columnFixed;
        const colsWithActive = this.columns.map((i: any) => {
            if (this.state.disabledColumns.includes(i.id)) {
                i = { ...i, active: false };
            } else {
                i = { ...i, active: true };
            }
            return i;
        });

        return (
            <ManageColumns
                columns={colsWithActive}
                disabledColumns={this.state.disabledColumns}
                isModalOpen={this.state.showManageColumnsModal}
                toggleModal={() => this.setState({showManageColumnsModal: !this.state.showManageColumnsModal})}
                handleSubmit={this.handleSubmitManageColumns}
            />
        )
    }

    renderManageTableHeight() {
        if (this.userContext.flags.newFrontendTable) {
            return <Tooltip
                placement="topLeft"
                color={"var(--tertiary-color)"}
                title={this.state.showFilters ? "Hide filters" : "Show filters"}
            >
                <Button
                    type="text"
                    icon={this.state.showFilters ? <FullscreenOutlined /> : <FullscreenExitOutlined style={{color:'var(--red)'}}/>}
                    onClick={() => this.toggleFilters()}
                    className="screen-size-btn"
                />
            </Tooltip>
        } else {
            return null
        }
    }

    renderConfigButtons() {
        return <div className="settings">
            {this.renderExportButton()}
            {this.renderManageColumns()}
            <Button className='back-top-btn' type={"text"} onClick={() => {
                window.scrollTo(0,0)
                if ( this.tableRef && this.tableRef.current ) {
                    this.tableRef.current.tableRef?.current?.scrollToTop(0)
                }
            }}><UpOutlined /></Button>
            {/*{this.renderManageTableHeight()}*/}
        </div>
    }

    public rowClassName(record: R, index: number): string {
        return ""
    }

    tableRef:any = createRef();


    renderTable(actions?: any) {

        if (this.state.error) {
            return <ErrorWarning resetFilters={this.clear} reload={this.reload} error={this.state.error.toString()}/>
        }

        const
            { rowData } = this.state,
            cols = this.columnMapping()
                .map((item) => this.column(item as abstractColumn<R>))
                // .sort(this.sortById);

        if (!this.props.legacyTable) {
            return <BaseTable
                ref={this.tableRef}
                className='new-projects-table'
                dataSource={rowData}
                columns={ cols }
                onColumnSort={(sorter:any) => this.sort({}, {}, sorter, {} as any)}
                // @ts-ignore
                sortBy={{ key: this.state.sortField, order: this.state.sortOrder === 'ascend' ? 'asc' : this.state.sortOrder === 'descend' ? 'desc' : this.state.sortOrder}}
                loading={this.state.loading}
                rowClassName={this.rowClassName}
                rowSelection={(this.props.noSelect ? !this.props.noSelect : actions) && {
                    selectedRowKeys: this.state.selectedRows.map(r => r.key) as Key[],
                    onSelectAll: this.selectAll,
                    onSelect: this.select
                }}
                rowHeight={this.props.rowHeight}
                // disabled={this.state.loading}
                enabledRowSelection={typeof this.props.noSelect === "boolean" ? !this.props.noSelect : true}
                itemsPerPage={this.state.itemsPerPage}
                showFilters={this.state.showFilters}
                updateColumnWidth={this.updateColumnWidth}
                CellRenderer={this.props.CellRender}
                hasSidebar={this.props.hasSidebar}
                filtersRef={this.props.filtersRef}
                onScroll={( args:any ) => {
                    const
                        filtersTop = this.filtersRef.current?.getBoundingClientRect().bottom;

                    if ( filtersTop <= 30 && filtersTop >= 40 ) {
                        return;
                    }

                    window.scrollTo( 0, args.scrollTop );
                }}
                emptyRenderer={<EmptyOverlay reset={this.clear} loading={this.state.loading} />}
                useEstimatedRowHeight={this.props.useEstimatedRowHeight}
            />
        }

        return <Table
            columns={cols as ColumnsType<R>}
            dataSource={rowData}
            rowSelection={(this.props.noSelect ? !this.props.noSelect : actions) && {
                // preserveSelectedRowKeys: true,
                selectedRowKeys: this.state.selectedRows.map(r => r.key) as Key[],
                onSelectAll: this.selectAll,
                onSelect: this.select,
                fixed: true
            }}
            pagination={false}
            onChange={(pagination, filters, sorter, extra) => this.sort(pagination, filters as any, sorter, extra)}
            className="projects-table"
            size="small"
            loading={this.state.loading}
            scroll={{x: 1500}}
            rowClassName={this.rowClassName}
            expandable={this.expandable()}
            bordered={true}
            sticky={{offsetHeader: this.props.offsetHeader || 40}}
        />
    }

    reload = async () => {
        this.bustCache()
        this.setState({error: undefined})
        await this.getData();
    }
    render() {
        const { hasSidebar } = this.props;
        if (!this.state.initialised) {
            return (
                <div className={"abstract-table ".concat(this.pageClass || "")}>
                    <LoadingStar logo={true} title={"Initialising..."}/>
                </div>
            )
        }

        const
            actions = this.renderActions();

        // @ts-ignore
        const actionsClass = `${!this.state.breaks.xl ? 'action-btns smaller-actions' : 'action-btns'}`;

        return (
            <div className={"abstract-table ".concat(this.pageClass || "")}>
                {this.renderFilters()}
                <div className={`actions-bar-container ${hasSidebar ? "with-sidebar" : ""}`}>
                    <div className={actionsClass}>
                        {actions}
                    </div>
                    <div className="right-side">
                        {this.renderPagination()}
                        <span className="divider" />
                        {this.renderConfigButtons()}
                    </div>
                </div>
                {this.renderTable(actions)}
            </div>
        )
    }

    public expandable(): ExpandableConfig<R> | undefined {
        return;
    }
}
