import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, Collapse, Space, Tabs, TabsProps } from "antd";
import { Error } from "../../../stories/Errors/Error";
import LoadingStar from "../../../ui/LoadingStar";
import Breadcrumbs from "../../../stories/BreadCrumb/Breadcrumbs";
import BackTopButton from "../../../ui/BackTopButton";
import { useMercuryContext } from "../../../user-context";
import ManualProjectInformation from "./ManualProjectInformation";
import { CreditCardOutlined, HistoryOutlined, ReloadOutlined } from "@ant-design/icons";
import StatusHistory from "../../StatusHistory";
import RequestedLanguages from "./RequestedLanguages";
import ManageProject from "../ManageProject";
import LinkProject from "../LinkProject";
import { checkPermissions } from "../../../Utilities/checkPermissions";
import UniversalButton from "../../../ui/UniversalButton";

interface State {
  error?: any;
  loading: boolean;
  details?: any;
  catToolStatus?: string;
  loadLanguages?: boolean;
}

class ProjectDetails extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: undefined,
      loading: false,
      details: undefined,
      catToolStatus: undefined,
      loadLanguages: false
    };
  }

  componentDidMount(): void {
    this.fetch();
  }

  async fetch(updating?: boolean, setUpdating?: any) {

    this.setState({ loadLanguages: true });

    if (!updating) {
      this.setState({ loading: true });
    }
  
    try {
      // get manual project
      const { data } = await this.props.context.gpClient.get(`/google-gp-v1/manualProjects/${this.props.id}`);
      let catToolStatus;
      let invoiceName: string;
      let invoiceMonth: Date;
      let linkedPlpDetails = [];
  
      // If shortId exists in the manual project, get linked project details
      if (data.shortId) {
        const result = await this.props.context.gpClient.get(`/google-gp-v1/project/shortId/${data.shortId}`);
        catToolStatus = result?.data?.catToolStatus;
        invoiceName = result?.data?.customVendorProjectMetadata?.invoiceName;
        invoiceMonth = result?.data?.customVendorProjectMetadata?.invoiceMonth;
        linkedPlpDetails = result?.data?.plps.map((plp: any) => ({
          targetLanguageCode: plp?.targetLanguageCode,
          polyglotStatus: plp?.catToolStatus,
          monthCompleted: plp?.latestTranslationCompletedTimestamp,
          invoiceMonth: invoiceMonth,
          invoiceNumber: invoiceName,
          totalCost: plp?.invoiceData?.totalCharge,
          varStatus: plp?.customVendorPLPMetadata?.varStatus,
          warning: "This language exists in the linked project but not in the manual project.",
        }));
      }
  
      // get manual project PLPs and add a warning if shortId exists
      const manualProjectPlps = data.plps?.map((plp: any) => ({
        targetLanguageCode: plp?.targetLanguageCode,
        warning: data.shortId ? "This language does not exist in the linked project." : undefined,
      })) || [];
  
      const manualPlpDetails = [...manualProjectPlps];
      
      // Merge linked PLP details into manual PLP details, removing warnings if they match
      linkedPlpDetails.forEach((linkedPlp: any) => {
        const existingPlp = manualPlpDetails.find((plp) => plp.targetLanguageCode === linkedPlp.targetLanguageCode);
        if (existingPlp) {
          Object.assign(existingPlp, linkedPlp);
          delete existingPlp.warning;
        } else {
          manualPlpDetails.push(linkedPlp);
        }
      });
  
      // Merge the manual project Requested Languages with PLP Details
      const combinedPlpDetails = data.requestedLanguages.map((manualLang: any) => {
        const linkedLang = manualPlpDetails.find((linkedLang: any) => linkedLang.targetLanguageCode === manualLang.targetLanguageCode);
        if (linkedLang) {
          return {
            ...manualLang,
            polyglotStatus: linkedLang.polyglotStatus,
            monthCompleted: linkedLang.monthCompleted,
            invoiceMonth: linkedLang.invoiceMonth,
            invoiceNumber: linkedLang.invoiceNumber,
            totalCost: linkedLang.totalCost,
            varStatus: linkedLang.varStatus,
            warning: undefined,
          };
        }
        return {
          ...manualLang,
          warning: data.shortId ? "Language exists in manual project but not in linked project" : undefined,
          exists: true
        };
      });
  
      // Add unique linked languages with warnings if shortId exists
      const uniqueLinkedLanguages = data.shortId ? manualPlpDetails.filter((linkedLang: any) =>
        !data.requestedLanguages.some((manualLang: any) => manualLang.targetLanguageCode === linkedLang.targetLanguageCode)
      ).map((linkedLang: any) => ({
        ...linkedLang,
        warning: "Language exists in linked project but not in manual project",
      })) : [];
  
      const plpDetails = [...combinedPlpDetails, ...uniqueLinkedLanguages];
  
      this.setState({ details: { ...data, plpDetails: plpDetails }, loading: false, loadLanguages: false, catToolStatus });
      if (updating) {
        setUpdating(false);
      }
    } catch (e: any) {
      console.error("Error during fetch:", e);
      this.setState({ error: e, loading: false });
    }
  }

  reload = async (callback: any) => {
    this.fetch(false, () => {
      if (callback) callback();
    });
  };

  projectDetails() {
    const items = [
      {
        key: "1",
        label: "General Information",
        children: (
          <ManualProjectInformation
            fetch={(setUpdating: any) => this.fetch(true, setUpdating)}
            details={this.state.details}
            catToolStatus={this.state.catToolStatus}
          />
        ),
        className: "general-information",
      },
    ];

    return (
      <Collapse defaultActiveKey={["1"]} items={items} style={{ marginBottom: "10px" }} />
    );
  }

  tabs() {
    const { details } = this.state;
    const tabItems: TabsProps["items"] = [
      {
        key: "1",
        label: (
          <span>
            <CreditCardOutlined />Requested Languages
          </span>
        ),
        children: <RequestedLanguages plpDetails={details?.plpDetails} loading={this.state.loadLanguages} />,
      },
      {
        key: "2",
        label: (
          <span>
            <HistoryOutlined />Production Status History
          </span>
        ),
        children: <StatusHistory statusHistory={details?.productionStatusHistory} />,
      },
      {
        key: "3",
        label: (
          <span>
            <HistoryOutlined />Quote Status History
          </span>
        ),
        children: <StatusHistory statusHistory={details?.quoteStatusHistory} />,
      },
    ];
    return <Tabs defaultActiveKey="1" size="small" items={tabItems} />;
  }

  actions() {
    const userPermissions = this.props.context?.userDetails?.permissions || [];
    const canEditProject = checkPermissions(userPermissions, [
      { customerApplication: "Google", permissionName: "Manual Projects", action: "edit" },
    ]);
    const items: TabsProps["items"] = [
      {
        key: "1",
        label: "Actions",
        className: "actions",
        children: (
          <Space
            size="large"
            style={{
              width: "100%",
              alignItems: "flex-start",
              margin: "15px 16px",
            }}
          >
            {canEditProject && (
              <>
                <div onClick={(e) => e.stopPropagation()}>
                  <ManageProject
                    label="Edit"
                    initialValues={this.state.details}
                    reload={(setUpdating: any) => this.fetch(true, setUpdating)}
                  />
                </div>
                <LinkProject initialValues={this.state.details} reload={(setUpdating: any) => this.fetch(true, setUpdating)} />
              </>
            )}
            <UniversalButton clickMethod={() => this.fetch()} text="Refresh" icon={<ReloadOutlined />} type={undefined} />
          </Space>
        ),
      },
    ];
    return <Collapse defaultActiveKey={["1"]} items={items} style={{ marginBottom: "10px" }} />;
  }

  render() {
    const { details } = this.state;
    const { projectName, product } = details || {};
    const { id } = this.props;
    if (this.state.error) {
      return <Error reload={this.reload} loading={this.state.loading} error={JSON.stringify(this.state.error)} />;
    }
    if (this.state.loading || !this.state.details) {
      return (
        <LoadingStar
          logo={true}
          title={
            <div>
              Retrieving details for project:
              <br />
              {id}
            </div>
          }
        />
      );
    }
    return (
      <>
        <Helmet>
          <title>
            {projectName}-{product}-Mercury © RWS
          </title>
        </Helmet>
        <div className="project-details-content">
          <Breadcrumbs
            separator="-"
            items={[
              { key: "projectName", label: details?.projectName, tooltip: "Project Name", color: "var(--primary-color)" },
              { key: "product", label: details?.product, tooltip: "Product" },
            ]}
          />
          <div style={{ padding: "5px 0 10px 0" }} className="project-details-body">
            {this.projectDetails()}
            {this.actions()}
            {this.tabs()}
            <BackTopButton />
          </div>
        </div>
      </>
    );
  }
}

const ManualProjectDetails = function (props: any) {
  const context = useMercuryContext(),
    { id } = useParams();
  return <ProjectDetails context={context} location={props.location} id={id} />;
};

export default ManualProjectDetails;