import {
    TextFilter,
    TableAbstract
} from "../../Components";

import { useMercuryContext } from "../../user-context";

import {
    CheckOutlined, 
    CloseOutlined, 
    } from "@ant-design/icons";
import TimezoneConverter from "../../timezone-converter";

import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import EditPoType from "./EditPoType";
import AddPoType from "./AddPoType";
import { descriptionColumn, titleColumn } from "../../Search/columnsConfig";
import { activeFilter, createdAtFilter } from "../../Search/filtersConfig";
// import { checkPermissions } from "../../Utilities/checkPermissions";

class ManagePOTypes extends TableAbstract<any> {
    defaultSortField = "title";
    FilterComponents = [
      {
          id: "title",
          name: "title",
          title: "Title",
          component: TextFilter,
          active: true
      },
      createdAtFilter,
      activeFilter,
    ];    

    columns = [
        titleColumn,
        descriptionColumn,
        {
          id: "createdAt",
          title: "Creation Timestamp",
          dataIndex: "createdAt",
          sorter: true,
          fixed: undefined,
          width: 160,
          render: (text:string) => <TimezoneConverter date={text}/>
        },
        {
          id:'isActive',
          title: "Active",
          dataIndex: "isActive",
          width: 75,
          sorter: true,
          fixed: undefined,
          render: (text:string, record:any) =>
          record.isActive ? (
              <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
            ) : (
              <CloseOutlined style={{ color: "var(--red)" }} />
            ),
        },
        {
          id: 'edit',
          title: "Edit",
          dataIndex: "edit",
          sorter: false,
          fixed: true,
          width: 100,
          render: (text:string, record:any) => <EditPoType currentPoType={record} reload={this.getData}/>
        },
    ];



    renderActions() {

      // Example on how to use utility function and check for permissions
      // @ts-ignore
      // const userPermissions = this.userContext.userDetails?.permissions || [];

      // const hasAccess = checkPermissions(userPermissions, [
      //   { customerApplication: "Google", permissionName: "Test Stefan", action: "create" },
      // ]);

      // console.log('Has access:', hasAccess);
        return <AddPoType reload={this.getData}/>
    }
   
    rowClassName = (record: any): string => {
        return 'row'
    }


    async fetch() {
      const { sortOrder, sortField, currentPage, itemsPerPage } = this.state;
        
      const { data } = await this.userContext.gpClient.get("google-gp-v1/PoType/paginate", {
        params: {
          query: 
            this.filtersToQuery(),
            page: currentPage,
            limit: itemsPerPage,
            sort: {
                    [sortField ?? "createdAt"]: sortOrder === "ascend" ? 1 : -1
                  } 
        }
      })
        
      const poTypes = data[0].documents.map((d:any) => {return {...d, key: d._id}})

        let result:any = [];

        result = { data: { documents: poTypes, meta: { documents: poTypes, page: 1 } } } as any;
        return result;
    }

    pageTitle = "Manage PO Types - Mercury";
    title = "Manage PO Types";
    pageClass = "poTypesTable"
}

export default function POTypes () {
  const
    context = useMercuryContext(),
    breaks = useBreakpoint();
    return <ManagePOTypes id={"ManagePoTypes"} noSelect={true} context={context as any} useLegacyEndpoints={false} breaks={breaks as any} hasSidebar={true}/>
}
