import React from "react";

import RWSId from "./AuthVendors/RWS-id";
import Moravia from "./AuthVendors/Moravia";
import RWSWorldWide from "./AuthVendors/RWSWorldWide"
import RWSOne from "./AuthVendors/RWSOneEntraID";

import {useMercuryContext} from "./index";
import Cookies from "js-cookie";
import {useMsal} from "@azure/msal-react";
import {useAuth0} from "@auth0/auth0-react";

import {Button} from "antd";
import {CheckCircleOutlined, CloseCircleOutlined, LogoutOutlined,} from "@ant-design/icons";
import {vendors} from "./LoginPage";

const LogoutButton = () => {
    const
        context = useMercuryContext(),
        { removeToken } = context,
        { instance, accounts } = useMsal(),
        authMethod = Cookies.get("auth_method") as vendors,
        auth0 = useAuth0(),
        MSAL = useMsal(),
        RWSID = new RWSId(auth0, context),
        MORAVIA = new Moravia( MSAL, context),
        Worldwide = new RWSWorldWide( MSAL, context ),
        RWSOneEntra = new RWSOne( MSAL, context );


    console.log('MSAL instance: ', instance)
    console.log('MSAL accounts: ', accounts)

    const determineLogoutMethod = async (method:vendors) => {
        if (!document.location.pathname.includes("logout")) {
            Cookies.set("return_url", document.location.pathname);
        }
        Cookies.remove("auth_method")
        switch (method) {
            case vendors.RWSID:
                await RWSID.logout()
                break;
            case vendors.MORAVIA:
                await MORAVIA.logout();
                break;
            case vendors.WORLDWIDE:
                await Worldwide.logout()
                break;
            case vendors.RWSONE:
                await RWSOneEntra.logout()
                break;
            default:
                removeToken();
        }
    };

    const determineStatusIcon = (status:boolean) => {
        if (status) {
            return (
                <CheckCircleOutlined
                    style={{ color: "var(--primary-color)", marginLeft: "7px" }}
                />
            );
        } else {
            return (
                <CloseCircleOutlined
                    style={{ color: "var(--red)", marginLeft: "7px" }}
                />
            );
        }
    };

    return (
        <>
            <Button
                type="text"
                icon={<LogoutOutlined />}
                onClick={async () =>
                    await determineLogoutMethod(authMethod)
                }
                className="profile-menu-item profile-menu-item__logout"
            >
                Log Out
            </Button>
            <hr style={{ margin: "10px 0" }} />
            <p style={{ display: "flex", justifyContent: "space-between" }}>
                <span>{determineStatusIcon(authMethod === vendors.RWSONE)} RWS One </span>
                <span>RWS ID {determineStatusIcon(authMethod === vendors.RWSID)} </span>
            </p>
            <p style={{ display: "flex", justifyContent: "space-between" }}>
                <span>{determineStatusIcon(authMethod === vendors.WORLDWIDE)} RWS Worldwide </span>
                <span>fMoravia {determineStatusIcon(authMethod === vendors.MORAVIA)} </span>
            </p>
        </>
    );
};

export default LogoutButton;
