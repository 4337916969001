import { FiniteListWithAsyncLoaderAbstract, ListProps } from "../../Components";
import { useMercuryContext } from "../../user-context";

export const fetchPriority = async (context: any) => {
  const { data } = await context.gpClient.get(
    "/google-gp-v1/manualProjects/listFields/priority" 
  );
  return data.map((item: any) => ({
    title: item.label,
    value: item.value,
  }));
};

class PriorityFilter extends FiniteListWithAsyncLoaderAbstract {
  fetch() {
    return fetchPriority(this.props.context);
  }
}

export default (props: ListProps) => {
  const context = useMercuryContext();
  return <PriorityFilter context={context} {...props} />;
};
