import io, {Socket}  from "socket.io-client";
import {EventMap} from "typed-emitter";

/**
 * Default socket class placeholder
 *
 * Eventually this will include typed mappings for each service via generics
 */

export default class SocketIOSocket<S extends EventMap,C extends EventMap> {

    // public servicesUrl = "http://localhost:9090";
    public get servicesUrl() {

        const
            path = this.path.split( "/" )[1].toUpperCase().replaceAll( "-", "_" );

        console.log( path, `http://localhost:${process.env[ `REACT_APP_${path}_WS_PORT` ]}`)

        if ( process.env[ `REACT_APP_${path}_WS_PORT` ] ) {
            return `http://localhost:${process.env[ `REACT_APP_${path}_WS_PORT` ]}`
        }

        return process.env.REACT_APP_SERVICES_API_URL;
    }

    public socket:Socket<C,S>;

    private token:string;
    private path:string;

    constructor( path: string, token:string, ns:string ) {

        this.token = token;
        this.path = path;
        this.socket = io( `${this.servicesUrl}/${ns}`,
        {
            path,
            auth: {
                token
            },
            transports: ['websocket']
        })

        this.socket.on("connect_error", (err) => {
            console.error( err , ns);
        });

        this.socket.on( "connect", () => {
            console.log( "Connected" );
        })


        // Todo: socket monitoring
        // this.socket.onAnyOutgoing(() => {
        //     console.log( "out" )
        // });
        //
        // this.socket.onAny(() => {
        //     console.log( "in" )
        // });
    }

    static create( path:string, token:string, ns:string ) {
        return new SocketIOSocket( path, token, ns ).socket;
    }
}

export { SocketIOSocket, Socket };
