export const fetchManualProjectFields = async (
  gpClient: any,
  fields: string[]
) => {
  try {
    const requests = fields.map((field) =>
      gpClient.get(
        `/google-gp-v1/mpDropdownOptions/listDropdownOptions/${field}`
      )
    );

    const responses: any = await Promise.all(requests);

    return responses.reduce((acc: any, res: any, index: any) => {
      acc[fields[index]] =
        res?.data?.map((item: any) => ({
          title: item.name,
          value: item.name,
        })) || [];
      return acc;
    }, {} as Record<string, any>);
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};
